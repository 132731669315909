<template>
  <form class="flex flex-col gap-4 h-fit" @submit.prevent>
    <slot />
  </form>
</template>

<script setup>
import { watchEffect, ref, provide, watch } from 'vue';
import { useFormValidation } from '@/composables/formValidation';

const { isFormValid, updateValidationState } = useFormValidation();

const emit = defineEmits(['update:modelValue']);

const childIsValidStatesByName = ref(new Map());

function setValidState(name, isValid) {
  childIsValidStatesByName.value.set(name, isValid);
}

function unregisterValidator(name) {
  childIsValidStatesByName.value.delete(name);
}

function validateChildren() {
  childIsValidStatesByName.value.forEach((isValid, name) => {
    updateValidationState(name, isValid);
  });
  emit('update:modelValue', isFormValid.value);
}

provide('registerValidator', setValidState);
provide('unregisterValidator', unregisterValidator);

watchEffect(() => {
  validateChildren();
});
</script>
