<template>
  <div class="loading-spinner w-fit">
    <div class="spinner"></div>
  </div>
</template>

<style scoped>
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 6px solid white;
  border-top-color: #000099;
  border-radius: 50%;
  animation: spin 1.25s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
