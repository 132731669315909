<template>
  <div class="filter_group">
    <!-- Switch-List -->
    <SwitcheryLayer
      v-if="menuItem.itemClass === 'switch'"
      :menu-item="menuItem"
      v-bind="$attrs"
    />
    <!-- / Switch-List -->
    <div
      v-else-if="menuItem.itemClass === 'toggle'"
      class="flex justify-between"
    >
      {{ menuItem.title }}
      <multiLayerToggle
        :toggle-option="menuItem.aggregation"
        :layer-state="menuItem.layerState"
      />
    </div>

    <VList
      v-else
      :opened="[open]"
      class="bg-transparent font-medium p-0 border-infra-lines filter-group-list-custom"
    >
      <VListGroup :value="menuItem.title" class="text-neutral">
        <template #activator="{ props }">
          <VListItem
            class="p-2.5"
            v-bind="props"
            @click="$emit('emitActivator', menuItem.title)"
          >
            <VListItemTitle class="flex items-center">
              <span class="subtitle-2 text-neutral pt-0.5">{{
                menuItem.title
              }}</span>
              <span v-show="layerCategories > 0" class="count">
                {{ layerCategories }}
              </span>
            </VListItemTitle>
            <template #append>
              <IconWrapper
                fill="text-color1"
                :icon="open === menuItem.title ? 'remove' : 'add'"
                :size="16"
              />
            </template>
          </VListItem>
        </template>
        <slot />
      </VListGroup>
    </VList>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';
import SwitcheryLayer from '@/apps/usecase-1/map/layer-panel/input-forms/SwitcheryLayer.vue';
import { useMapStore } from '@/apps/usecase-1/map/map-store';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import multiLayerToggle from './input-forms/multiLayerToggle.vue';

const mapStore = useMapStore();
const store = useStore();
const props = defineProps({
  menuItem: {
    type: Object,
    default: () => {},
  },
  open: {
    type: String,
    default: null,
  },
});
const emits = defineEmits(['emitActivator']);

const getSwitchStates = computed(() => mapStore.getSwitchStates);
const layerCategories = computed(() => {
  let switchesActive = 0;
  props.menuItem.items.forEach((e) => {
    if (getSwitchStates.value[e.switchId] === true) switchesActive += 1;
  });
  return switchesActive;
});
</script>
