import IconGebaeudeInfrastruktur from '@/assets/icons/custom/misc/IconGebaeudeInfrastruktur.vue';
import IconInfrastrukturErzeugung from '@/assets/icons/custom/misc/IconInfrastrukturErzeugung.vue';
import IconLadestationFinden from '@/assets/icons/custom/misc/IconLadestationFinden.vue';
import IconKlimaneutraleEnBW from '@/assets/icons/custom/misc/IconKlimaneutraleEnBW.vue';
import { markRaw } from 'vue';

const rawCardData = [
  {
    title: 'Energiewendepartner für Landkreise und Kommunen',
    id: 0,
    description:
      'Als Energiewendepartner der Landkreise und Kommunen gestaltet die EnBW ' +
      'aktiv auf Basis eines datenbasierten und dynamischen Zielbildes des ' +
      'Energiesystems die Energiewende. Über einen „Diskussionsprozess“ ' +
      'schaffen wir mehr Verbindlichkeit zu Ausbau, Zeitplan und Beitrag ' +
      'der EnBW bei der Umsetzung.',
    icon: markRaw(IconGebaeudeInfrastruktur),
    row: 1,
    order: 1,
    routeName: 'UseCase1',
  },
  {
    title: 'Optimierte Geoallokation von Erzeugungsanlagen',
    id: 1,
    description:
      'Identifizierung  von geeigneten Flächen für die Projektenzwicklung von ' +
      'PV-Freiflächenanlagen unter Berücksichtigung von Netzdaten ' +
      '(u.a. freie Kapazitäten). Ziel ist es effizient Transparenz über das ' +
      'Flächenangebot zu erhalten und auf Basis der automatisierten Analytik ' +
      'frühzeitig Projekte zu priorisieren, um den EE-Ausbau zu beschleunigen.',
    icon: markRaw(IconInfrastrukturErzeugung),
    row: 1,
    order: 2,
    routeName: 'UseCase2',
  },
  {
    title: 'Wärmepumpen-KundInnen für die vernetzte Energiewelt',
    id: 2,
    description:
      'Identifizierung potenzieller Wärmepumpen-Kund*innen für die EnBW und ' +
      'die Wärmepumpen-Partner auf Basis geodatenbasierter ' +
      'Wärmebedarfsanalytik, angereichert durch weitere vertriebsrelevante ' +
      'interne sowie externe Daten.',
    icon: markRaw(IconKlimaneutraleEnBW),
    row: 1,
    order: 3,
    disabled: true,
    routeName: '',
  },
];

export { rawCardData };
