<template>
  <v-chart
    ref="geoChart"
    :option="option"
    :class="(geoItems.length === 1 || readOnly) && 'pointer-events-none'"
    @click="syncSelectedFromChartToggle"
  />
</template>

<script setup>
import { computed, nextTick, onBeforeMount, ref, watch } from 'vue';
import { registerMap } from 'echarts/core';

const props = defineProps({
  selected: {
    type: [String, Array],
  },
  /**
   * An array of objects containing details of geo referenced items.
   * Each object should have the following structure:
   * {
   *   @isSelectable: Boolean,         // Indicates if the item is selectable
   *   @title: String,                 // The title of the item
   *   @id: String,                    // Unique identifier for the item
   *   @name: String,                  // Unique identifier for the item
   *   @geometry: Object,              // Geometry data in GeoJSON format
   *   @additionalAttributes: Array    // Array of additional attribute objects
   * }
   */
  geoItems: {
    type: Array,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  singleSelect: {
    type: Boolean,
    default: false,
  },
  styleBorderOnSelect: {
    type: Boolean,
    default: false,
  },
  styleAreaOnSelect: {
    type: Boolean,
    default: true,
  },
});

const geoChart = ref(null);
const features = ref({ type: 'FeatureCollection', features: [] });
const items = ref([]);

const emit = defineEmits(['loaded', 'geo-unselected', 'geo-selected']);

// register empty map to init chart ref in mounted cycle
registerMap('geoMap', features.value);

onBeforeMount(async () => {
  for (const geoItem of props.geoItems) {
    features.value.features.push({
      geometry: JSON.parse(geoItem.geometry_json),
      type: 'Feature',
      properties: { name: geoItem.id },
      id: geoItem.id,
    });
  }
  await nextTick();
  await nextTick();
  registerMap('geoMap', features.value);
  syncGeoSelect();
  await defaultSelectOneMunicipality();
});

const option = computed(() => {
  const style = getComputedStyle(document.documentElement);
  const unSelectedColor = `rgb(${style.getPropertyValue('--bg-subtle')})`;
  const hoverColor = `rgb(255, 224, 178)`;
  const selectedColor = `rgb(${style.getPropertyValue('--core-color2')})`;
  return {
    textStyle: {
      fontFamily: 'mark',
      fontWeight: 500,
    },
    tooltip: {
      trigger: 'item',
    },
    series: [
      {
        selectedMode: props.singleSelect ? 'single' : 'multiple',
        map: 'geoMap',
        type: 'map',
        geoIndex: 0,
        layoutCenter: ['50%', '50%'],
        layoutSize: '100%',
        emphasis: {
          label: {
            show: false,
          },
          itemStyle: {
            areaColor: hoverColor,
          },
        },
        itemStyle: {
          borderWidth: 1,
          borderColor: 'white',
        },
        select: {
          label: {
            show: false,
          },
        },
        data: props.geoItems.map((item) => {
          const itemStyleSelect = {
            borderColor: 'white',
            areaColor: item.areaColor,
          };
          if (props.styleAreaOnSelect) {
            itemStyleSelect.areaColor = selectedColor;
          }
          if (props.styleBorderOnSelect) {
            itemStyleSelect.borderColor = selectedColor;
          }
          return {
            id: item.id,
            name: item.name,
            itemStyle: {
              areaColor: item.areaColor || unSelectedColor,
            },
            emphasis: {
              disabled: item.isSelectable === false,
            },
            select: {
              disabled: item.isSelectable === false,
              itemStyle: itemStyleSelect,
            },
            tooltip: {
              formatter: item.title + ' (' + item.id + ')',
            },
          };
        }),
      },
    ],
  };
});

// dispatch action. Type can be select, unselect or toggleSelect
function selectAction(type, ids) {
  geoChart.value.dispatchAction({ type, name: ids });
}

function syncSelectedFromChartToggle(param) {
  const selectedOld = props.selected;
  const { data } = param;
  if (data.select.disabled) return;
  const unselect = props.singleSelect
    ? selectedOld === data.id
    : selectedOld.includes(data.id);
  if (unselect && props.singleSelect) emit('geo-selected', null);
  else if (unselect && !props.singleSelect) emit('geo-unselected', data.id);
  else emit('geo-selected', data.id);
}

function syncGeoSelect() {
  selectAction(
    'unselect',
    props.geoItems.map((e) => e.id),
  );
  selectAction('select', props.selected);
}

watch(
  () => props.selected,
  () => {
    syncGeoSelect();
  },
);

async function defaultSelectOneMunicipality() {
  await nextTick();
  if (props.geoItems.length === 1) {
    selectAction(
      'select',
      props.geoItems.map((e) => e.id),
    );
  }
}
</script>
