import { ref } from 'vue';
import { rules as validationRules } from '../utils/validationRules';

export function useValidation() {
  const isValid = ref(true);
  const errorText = ref(null);

  function checkValidationRules(rules, input, setErrorText = false) {
    isValid.value = true;
    errorText.value = null;

    if (rules) {
      for (let i = 0; i < rules.length; i++) {
        const ruleResult = rules[i](input);
        if (ruleResult !== true) {
          isValid.value = false;
          errorText.value = setErrorText ? ruleResult : null;
          break;
        }
      }
    }
  }

  function mapValidationRules(rulesProp) {
    if (typeof rulesProp !== 'object' || rulesProp === null) {
      return [];
    }

    const validators = [];
    Object.keys(rulesProp).forEach((ruleKey) => {
      const ruleValue = rulesProp[ruleKey];
      const validationFunction = validationRules[ruleKey];

      if (typeof validationFunction === 'function') {
        if (typeof ruleValue === 'boolean') {
          if (ruleValue) {
            // If the rule is a simple boolean and is true, use the rule directly.
            validators.push(validationFunction);
          }
          // if the rule is boolean and false, just ignore it
        } else {
          // Treat all non-boolean rule values as arguments to be spread into the validation function.
          validators.push(validationFunction(...[].concat(ruleValue)));
        }
      }
    });

    return validators;
  }

  return {
    isValid,
    errorText,
    checkValidationRules,
    mapValidationRules,
  };
}
