<template>
  <div v-if="showFooter" class="p-5 mt-auto">
    <div
      class="bg-dark standard-elevation-2 rounded-[16px] gap-10 flex w-full justify-between body-2 items-center p-5 text-default"
    >
      <span>
        © EnBW Energie Baden-Württemberg AG {{ new Date().getFullYear() }}.
        Alle Rechte vorbehalten.
      </span>
      <a href="https://www.lbd.de/" target="_blank"
        >LBD Beratungsgesellschaft mbH</a
      >
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const showFooter = computed(() => {
  return router.currentRoute.value.meta.hide_footer ? 0 : 1;
});
</script>
