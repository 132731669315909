<template>
  <RouterView v-slot="{ Component }">
    <Transition :name="getTransitionName" mode="out-in">
      <div v-if="Component" class="flex flex-col gap-5">
        <component :is="Component" />
      </div>
    </Transition>
  </RouterView>
</template>
<script setup>
import { usePageTransitionStore } from '@/composables/usePageTransitionStore';

const { getTransitionName } = usePageTransitionStore();
</script>
