import { RENEWABLE_GENERATION, POWER_DEMAND } from '@/configs/color-constants';

export function seriesChart(
  generationPotentialTotal,
  demandTotal,
  demandStatusQuo,
  demandElectrificationMobility,
  demandElectrificationHeatSingle,
  demandElectrificationHeatNetwork,
  demandEfficiency,
  generationPvRoof,
  generationPvFree,
  generationWind,
  generationWater,
  generationBiomass,
  generationTotal,
  totalIncreasePvRoof,
  totalIncreasePvFree,
  totalIncreaseWind,
) {
  let delta = generationPotentialTotal - demandTotal;
  let hiddenDelta = demandTotal;
  let deltaColor = '#70A638';
  if (delta < 0) {
    hiddenDelta = generationPotentialTotal;
    delta = delta * -1;
    deltaColor = '#f32f2f';
  }
  const hiddenStacks = [
    0,
    demandStatusQuo,
    demandStatusQuo + demandElectrificationMobility,
    demandStatusQuo +
      demandElectrificationMobility +
      demandElectrificationHeatSingle,
    demandStatusQuo +
      demandElectrificationMobility +
      demandElectrificationHeatSingle +
      demandElectrificationHeatNetwork -
      demandEfficiency,
    0,
    0,
    0,
    generationTotal,
    0,
    0,
    hiddenDelta,
  ];
  return {
    visibleDataIsStacked: true,
    visibleStacks: [
      {
        name: 'Status Quo',
        itemStyle: {
          color: POWER_DEMAND.TOTAL,
        },
        data: [demandStatusQuo, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        markLine: {
          symbol: 'none',
          silent: true,
          animation: false,
          data: [
            {
              xAxis: 6,
              label: { show: false },
              lineStyle: {
                color: '#ffffff',
                width: 6,
                type: 'solid',
              },
            },
            {
              xAxis: 10,
              label: { show: false },
              lineStyle: {
                color: '#ffffff',
                width: 6,
                type: 'solid',
              },
            },
            [
              {
                coord: [5, demandTotal],
                lineStyle: {
                  color: 'red',
                },
                label: { show: false },
              },
              { coord: [11, demandTotal] },
            ],
            [
              {
                coord: [5, generationPotentialTotal],
                lineStyle: {
                  color: 'red',
                },
                label: { show: false },
              },
              { coord: [11, generationPotentialTotal] },
            ],
          ],
        },
      },
      {
        name: 'Elektri-\nfizierung\nMobilität',
        itemStyle: {
          color: POWER_DEMAND.ELECTRIFICATION_MOBILITY,
        },
        data: [0, demandElectrificationMobility, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: 'Elektri-\nfizierung\nWärme\nEinzeilvers.',
        itemStyle: {
          color: POWER_DEMAND.ELECTRIFICATION_HEAT_SINGLE,
        },
        data: [
          0,
          0,
          demandElectrificationHeatSingle,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
      },
      {
        name: 'Elektri-\nfizierung\nWärmenetz',
        itemStyle: {
          color: POWER_DEMAND.ELECTRIFICATION_HEAT_NETWORK,
        },
        data: [
          0,
          0,
          0,
          demandElectrificationHeatNetwork,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
      },
      {
        name: 'Effizienz-\nsteigerung',
        itemStyle: {
          color: POWER_DEMAND.EFFICIENCY,
        },
        data: [0, 0, 0, 0, demandEfficiency, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: 'Nachfrage 2036',
        itemStyle: {
          color: POWER_DEMAND.TOTAL,
        },
        data: [0, 0, 0, 0, 0, demandTotal, 0, 0, 0, 0, 0, 0],
      },
      {
        name: 'PV-Aufdach',
        itemStyle: {
          color: RENEWABLE_GENERATION.PV_ROOF,
        },
        data: [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          generationPvRoof,
          totalIncreasePvRoof,
          0,
          0,
          0,
        ],
      },
      {
        name: 'PV-Freifläche',
        itemStyle: {
          color: RENEWABLE_GENERATION.PV_FREE,
        },
        data: [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          generationPvFree,
          totalIncreasePvFree,
          0,
          0,
          0,
        ],
      },
      {
        name: 'Wind',
        itemStyle: {
          color: RENEWABLE_GENERATION.WIND,
        },
        data: [0, 0, 0, 0, 0, 0, 0, generationWind, totalIncreaseWind, 0, 0, 0],
      },
      {
        name: 'Wasser',
        itemStyle: {
          color: RENEWABLE_GENERATION.HYDRO,
        },
        data: [0, 0, 0, 0, 0, 0, 0, generationWater, 0, 0, 0, 0],
      },
      {
        name: 'Biomasse',
        itemStyle: {
          color: RENEWABLE_GENERATION.BIOMASS,
        },
        data: [0, 0, 0, 0, 0, 0, 0, generationBiomass, 0, 0, 0, 0],
      },
      {
        name: 'Gesamterzeugung',
        itemStyle: {
          color: RENEWABLE_GENERATION.TOTAL,
        },
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, generationPotentialTotal, 0, 0],
      },
      {
        name: 'Delta',
        itemStyle: {
          color: deltaColor,
        },
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, delta],
      },
    ],
    hiddenStacks,
  };
}
