<template>
  <div
    class="flex justify-between w-full panelItem py-1 border-b border-infra-highlight-200 hover:border-none singleItemcustom"
  >
    <div class="flex items-center relative body-3" :style="{ width: '60%' }">
      {{ item.label }}
    </div>
    <div
      :style="{ width: '38%' }"
      class="flex items-center"
      :class="
        item.type !== 'boolean'
          ? 'overflow-hidden hover:overflow-visible hover:z-10 justify-start'
          : 'justify-end'
      "
    >
      <div class="flex w-full caption-1">
        <span class="whitespace-nowrap">{{ item.unit }}</span>
        <div class="flex justify-end w-fit ml-auto white-space: normal">
          <div>
            <div>
              <div v-if="item.value.length < 15" class="">
                {{ item.value }}
              </div>
              <div v-else>
                <v-tooltip :text="item.value">
                  <template #activator="{ props }">
                    <div v-bind="props">
                      {{ sliceString() }}
                    </div>
                  </template>
                </v-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  methods: {
    sliceString() {
      const itemValue = this.item.value;
      if (itemValue.length > 15) return `${itemValue.slice(0, 14)}...`;
      else return itemValue;
    },
  },
};
</script>

<style lang="scss">
.panelItem {
  .left-outside-custom {
    box-shadow: -1px 1px 1px 0 rgba(0, 0, 0, 0.1);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .right-outside-custom {
    box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.1);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
</style>
