<template>
  <TopHeaderBar />
  <div class="flex flex-col gap-5">
    <div class="flex items-center gap-4">
      <IconWrapper
        v-if="IconAnalysis"
        :size="64"
        :icon="IconAnalysis"
        fill="text-core-color2"
      />
      <h1 class="text-title-neutral">Potentialanalyse</h1>
    </div>
    <skeleton-loader-list v-if="false" />
    <div v-else class="flex flex-col gap-5">
      <VuetifyDataTable
        :headers="tableHeadersPotentials"
        :item-data="[]"
        :show-expand="false"
      >
      </VuetifyDataTable>
      <ButtonEl
        v-if="!showForm"
        icon="add"
        icon-fill="text-inverted-color1"
        text="Neu"
        class="w-fit"
        @click="showModal = true"
      />
      <VuetifyDialog v-model="showModal" title="Datengrundlage">
        <template #content>
          <p class="body-1 mb-5">
            Legen Sie die Datengrundlage der Szenarien-Erstellung fest:
          </p>
          <div class="elevation-1 rounded-lg">
            <VRadioGroup v-model="potentialAnalysis.data_basis">
              <div
                class="p-5 border-b-line-disabled-neutral border-b cursor-pointer"
                @click="potentialAnalysis.data_basis = 'administrative'"
              >
                <VRadio value="administrative">
                  <template #label
                    ><h5 class="ml-5">
                      Gebietsauswahl über administrative Grenzen
                    </h5></template
                  ></VRadio
                >
              </div>
              <div
                class="flex items-center p-5 cursor-pointer"
                @click="potentialAnalysis.data_basis = 'projectPipeline'"
              >
                <VRadio value="projectPipeline">
                  <template #label>
                    <h5 class="ml-5">
                      EnBW-Projektpipeline PV-Freifläche
                    </h5></template
                  ></VRadio
                >
              </div>
            </VRadioGroup>
          </div>
        </template>
        <template #actions>
          <div class="flex justify-between w-full gap-5">
            <ButtonEl
              text="Abbrechen"
              color="color2"
              @click="showModal = false"
            />
            <ButtonEl
              text="Weiter"
              icon="arrow_forward"
              :icon-right="true"
              @click="
                showModal = false;
                showForm = true;
              "
            />
          </div>
        </template>
      </VuetifyDialog>
    </div>
    <div
      v-if="showForm"
      class="bg-white rounded-[8px] standard-elevation-0-dark p-5 flex flex-col gap-5"
    >
      <!-- Todo: Get label, rules and so on from backend -->
      <div class="flex justify-between items-center">
        <h3 class="text-title-neutral">Konfiguration Potenzialanalyse</h3>
        <div class="flex items-center">
          <ButtonEl text="Berechnung starten" icon="table_view" />
          <IconWrapper
            icon="close"
            class="cursor-pointer ml-5"
            fill="text-core-color1"
            @click="showForm = false"
          />
        </div>
      </div>
      <!-- Min-height to not cut off FilterDropdown, when all items filtered away -->
      <FormTabs :tabs="tabs" custom-window-classes="min-h-[300px]">
        <template #slot0>
          <PotentialAnalysisGeoSelection
            :potential-analysis="potentialAnalysis"
            @update:potential-analysis="potentialAnalysis = { ...$event }"
          />
        </template>
        <template #slot1>
          <div class="flex gap-5 w-1/2">
            <InputEl
              v-model="potentialAnalysis.min_area"
              label="Zusammenhängende Mindestfläche"
              input-type="integer"
              placeholder="Bitte eingeben"
              class="w-full"
              suffix="ha"
            />
            <InputEl
              v-model="potentialAnalysis.max_estates_on_area"
              label="Maximale Anzahl Flurstücke auf Fläche"
              input-type="integer"
              placeholder="Bitte eingeben"
              class="w-full"
            />
          </div>
          <h5 class="mb-5 mt-7 text-title-neutral">Netzanschluss</h5>
          <div class="flex gap-5 mb-5">
            <div class="w-full standard-elevation-0 py-7 px-5">
              <div class="flex">
                <CheckboxEl v-model="potentialAnalysis.medium_voltage" />
                <span class="body-2 text-neutral ml-4 mt-1"
                  >Mittelspannung</span
                >
                <ToolTip
                  tooltip-text="Beim Anschluss an die Mittelspannung können aufgrund der Datenlage freie Netzkapazitäten bei der Netzanschlusssuche berücksichtigt werden."
                  class="ml-4"
                />
              </div>
            </div>
            <div class="w-full standard-elevation-0 py-7 px-5">
              <div class="flex">
                <CheckboxEl v-model="potentialAnalysis.high_voltage" />
                <span class="body-2 text-neutral ml-4 mt-1">Hochspannung</span>
                <ToolTip
                  tooltip-text="Beim Anschluss an die Hochspannung können aufgrund der Datenlage keine freien Netzkapazitäten berücksichtigt werden. Der Netzanschluss erfolgt direkt zum nächsten Mast der Hochspannungsinfrastruktur."
                  class="ml-4"
                />
              </div>
            </div>
          </div>
        </template>
        <template #slot2>
          <PotentialAnalysisRestrictions
            :potential-analysis="potentialAnalysis"
            @update:potential-analysis-distance="
              potentialAnalysis.distance = $event
            "
          />
        </template>
        <template #slot3>
          <!-- Todo: Listen on surface-active-fields and slope-active-fields and prevent submitting them if needed -->
          <PotentialAnalysisAreaQualification
            :potential-analysis="potentialAnalysis"
            @update:potential-analysis="potentialAnalysis = { ...$event }"
        /></template>
      </FormTabs>
    </div>
  </div>
</template>

<script setup>
import TopHeaderBar from '@/components/header-component/TopHeaderBar.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import SkeletonLoaderList from '@/components/skeleton-loader/skeletonLoaderList.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import IconAnalysis from '@/assets/icons/custom/misc/IconAnalysis.vue';
import VuetifyDataTable from '@/components/vuetifyDataTable/VuetifyDataTable.vue';
import FormTabs from '@/components/formTabs/FormTabs.vue';
import InputEl from '@/components/input/InputEl.vue';
import { ref } from 'vue';
import { PotentialAnalysisDto } from '@/apps/usecase-2/potential-analysis/potential-analysis-dto';
import CheckboxEl from '@/components/CheckboxEl/CheckboxEl.vue';
import PotentialAnalysisRestrictions from '@/apps/usecase-2/potential-analysis/PotentialAnalysisRestrictions.vue';
import PotentialAnalysisAreaQualification from '@/apps/usecase-2/potential-analysis/PotentialAnalysisAreaQualification.vue';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import {
  tableHeadersPotentials,
  tabs,
} from '@/apps/usecase-2/potential-analysis/potential-analysis-data';
import PotentialAnalysisGeoSelection from '@/apps/usecase-2/potential-analysis/PotentialAnalysisGeoSelection.vue';
import { useBaseStore } from '@/apps/basemodule/base-store';
import VuetifyDialog from '@/components/vuetifyDialog/VuetifyDialog.vue';

const baseStore = useBaseStore();

const showForm = ref(false);
const showModal = ref(false);
const potentialAnalysis = ref(new PotentialAnalysisDto());

await baseStore.fetchSimplifiedMuniData();

function createInstance() {}
</script>
