import { getSidebarSimple } from './geographical-level';
import { polygonClickEnabledStyle } from './utils';

export const LAYER_KEY__ESTATE = 'estate';

export default {
  [LAYER_KEY__ESTATE]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      app_name: 'scenario',
      router_name: 'estate',
      key_name: 'id',
      type: 'polygon',
      id: LAYER_KEY__ESTATE,
      sourceLayer: LAYER_KEY__ESTATE,
      layerId: LAYER_KEY__ESTATE,
      visibility: 'none',
    },
    sidebar: getSidebarSimple('Flurstück', [
      'scenario_name',
      'id',
      'parcel_nr',
      'parcel_numerator',
      'parcel_denominator',
      'district_name',
      'municipality_name',
    ]),
    style: {
      default: polygonClickEnabledStyle(['rgba(255,255,255,0)']),
    },
  },
};
