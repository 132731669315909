export const headers = [
  {
    title: 'Projektbezeichnung',
    key: 'title',
    align: 'left',
  },
  {
    title: 'Erstellt am',
    key: 'created_at',
    width: '150px',
    align: 'center',
  },
  {
    title: 'Zieljahr',
    key: 'target_year',
    width: '150px',
    align: 'center',
  },
  {
    title: 'Status',
    key: 'progress',
    width: '110px',
    align: 'center',
  },
  {
    title: '',
    key: 'functions',
    width: '230px',
    align: 'end',
    sortable: false,
  },
];
