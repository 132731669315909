import { useRoute, useRouter } from 'vue-router';

export function useGoBack(defaultRoute = { name: 'landingURL' }) {
  const router = useRouter();
  const route = useRoute();

  return function goBack(customRouteName = null) {
    if (customRouteName) {
      // Navigate to the custom route if provided
      router.push({ name: customRouteName });
    } else {
      // Proceed with the default behavior
      const currentPath = route.path;
      const parentPath = currentPath.substring(0, currentPath.lastIndexOf('/'));
      if (parentPath) {
        router.push(parentPath);
      } else {
        router.push(defaultRoute); // Fallback if no parent path found
      }
    }
  };
}
