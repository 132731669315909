import { restFetch, restFetchAll } from '@/utils/rest-utils';
import { ProjectDto } from '@/apps/usecase-1/projects/project-dto';
import { ScenarioDto } from '@/apps/usecase-1/scenario/scenario-dto';

export class Project {
  projectDto;
  scenarioDtos;

  constructor(projectDto, scenarioDtos = []) {
    this.projectDto = projectDto;
    this.scenarioDtos = scenarioDtos;
  }

  static async fetchAllDeep() {
    const projectDtos = await restFetchAll(ProjectDto);
    const scenarioDtos = await restFetchAll(ScenarioDto);
    const projects = [];
    for (const projectDto of projectDtos) {
      projects.push(
        new Project(
          projectDto,
          scenarioDtos.filter(
            (scenarioDto) => scenarioDto.project === projectDto.id,
          ),
        ),
      );
    }
    return projects;
  }

  static async fetchDeep(projectId) {
    const projectDto = await restFetch(ProjectDto, projectId);
    const scenarioDtos = await restFetchAll(ScenarioDto);
    return new Project(
      projectDto,
      scenarioDtos.filter(
        (scenarioDto) => scenarioDto.project === projectDto.id,
      ),
    );
  }
}
