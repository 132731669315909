<template>
  <div>
    <FallbackContainer v-if="error" :error="true" />
    <slot v-else />
  </div>
</template>

<script setup>
import FallbackContainer from '@/components/fallback-container/FallbackContainer.vue';
import { onErrorCaptured, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

const error = ref(null);
const route = useRoute();

// Capture errors
onErrorCaptured((err, instance, info) => {
  if (!import.meta.env.DEV) {
    // If not in dev mode, simply propagate the error
    return true;
  }
  console.error('Error: ', err, ', info:', info);
  error.value = err;
  return false;
});

// Watch for route changes and reset error
watch(route, () => {
  error.value = null;
});
</script>
