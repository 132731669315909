import { defineStore } from 'pinia';
import { axios } from '@/utils/axiosHelper';

export const useAuth = defineStore('auth', {
  actions: {
    async loginUser(username, password) {
      await axios({
        method: 'POST',
        url: '/api/login/',
        data: { username, password },
      });
    },
    async logoutUser() {
      await axios({
        method: 'GET',
        url: '/api/logout/',
      });
    },
  },
});
