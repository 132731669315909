import IconUrbaneStandorte from '@/assets/icons/custom/misc/IconUrbaneStandorte.vue';
import IconDevelopment from '@/assets/icons/custom/misc/IconDevelopment.vue';
import IconInfrastrukturErzeugung from '@/assets/icons/custom/misc/IconInfrastrukturErzeugung.vue';
import IconEnergieEffizienz from '@/assets/icons/custom/misc/IconEnergieEffizienz.vue';
import IconInfrastrukturSystem from '@/assets/icons/custom/misc/IconInfrastrukturSystem.vue';
import IconFlaechendeckendesLadenetz from '@/assets/icons/custom/misc/IconFlaechendeckendesLadenetz.vue';
import IconSmartMobilityPortal from '@/assets/icons/custom/misc/IconSmartMobilityPortal.vue';
import IconPresse from '@/assets/icons/custom/misc/IconPresse.vue';

export const ProjectDetailTabs = [
  {
    key: 'overview',
    name: 'Übersicht\nProjektgebiet',
    subUrl: 'overview',
    hasExtraRouterParameter: true,
    color: '#E2491B',
    icon: IconUrbaneStandorte,
  },
  {
    key: 'demand-generation',
    name: 'Bestandsanalyse & Nachfrage\u00ADentwicklung',
    subUrl: 'demand-generation',
    hasExtraRouterParameter: true,
    color: '#FE8F11',
    icon: IconDevelopment,
  },
  {
    key: 'renewable-generation',
    name: 'EE-Erzeugung',
    subUrl: 'renewable-generation',
    hasExtraRouterParameter: true,
    color: '#AD8653',
    icon: IconInfrastrukturErzeugung,
  },
  {
    key: 'area-evaluation',
    name: 'Gebiets\u00ADauswertung',
    subUrl: 'area-evaluation',
    hasExtraRouterParameter: true,
    color: '#70A638',
    icon: IconFlaechendeckendesLadenetz,
  },
  {
    key: 'scenario-comparison',
    name: 'Szenarien\u00ADvergleich',
    subUrl: 'scenario-comparison',
    hasExtraRouterParameter: true,
    color: '#119C8D',
    icon: IconSmartMobilityPortal,
  },
  {
    key: 'power-balance',
    name: 'Mengen- und Leistungsbilanz',
    subUrl: 'power-balance',
    hasExtraRouterParameter: true,
    color: '#077B6F',
    icon: IconEnergieEffizienz,
  },
  {
    key: 'grid-planning',
    name: 'Netzplanung',
    subUrl: 'grid-planning',
    hasExtraRouterParameter: true,
    color: '#0059B9',
    icon: IconInfrastrukturSystem,
  },
  {
    key: 'project-reports',
    name: 'Berichte',
    subUrl: 'project-reports',
    hasExtraRouterParameter: true,
    color: '#000099',
    icon: IconPresse,
  },
];
