<template>
  <VChart
    :option="chartOptions"
    autoresize
    class="standard-elevation-0-dark h-full w-full"
  />
</template>

<script setup>
import { computed } from 'vue';
import { formatChartAxisLabel, formatChartTooltip } from '@/utils/chart-utils';

const props = defineProps({
  data: {
    type: Object,
    required: true,
    default: () => ({}),
  },
  labels: {
    type: Object,
    default: () => {},
  },
  legend: {
    type: [Array, Object],
    default: () => [],
  },
  title: {
    type: String,
    default: null,
  },
  gridTop: {
    type: String,
    default: '5%',
  },
  gridLeft: {
    type: String,
    default: '5%',
  },
  gridRight: {
    type: String,
    default: '3%',
  },
  gridBottom: {
    type: String,
    default: '3%',
  },
  titleTop: {
    type: String,
    default: '10px',
  },
  showYAxisLine: {
    type: Boolean,
    default: false,
  },
  showYAxisLabel: {
    type: Boolean,
    default: true,
  },
  unit: {
    type: String,
    default: null,
  },
  showTooltip: {
    type: Boolean,
    default: true,
  },
  yAxisLabelCustomFixedWidth: {
    type: Number,
    default: null,
  },
  yAxisName: {
    type: String,
    default: '',
  },
});

const chartOptions = computed(() => {
  const option = {
    backgroundColor: '#FFF',
    title: {
      text: props.title,
      left: 'center',
      top: props.titleTop,
      textStyle: {
        fontFamily: 'mark',
        fontWeight: 500,
        fontSize: 18,
        lineHeight: 20,
        color: '#7E7570',
      },
    },
    grid: {
      top: props.gridTop,
      left: props.gridLeft,
      right: props.gridRight,
      bottom: props.gridBottom,
      containLabel: true,
    },
    tooltip: {
      show: props.showTooltip,
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      textStyle: {
        fontFamily: 'mark',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: 20,
        color: '#7E7570',
      },
      backgroundColor: 'rgba(255, 255, 255, 0.95)',
      borderWidth: 0,
      borderRadius: 0,
      boxShadow: '0 0.5px 8px 0 rgba(207, 216, 220, 0.50)',
      formatter: (params) => {
        const value = params.filter((param) => param.componentIndex === 1)[0]
          .value;
        return `${formatChartTooltip(value, props.unit)}`;
      },
    },
    xAxis: {
      type: 'category',
      data: props.labels,
      axisLine: {
        show: true,
        lineStyle: {
          color: '#C3BCB6',
          width: 3,
        },
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        interval: 0,
        fontFamily: 'mark',
        fontWeight: 500,
        fontSize: 12,
        lineHeight: 16,
        color: '#322A26',
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: ['#E0E6F1'],
        },
      },
    },
    yAxis: {
      type: 'value',
      axisTick: {
        show: false,
      },
      name: props.yAxisName,
      nameGap: 16,
      nameTextStyle: {
        fontFamily: 'mark',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: 16,
        color: '#322A26',
        align: 'right',
      },
      axisLabel: {
        show: props.showYAxisLabel,
        fontFamily: 'mark',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: 16,
        color: '#322A26',
        formatter: (value) => {
          if (props.yAxisLabelCustomFixedWidth) {
            return `{fixedWidth|${formatChartAxisLabel(value)}}`;
          }
          return formatChartAxisLabel(value);
        },
        rich: {
          fixedWidth: {
            width: props.yAxisLabelCustomFixedWidth,
            fontFamily: 'mark',
            fontWeight: 500,
            fontSize: 14,
            lineHeight: 16,
            color: '#322A26',
          },
        },
      },
      splitLine: {
        show: props.showYAxisLine,
        lineStyle: {
          color: ['#E0E6F1'],
        },
      },
    },
    legend: {
      selectedMode: false,
      show: true,
      itemWidth: 12,
      itemHeight: 12,
      textStyle: {
        fontFamily: 'enbw',
        fontWeight: 700,
        fontSize: 12,
        color: '#322A26',
      },
      ...props.legend,
    },
    series: [
      {
        type: 'bar',
        stack: 'Total',
        barWidth: '32px',
        data: props.data.hiddenStacks,
        itemStyle: {
          color: 'transparent',
        },
      },
    ],
  };

  if (props.data.visibleDataIsStacked) {
    for (const stack of props.data.visibleStacks) {
      option.series.push({
        ...stack,
        type: 'bar',
        stack: 'Total',
        label: {
          show: false,
        },
      });
    }
  } else {
    option.series.push({
      type: 'bar',
      stack: 'Total',
      label: {
        show: false,
      },
      data: props.data.visibleStacks,
    });
  }

  return option;
});
</script>
