<template>
  <div
    class="flex flex-col switchery-layer-custom"
    :class="{ 'apply-border': !active }"
  >
    <SwitchItem
      v-model="active"
      size="small"
      class="p-2.5 pr-3 bg-active-area"
      :label="menuItem.title"
      :data-test="`switch-item-${menuItem.title}`"
    />

    <!-- Sub-Radio-List -->
    <template v-if="menuItem.radioGroups">
      <div class="radio-list-container" :class="{ 'expand-list': active }">
        <VRadioGroup
          v-for="radioGroup in menuItem.radioGroups"
          :key="radioGroup.index"
          v-model="radioGroup.activated"
          hide-details="auto"
          class="bg-active-area"
        >
          <div
            v-for="(item, index) in radioGroup.radios"
            :key="index"
            class="flex justify-between items-center pl-2.5 pr-[9px] py-[7px] radio-custom not-1st-level"
            :class="{
              'border-t border-t-line-disabled-neutral': index === 0,
              'border-t border-t-white': index === 0,
            }"
          >
            <VRadio :value="index" @change="emitRadioAction(item)">
              <template #label>
                <span
                  class="body-2 text-neutral pt-0.5"
                  :data-test="item.title.replace(/\s+/g, '_')"
                >
                  {{ item.title }}
                </span>
              </template>
            </VRadio>
          </div>
        </VRadioGroup>
      </div>
    </template>
    <!-- / Sub-Radio-List -->
  </div>
  <!-- / Switch-List -->
</template>

<script setup>
import SwitchItem from '@/components/SwitchItem/SwitchItem.vue';
import { computed, watch } from 'vue';
import { useMapStore } from '../../map-store';

const props = defineProps({
  menuItem: {
    type: Object,
    default: () => {},
  },
});
const mapStore = useMapStore();

const getSwitchStates = computed(() => mapStore.getSwitchStates);
const active = computed({
  get() {
    return getSwitchStates.value[props.menuItem.switchId];
  },
  set(val) {
    const switches = [props.menuItem.switchId];
    const active = val;
    mapStore.changeSwitch({ switches, active });
  },
});

function emitLayerVisibility(onLayers, active) {
  mapStore.changeLayerVisibility({ onLayers, active });
}

function emitRadioAction(item) {
  if (item.layerState) {
    mapStore.changeLayerState({
      layerKeys: item.onLayers,
      layerState: item.layerState,
    });
  }
  if (item.filter) {
    mapStore.addFilter({
      layerKeys: item.layerKeys,
      filter: item.filter,
    });
  }
}

function emitChangeSwitch(switches, active) {
  mapStore.changeSwitch({ switches, active });
}

watch(active, (val) => {
  const active = val;
  const s = props.menuItem;
  if (s.onLayers) emitLayerVisibility(s.onLayers, active);
  if (s.turnOffSwitches && active) emitChangeSwitch(s.turnOffSwitches, false);
  if (s.onSwitches) emitChangeSwitch(s.onSwitches, active);
  if (typeof s.customFunction === 'function') s.customFunction(active);
});
</script>

<style lang="scss" scoped>
.radio-list-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  width: 100%;

  &.expand-list {
    max-height: 900px;
  }
}
</style>
