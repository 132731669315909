<template>
  <div class="flex flex-col gap-5 p-1">
    <div
      v-for="item in props.data"
      :key="item.id"
      class="standard-elevation-0-dark rounded-[4px] p-2.5 flex items-center justify-between gap-2.5"
    >
      <IconWrapper
        :size="32"
        :icon="IconFileCopy"
        fill="text-color1"
        class="mb-auto"
      />

      <div class="flex flex-col gap-2.5 mr-auto">
        <h6 class="text-color1 leading-normal">{{ item.title }}</h6>
        <div class="body-3 text-neutral leading-normal">
          {{ item.description }}
        </div>
      </div>

      <ButtonEl
        text="Download"
        :icon-right="true"
        icon="download"
        @click="goToLink(item.link)"
      />
    </div>
  </div>
</template>

<script setup>
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import IconFileCopy from '@/assets/icons/custom/misc/IconFileCopy.vue';

const props = defineProps({
  data: {
    type: Array,
    default: null,
  },
});

function goToLink(link) {
  window.open(link, '_blank');
}
</script>
