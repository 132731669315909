<template>
  <div class="flex gap-2">
    <div class="flex gap-0.5">
      <ButtonEl
        class="rounded-[2px] p-0.5"
        size="m"
        :icon="props.icon"
        :color="!toggleIsActive ? 'neutral' : 'color1'"
        :disabled="!layerExists"
        :class="{ 'text-gray-500': !layerExists }"
        @click="toggle()"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import { useMapStore } from '@/apps/usecase-1/map/map-store';

const mapStore = useMapStore();
const emit = defineEmits(['update-style']);

const props = defineProps({
  layerState: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    default: 'layers',
  },
  onLayer: {
    type: [String, null],
    required: true,
  },
});

const toggleIsActive = ref(false);
const layerExists = computed(() => mapStore.getLayers.includes(props.onLayer));
onMounted(() => {
  if (layerStateIsApplied(mapStore.getLayerStates)) toggleIsActive.value = true;
});

// emit Style when button is activated and set Layer visibility accordingly
function toggle() {
  toggleIsActive.value = !toggleIsActive.value;
  if (!props.onLayer) return;
  if (toggleIsActive.value) {
    emit('update-style', props.layerState);
    mapStore.changeLayerState({
      layerKeys: [props.onLayer],
      layerState: props.layerState,
    });
  }
  mapStore.changeLayerVisibility({
    onLayers: [props.onLayer],
    active: toggleIsActive.value,
  });
}

function layerStateIsApplied(layerStates) {
  const styleSelected = layerStates.find((e) => e.layer === props.onLayer);
  const isVisible = mapStore.getLayersVisible.includes(props.onLayer);
  if (typeof styleSelected === 'undefined') return false;
  return props.layerState === styleSelected.state && isVisible;
}

// if layer style changes and is not in this radio group, deactivate toggle
watch(
  () => mapStore.getLayerStates,
  (val) => {
    if (!layerStateIsApplied(val)) toggleIsActive.value = false;
  },
);
</script>

<style lang="scss" scoped>
@import '@/assets/styles';
</style>
