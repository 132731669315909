<template>
  <div>
    <LandingPageHeader />
    <div class="default-layout">
      <RouterView v-slot="{ Component }">
        <Transition :name="getTransitionName" mode="out-in">
          <div v-if="Component" class="flex flex-col gap-5">
            <component :is="Component" />
          </div>
          <slot v-else />
        </Transition>
      </RouterView>
    </div>
  </div>
</template>

<script setup>
import LandingPageHeader from '@/apps/landing-page/components/LandingPageHeader.vue';
import { usePageTransitionStore } from '@/composables/usePageTransitionStore';

const { getTransitionName } = usePageTransitionStore();
</script>

<style lang="scss">
.default-layout {
  min-height: calc(100vh - 220px);
  @apply flex flex-col gap-5 rounded-[8px] relative;

  @media (max-width: 1440px) {
    @apply mx-5;
  }

  @media (min-width: 1441px) {
    max-width: 1360px;
    @apply mx-auto w-full;
  }
}
</style>
