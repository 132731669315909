<template>
  <main class="relative bg-subtle hide-scrollbar min-h-screen flex flex-col">
    <VProgressLinear
      v-if="getLoadingState"
      indeterminate
      class="absolute top-0"
      style="z-index: 1000"
      :color="loadingColor"
    />

    <RouterView v-slot="{ Component, route }">
      <ErrorBoundary>
        <Suspense>
          <Transition :name="getTransitionName" mode="out-in">
            <template v-if="Component">
              <Component :is="currentLayout">
                <Component :is="Component" :key="route.fullPath" />
              </Component>
            </template>
          </Transition>
          <template #fallback>
            <FallbackContainer />
          </template>
        </Suspense>
      </ErrorBoundary>
    </RouterView>

    <FooterSection />

    <SnackBar />
  </main>
</template>

<script setup>
import { computed, onMounted, onUnmounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import cookie from 'vue-cookies';
import AuthLayout from '@/layouts/AuthLayout.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { useTitle } from '@vueuse/core';
import MapLayout from '@/layouts/MapLayout.vue';
import FooterSection from '@/components/footer/FooterSection.vue';
import { useMapStore } from '@/apps/usecase-1/map/map-store';
import SnackBar from '@/components/snack-bar/SnackBar.vue';
import FallbackContainer from '@/components/fallback-container/FallbackContainer.vue';
import ErrorBoundary from '@/components/ErrorBoundary.vue';
import { usePageTransitionStore } from '@/composables/usePageTransitionStore';

const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
cookie.set('django_timezone', clientTimeZone);

const store = useStore();
const mapStore = useMapStore();
const route = useRoute();

const getLoadingState = computed(() => store.getters.getLoadingState);
const { getTransitionName } = usePageTransitionStore();

const currentLayout = computed(() => {
  switch (route.meta.layout) {
    case 'Auth':
      return AuthLayout;
    case 'Map':
      return MapLayout;
    default:
      return DefaultLayout;
  }
});

const loadingColor = computed(() => {
  const onAuthPage = currentLayout.value === 'AuthLayout';
  const onLandingPage = route.meta.name === 'landingURL';
  const darkBackground = onAuthPage || onLandingPage;
  return darkBackground ? 'white' : '#001257';
});

onMounted(() => {
  onResize();
  window.addEventListener('resize', onResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', onResize);
});

function onResize() {
  const height = window.innerHeight;
  mapStore.setContainerHight(height - 170);
}

const title = useTitle('Default Title');

watch(
  () => route.meta.title,
  (newTitle) => {
    if (newTitle) {
      title.value = 'PadE | ' + newTitle;
    } else {
      title.value = 'PadE';
    }
  },
  { immediate: true },
);
</script>
