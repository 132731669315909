import { ref, watch } from 'vue';
import { useBaseStore } from '@/apps/basemodule/base-store';

export async function useSyncGeoMapSelection() {
  const baseStore = useBaseStore();
  const selected = ref([]);
  const municipalitySelectItems = ref([]);
  const municipalities = ref([]);
  const municipalityGeoData = await baseStore.fetchSimplifiedMuniData();

  populateMunicipalities(municipalityGeoData);

  function setSelected(municipalityId) {
    selected.value.push(municipalityId);
    toggleMunicipalitySelectItem(municipalityId);
  }

  function setUnSelected(municipalityId) {
    const index = selected.value.findIndex((e) => e === municipalityId);
    selected.value.splice(index, 1);
    toggleMunicipalitySelectItem(municipalityId);
  }

  function toggleMunicipalitySelectItem(municipalityId) {
    const counties = municipalitySelectItems.value[0];
    for (const county of counties.items) {
      for (const municipality of county.items) {
        if (municipality.value === municipalityId) {
          municipality.checked = !municipality.checked;
        }
      }
    }
  }

  function populateMunicipalities(muniResponse) {
    const multiSelectContent = {
      name: 'Alle Gemeinden',
      value: 'all',
      items: [],
    };
    for (const municipality of muniResponse) {
      const countyId = municipality.county_id;
      const countyName = municipality.county_name;
      municipalities.value.push({
        isSelectable: true,
        key: municipality.key,
        title: municipality.name,
        id: municipality.id.toString(),
        name: municipality.id.toString(),
        geometry_json: municipality.geometry_json,
      });
      if (!multiSelectContent.items.map((e) => e.value).includes(countyId)) {
        multiSelectContent.items.push({
          name: countyName,
          value: countyId,
          expanded: false,
          checked: false,
          items: [],
        });
      }
      multiSelectContent.items
        .find((e) => e.value === countyId)
        .items.push({
          name: municipality.name,
          value: municipality.id.toString(),
        });
    }
    municipalitySelectItems.value.push(multiSelectContent);
  }

  watch(
    municipalitySelectItems,
    (newVal) => {
      const municipalitiesSelected = [];
      const counties = newVal[0];
      for (const county of counties.items) {
        for (const municipality of county.items) {
          if (municipality.checked) {
            municipalitiesSelected.push(municipality.value.toString());
          }
        }
      }
      if (!selected.value.length && !municipalitiesSelected.length) return;
      selected.value = municipalitiesSelected;
    },
    { deep: true },
  );

  return {
    setSelected,
    setUnSelected,
    selected,
    municipalitySelectItems,
    municipalities,
  };
}
