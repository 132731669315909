<template>
  <div
    :class="[
      'flex flex-col w-full justify-center items-center',
      customWrapperPadding,
    ]"
  >
    <h4
      v-if="title"
      class="text-[#7E7570]"
      :style="titleCustomSize ? { 'font-size': titleCustomSize + 'px' } : {}"
      :class="customTitleClasses"
    >
      {{ title }}
    </h4>
    <VChart
      class="h-full w-full"
      :class="customPadding"
      :option="option"
      autoresize
    />
  </div>
</template>

<script setup>
import { sum } from 'lodash';
import { computed } from 'vue';

const props = defineProps({
  chartData: {
    type: Array,
    default: () => [],
    deep: true,
  },
  titleInside: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
  chartUnit: {
    type: String,
    default: '',
  },
  showChartUnitInsideTitle: {
    type: Boolean,
    default: true,
  },
  chartTop: {
    type: String,
    default: '',
  },
  titleTop: {
    type: String,
    default: 'center',
  },
  titleInsideLH: {
    type: Number,
    default: 0,
  },
  titleCustomSize: {
    type: Number,
    default: null,
  },
  multiply: {
    type: Number,
    default: 1,
  },
  customTitleClasses: {
    type: String,
    default: '-mb-5',
  },
  customPadding: {
    type: String,
    default: '',
  },
  customMinHeight: {
    type: String,
    default: '250px',
  },
  customRadius: {
    type: Array,
    default: () => ['35%', '45%'],
  },
  showLegend: {
    type: Boolean,
    default: true,
  },
  showLabel: {
    type: Boolean,
    default: true,
  },
  showInnerTitle: {
    type: Boolean,
    default: true,
  },
  showRawInputInnerTitle: {
    type: Boolean,
    default: false,
  },
  legendSelectedMode: {
    type: Boolean,
    default: false,
  },
  seriesPosition: {
    type: Array,
    default: () => ['50%', '50%'],
  },
  legendTop: {
    type: String,
    default: 'center',
  },
  legendBottom: {
    type: String,
    default: null,
  },
  legendRight: {
    type: String,
    default: '10px',
  },
  legendOrient: {
    type: String,
    default: 'vertical',
  },
  gridBottom: {
    type: String,
    default: '5%',
  },
  gridTop: {
    type: String,
    default: '5%',
  },
  gridLeft: {
    type: String,
    default: '5%',
  },
  gridRight: {
    type: String,
    default: '5%',
  },
  customWrapperPadding: {
    type: String,
    default: 'py-5',
  },
  showTooltip: {
    type: Boolean,
    default: true,
  },
  scaleEmphasis: {
    type: Boolean,
    default: true,
  },
});
const option = computed(() => {
  const filteredChartData = props.chartData.filter((item) => {
    return item.value !== 0 || item.value !== 0.0;
  });

  const sumValues = sum(
    filteredChartData.map((item) => props.multiply * item.value),
  );

  const innerTextUnit = props.showChartUnitInsideTitle ? props.chartUnit : '';

  const innerText =
    `{a|${
      sumValues.toLocaleString(navigator.language, {
        maximumFractionDigits: 0,
      }) + (innerTextUnit ? ` ${innerTextUnit}` : '')
    }}` + (props.titleInside ? `\n {b|${props.titleInside}}` : '');

  // Integrate color codes from chartData
  const dataWithColors = filteredChartData.map((item) => ({
    ...item,
    itemStyle: {
      color: item.color,
      borderWidth: 2, // Set the border width to create a gap
      borderColor: '#FFF',
    },
  }));

  // Determine the alignment for the title based on the series position
  const adjustedCalcLeft =
    parseFloat(
      props.seriesPosition[0] //remove % from string
        .replace('%', '')
        .trim(),
    ) -
    1.3 +
    '%';

  const titleLeftPosition =
    props.seriesPosition[0] === '50%' ? 'center' : adjustedCalcLeft;

  const titleTextAlign = props.seriesPosition[0] === '50%' ? null : 'center';

  return {
    title: {
      show: props.showInnerTitle,
      text: props.showRawInputInnerTitle ? props.titleInside : innerText,
      left: titleLeftPosition,
      textAlign: titleTextAlign,
      z: -1,
      top: props.titleTop,
      textStyle: {
        fontSize: 16,
        color: '#7E7570',
        lineHeight: 20,
        fontFamily: 'mark',
        fontWeight: 500,
        rich: {
          a: {
            fontFamily: 'mark',
            color: 'black',
            fontSize: 13,
            lineHeight: 20,
            fontWeight: 500,
          },
          b: {
            fontSize: 12,
            color: 'black',
            lineHeight: 20,
            fontFamily: 'enbw',
            fontWeight: 500,
          },
        },
      },
    },
    tooltip: {
      trigger: 'item',
      show: props.showTooltip,
      formatter: (params) => {
        const value = params.value;
        // Format the value using toLocaleString
        const formattedValue = value.toLocaleString('de-DE', {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        });

        // Check if the formatted value ends with ",0"
        const finalValue = formattedValue.endsWith(',0')
          ? formattedValue.slice(0, -2) // Remove the ",0" part
          : formattedValue; // Keep the formatted value as is

        return `${finalValue} ${props.chartUnit}`;
      },
      backgroundColor: 'rgba(255, 255, 255, 0.95)',
      borderWidth: 0,
      borderRadius: 0,
      boxShadow: '0 0.5px 8px 0 rgba(207, 216, 220, 0.50)',
      textStyle: {
        fontSize: 12,
        lineHeight: 20,
        color: 'black',
        fontWeight: 500,
        fontFamily: 'mark',
        align: 'center',
        style: 'normal',
      },
    },

    legend: {
      show: props.showLegend,
      itemWidth: 12,
      itemHeight: 12,
      orient: props.legendOrient,
      align: 'left',
      right: props.legendRight,
      top: props.legendBottom ? null : props.legendTop,
      bottom: props.legendBottom,
      selectedMode: props.legendSelectedMode,
      textStyle: {
        fontFamily: 'enbw',
        fontWeight: 700,
        fontSize: 12,
        color: '#322A26',
      },
      formatter: (name) => {
        if (name === 'Gewerbe, Handel und Dienstleistungen') {
          return 'Gewerbe, Handel,\nDienstleistungen';
        }
        return name;
      },
    },
    grid: {
      top: props.gridTop,
      left: props.gridLeft,
      right: props.gridRight,
      bottom: props.gridBottom,
      containLabel: props.showLabel,
    },
    series: [
      {
        type: 'pie',
        radius: props.customRadius,
        center: props.seriesPosition,
        top: props.chartTop,
        data: dataWithColors,
        label: {
          show: props.showLabel,
          formatter: (value) => {
            const percentage = (value.value / sumValues) * 100;
            const decimalPlaces = percentage === 0 || percentage >= 10 ? 0 : 1;
            return `{a|${value.name}}\n{b|${percentage.toLocaleString('de-DE', {
              minimumFractionDigits: decimalPlaces,
              maximumFractionDigits: decimalPlaces,
            })}%}`;
          },
          rich: {
            a: {
              fontSize: 12,
              lineHeight: 20,
              color: 'black',
              fontWeight: 500,
              fontFamily: 'mark',
              align: 'center',
              style: 'normal',
            },
            b: {
              fontSize: 12,
              color: 'black',
              fontFamily: 'enbw',
              align: 'center',
              fontWeight: 500,
            },
          },
        },
        silent: !props.scaleEmphasis,
        emphasis: {
          scale: props.scaleEmphasis,
          scaleSize: 3,
        },
        labelLine: {
          show: true,
        },
        cursor: props.scaleEmphasis ? 'pointer' : 'default',
      },
    ],
  };
});
</script>
