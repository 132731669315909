<template>
  <FormValidator v-model="isFormValid">
    <FormTabs :tabs="tabs">
      <template #slot0>
        <InputEl
          v-model="scenario.title"
          :label="metaData.title.label"
          :rules="{
            ...formRules.title,
            unique: [scenarios.map((item) => item.title)],
          }"
          class="w-[565px] mb-3"
        />
      </template>
      <template #slot1>
        <VuetifyDataTable
          :item-data="assumptionsScenarioOptions"
          :headers
          :show-radio="true"
          :show-expand="false"
          :header-small="true"
          :fixed-header="true"
          :substract-height="468"
          @selected-radio="scenario.assumptions_scenario = $event"
        >
          <template #title="{ item }">
            <div class="flex flex-col gap-3 pr-5 pl-0">
              <h5>{{ item.title }}</h5>
            </div>
          </template>
          <template #created_at="{ item }">
            <div>
              {{ new Date(item.created_at).toLocaleDateString('de-DE') }}
            </div>
          </template>
          <template #functions="{ item }">
            <div class="flex justify-end items-center mr-4">
              <a
                :href="router.resolve({ name: 'AssumptionsScenario' }).href"
                target="_blank"
              >
                <IconWrapper
                  :id="item.id"
                  :size="32"
                  fill="text-core-color1"
                  hover="hover:text-button-primary-hover-color1"
                  icon="article"
                  class="cursor-pointer"
                  type="round"
                />
              </a>
            </div>
          </template>
        </VuetifyDataTable>
      </template>
      <template #slot2>
        <InputEl
          v-model="scenario.demographic_development_yearly_validated"
          :label="metaData.demographic_development_yearly.label"
          :input-type="getFieldType(metaData.demographic_development_yearly)"
          suffix="%"
          class="w-[565px] mb-3"
        />
        <InputEl
          v-model="scenario.economic_development_yearly_validated"
          :label="metaData.economic_development_yearly.label"
          :input-type="getFieldType(metaData.economic_development_yearly)"
          suffix="%"
          class="w-[565px] mb-3"
        />
      </template>
    </FormTabs>
  </FormValidator>
  <div class="flex justify-between items-center gap-5 mt-2">
    <div class="flex gap-5 ml-auto">
      <ButtonEl text="Abbrechen" color="color2" @click="emit('abort')" />
      <ButtonEl
        text="Speichern"
        :disabled="!isFormValid || !scenario.assumptions_scenario"
        @click="save"
      />
    </div>
  </div>
</template>

<script setup>
import { ScenarioDto } from '@/apps/usecase-1/scenario/scenario-dto';
import axios from 'axios';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import FormTabs from '@/components/formTabs/FormTabs.vue';
import {
  convertMetaDataToFormRules,
  getFieldType,
  restGetOrFetchMetaData,
  restPost,
} from '@/utils/rest-utils';
import InputEl from '@/components/input/InputEl.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import FormValidator from '@/components/FormValidator.vue';
import VuetifyDataTable from '@/components/vuetifyDataTable/VuetifyDataTable.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';

const router = useRouter();

const metaData = await restGetOrFetchMetaData(ScenarioDto);
const formRules = convertMetaDataToFormRules(metaData);

const scenario = ref(new ScenarioDto());
const assumptionsScenarioOptions = ref([]);

const tabs = [
  {
    title: 'Basisparameter',
    icon: 'text_snippet',
  },

  {
    title: 'Szenariorahmen',
    icon: 'insert_chart_outlined',
  },

  {
    title: 'Demografie & Industrieentwicklung',
    icon: 'insert_chart_outlined',
  },
];

const headers = [
  {
    title: 'Szenariorahmen',
    key: 'title',
    align: 'left',
  },
  {
    title: 'Erstellt am',
    key: 'created_at',
    width: '150px',
    align: 'end',
  },
  {
    title: '',
    key: 'functions',
    width: '300px',
    align: 'end',
    sortable: false,
  },
];

const isFormValid = ref(false);

const emit = defineEmits(['abort', 'update-instances', 'instance-created']);
const props = defineProps({
  projectId: {
    type: Number,
    required: true,
  },
  scenarios: {
    type: Array,
  },
});

onMounted(() => {
  axios({
    method: 'GET',
    url: '/api/usecase-1/select-options-assumptions-scenario/',
  }).then((resp) => {
    assumptionsScenarioOptions.value = resp.data.result;
  });
});

function save() {
  scenario.value.project = props.projectId;
  restPost(scenario.value, [
    'title',
    'assumptions_scenario',
    'demographic_development_yearly',
    'economic_development_yearly',
    'project',
  ])
    .then((resp) => {
      emit('abort');
      emit('instance-created', resp.data.id);
    })
    .catch((e) => {
      console.log(e);
    });
}
</script>
