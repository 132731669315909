import { defineStore } from 'pinia';
import { restFetchAll, restFetchAllWithParam } from '@/utils/rest-utils';
import { ProjectDto } from '@/apps/usecase-1/projects/project-dto';
import { ScenarioDto } from '@/apps/usecase-1/scenario/scenario-dto';

export const useProjectStore = defineStore('projectStore', {
  state: () => ({
    projects: [],
    scenarios: [],
  }),
  actions: {
    async fetchProjects() {
      try {
        this.projects = await restFetchAll(ProjectDto);
        return this.projects;
      } catch (error) {
        console.error('Error fetching projects:', error);
        throw error;
      }
    },
    async fetchScenarios() {
      try {
        this.scenarios = await restFetchAll(ScenarioDto);
        return this.scenarios;
      } catch (error) {
        console.error('Error fetching scenarios:', error);
        throw error;
      }
    },

    async fetchScenarioForProject(projectId) {
      try {
        const params = {
          project_id: projectId,
        };

        // Fetch the single scenario for the given project ID
        const newScenario = await restFetchAllWithParam(ScenarioDto, params);

        // Check if the scenario already exists in the state
        const existingScenario = this.scenarios.find(
          (scenario) => scenario.id === newScenario.id,
        );

        if (existingScenario) {
          // Update the existing scenario
          Object.assign(existingScenario, newScenario);
        } else {
          // Add the new scenario
          this.scenarios.push(newScenario);
        }

        return this.scenarios;
      } catch (error) {
        console.error('Error fetching scenario:', error);
        throw error;
      }
    },

    async fetchProjectsAndScenarios() {
      try {
        await Promise.all([this.fetchProjects(), this.fetchScenarios()]);
      } catch (error) {
        console.error('Error fetching projects and scenarios:', error);
        throw error;
      }
    },
  },
});
