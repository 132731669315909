import {
  SECTOR,
  CONSTRUCTION_YEAR,
  BUILDINGS,
  hexToRgba,
  GENERATION_POTENTIAL,
} from '../../../../configs/color-constants';
import {
  SECTOR_LEGEND,
  CONSTRUCTION_YEAR_CLASS_LEGEND,
  powerLegendType,
  powerStyleType,
  fillExtrusionClickEnabledStyle,
} from './utils';

export const LAYER_KEY__BUILDINGS = 'building';

export default {
  [LAYER_KEY__BUILDINGS]: {
    layoutState: 'default',
    visible: true,
    layerConfig: {
      type: 'polygon',
      subType: 'fillExtrusion',
      id: LAYER_KEY__BUILDINGS,
      sourceLayer: LAYER_KEY__BUILDINGS,
      layerId: LAYER_KEY__BUILDINGS,
      visibility: 'visible',
      dependsOnScenario: true,
    },
    sidebar: {
      name: 'Gebäude',
      subtitles: ['key', 'postal_code', 'district', 'street'],
      chips: [
        {
          title: 'Gebäudestruktur',
          active: true,
          items: [
            {
              title: 'Gebäudeinformationen',
              active: true,
              items: [
                {
                  key: 'function',
                  unit: '',
                },
                {
                  key: 'iwu_type',
                  unit: '',
                },
                {
                  key: 'sector',
                  unit: '',
                },
                {
                  key: 'roof_type',
                  unit: '',
                },
                {
                  key: 'construction_method',
                  unit: '',
                },
                {
                  key: 'construction_year_class',
                  unit: '',
                  forceString: true,
                },
                {
                  key: 'height_m',
                  unit: 'm',
                },
                {
                  key: 'floor_count',
                  unit: '',
                },
                {
                  key: 'usable_area_m2',
                  unit: 'm²',
                },
              ],
            },
          ],
        },
        {
          title: 'Nachfrage',
          active: false,
          items: [
            {
              title: 'Strom',
              active: false,
              items: [
                {
                  key: 'electricity_demand_total_kwh_a',
                  unit: 'kWh(el)/a',
                  decimal: 0,
                },
                {
                  key: 'electricity_demand_total_kw',
                  unit: 'kW(el)',
                  decimal: 0,
                },
              ],
            },
            {
              title: 'Wärme',
              active: false,
              items: [
                {
                  key: 'heat_demand_kwh_a',
                  unit: 'kWh(th)/a',
                  decimal: 0,
                },
                {
                  key: 'heat_demand_kw',
                  unit: 'kW(th)',
                  decimal: 0,
                },
                {
                  key: 'heat_demand_kwh_m2',
                  unit: 'kWh(th)/m²',
                  decimal: 0,
                },
                {
                  key: 'heat_demand_savings_kwh_m2a',
                  unit: 'kWh(th)/m²',
                  decimal: 0,
                },
                {
                  key: 'heat_line_density_target_year_kwh_am',
                  unit: 'kWh(th)/m²',
                  decimal: 0,
                },
                {
                  key: 'heating_type',
                  unit: '',
                  decimal: 0,
                },
              ],
            },
          ],
        },
        {
          title: 'Erzeugung',
          active: false,
          items: [
            {
              title: 'PV-Dach (Potenzial)',
              active: true,
              items: [],
            },
          ],
        },
      ],
    },
    style: {
      default: fillExtrusionClickEnabledStyle(['#888888']),
      sector: fillExtrusionClickEnabledStyle([
        [
          'match',
          ['get', 'sector'],
          0,
          SECTOR.GHD,
          1,
          SECTOR.INDUSTRY,
          2,
          SECTOR.HOUSEHOLD,
          3,
          SECTOR.PUBLIC,
          SECTOR.UNKNOWN,
        ],
      ]),
      constructionYearClass: fillExtrusionClickEnabledStyle([
        [
          'match',
          ['get', 'construction_year_class'],
          0,
          CONSTRUCTION_YEAR.UNTIL_1948,
          1,
          CONSTRUCTION_YEAR.BETWEEN_1949_1968,
          2,
          CONSTRUCTION_YEAR.BETWEEN_1969_2001,
          3,
          CONSTRUCTION_YEAR.AFTER_2001,
          CONSTRUCTION_YEAR.UNKNOWN,
        ],
      ]),
      electricity_demand_total_kwh_a: (year) =>
        fillExtrusionClickEnabledStyle([
          [
            'interpolate',
            ['linear'],
            ['get', `electricity_demand_total_kwh_a_${year}`],
            0,
            hexToRgba(BUILDINGS.ELECTRICITY_DEMAND_MIN_GRADIENT, 0.7),
            1000000,
            hexToRgba(BUILDINGS.ELECTRICITY_DEMAND_MAX_GRADIENT, 0.7),
          ],
        ]),
      electricity_demand_total_kw: (year) =>
        fillExtrusionClickEnabledStyle([
          [
            'interpolate',
            ['linear'],
            ['get', `electricity_demand_total_kw_${year}`],
            0,
            hexToRgba(BUILDINGS.ELECTRICITY_DEMAND_MIN_GRADIENT, 0.7),
            1000,
            hexToRgba(BUILDINGS.ELECTRICITY_DEMAND_MAX_GRADIENT, 0.7),
          ],
        ]),
      heat_demand_total_kwh_a: (year) =>
        fillExtrusionClickEnabledStyle([
          [
            'interpolate',
            ['linear'],
            ['get', `heat_demand_total_kwh_a_${year}`],
            0,
            hexToRgba(BUILDINGS.ELECTRICITY_DEMAND_MIN_GRADIENT, 0.7),
            1000000,
            hexToRgba(BUILDINGS.ELECTRICITY_DEMAND_MAX_GRADIENT, 0.7),
          ],
        ]),
      heat_demand_total_kw: (year) =>
        fillExtrusionClickEnabledStyle([
          [
            'interpolate',
            ['linear'],
            ['get', `heat_demand_total_kw_${year}`],
            0,
            hexToRgba(BUILDINGS.ELECTRICITY_DEMAND_MIN_GRADIENT, 0.7),
            1000,
            hexToRgba(BUILDINGS.ELECTRICITY_DEMAND_MAX_GRADIENT, 0.7),
          ],
        ]),
      potentialPvRoofKw: fillExtrusionClickEnabledStyle(
        powerStyleType(
          'pv_roof_potential_kw',
          { value: 0, color: GENERATION_POTENTIAL.PV_FREE_MIN_GRADIENT },
          { value: 500, color: GENERATION_POTENTIAL.PV_FREE_MAX_GRADIENT },
        ),
      ),
    },
    legend: {
      default: {
        name: 'Gebäude',
        unit: '',
        items: [
          {
            color: BUILDINGS.BUILDING,
            name: 'Gebäude',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
      sector: SECTOR_LEGEND('Gebäude nach Sektoren'),
      constructionYearClass: CONSTRUCTION_YEAR_CLASS_LEGEND(
        'Gebäude nach Baualtersklasse',
      ),
      electricity_demand_total_kwh_a: {
        type: 'gradient',
        name: 'Gebäude nach Strombedarf',
        unit: 'kWh(el)/a',
        items: {
          min: {
            value: 0,
            color: BUILDINGS.ELECTRICITY_DEMAND_MIN_GRADIENT,
          },
          max: {
            value: 1000000,
            color: BUILDINGS.ELECTRICITY_DEMAND_MAX_GRADIENT,
          },
        },
      },
      electricity_demand_total_kw: {
        type: 'gradient',
        name: 'Gebäude nach Stromlast',
        unit: 'kW(el)',
        items: {
          min: {
            value: 0,
            color: BUILDINGS.ELECTRICITY_DEMAND_MIN_GRADIENT,
          },
          max: {
            value: 1000,
            color: BUILDINGS.ELECTRICITY_DEMAND_MAX_GRADIENT,
          },
        },
      },
      heat_demand_total_kwh_a: {
        type: 'gradient',
        name: 'Gebäude nach Wärmebedarf',
        unit: 'kWh(th)/a',
        items: {
          min: {
            value: 0,
            color: BUILDINGS.HEAT_DEMAND_MIN_GRADIENT,
          },
          max: {
            value: 1000000,
            color: BUILDINGS.HEAT_DEMAND_MAX_GRADIENT,
          },
        },
      },
      heat_demand_total_kw: {
        type: 'gradient',
        name: 'Gebäude nach Wärmelast',
        unit: 'kW(th)',
        items: {
          min: {
            value: 0,
            color: BUILDINGS.HEAT_DEMAND_MIN_GRADIENT,
          },
          max: {
            value: 1000,
            color: BUILDINGS.HEAT_DEMAND_MAX_GRADIENT,
          },
        },
      },
      potentialPvRoofKw: powerLegendType(
        'Gebäude nach PV-Dach Potenzial',
        'kW',
        { value: 0, color: GENERATION_POTENTIAL.PV_FREE_MIN_GRADIENT },
        { value: 500, color: GENERATION_POTENTIAL.PV_FREE_MAX_GRADIENT },
      ),
    },
  },
};
