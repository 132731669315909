<template>
  <div class="w-full h-screen relative flex">
    <LayerPanel v-if="loaded && !getResults" v-show="getMainMenuShow" />
    <div class="mapView h-full relative w-full">
      <template v-if="loaded">
        <BaseMap
          :selected-scenario-id="selectedScenarioId"
          :project-base-year="projectBaseYear"
        />
        <div class="top-controls absolute w-full">
          <ControlBar />
          <ScenarioSelector
            :scenarios="project.scenarioDtos"
            :selected-scenario-id
            @select-scenario="selectedScenarioId = $event"
          />
          <LegendControls
            :show-legend="showLegend"
            @toggle-legend-state="showLegend = !showLegend"
          />
        </div>
        <div class="bottom-controls absolute w-full">
          <TimeMachine data-test="time-machine" :years="projectYears" />
          <MapControls v-bind="$attrs" />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { computed, onMounted, ref, watch } from 'vue';
import LayerPanel from '@/apps/usecase-1/map/layer-panel/LayerPanel.vue';
import BaseMap from '@/apps/usecase-1/map/BaseMap.vue';
import ControlBar from '@/apps/usecase-1/map/control-options/components/ControlBar.vue';
import LegendControls from '@/apps/usecase-1/map/control-options/components/LegendControls.vue';
import TimeMachine from '@/apps/usecase-1/map/control-options/components/TimeMachine.vue';
import MapControls from '@/apps/usecase-1/map/control-options/components/MapControls.vue';
import { Project } from '@/apps/usecase-1/map/project';
import { useMapStore } from './map-store';
import ScenarioSelector from '@/apps/usecase-1/map/control-options/components/ScenarioSelector.vue';

const mapStore = useMapStore();
const store = useStore();
const route = useRoute();
const router = useRouter();

const showLegend = ref(false);
const loaded = ref(false);

const getMainMenuShow = computed(() => store.getters.getMainMenuShow);
const getResults = computed(() => {
  return store.getters['search/getResults'];
});

onMounted(async () => {
  if (setupError) {
    await router.push({ name: 'mapProjectSelection', params: {} });
    return;
  }
  initializeMap();
});

function initializeMap() {
  mapStore.resetConfigs();
  animateControls();
  loaded.value = true;
}

function animateControls() {
  setTimeout(() => {
    const bottomControls = document.querySelector('.bottom-controls');
    bottomControls.classList.add('bottom-controls-active');
    const topControls = document.querySelector('.top-controls');
    topControls.classList.add('top-controls-active');
  }, 500);
}

let project;
const selectedScenarioId = ref();
const currentScenarioDto = ref();
const projectBaseYear = ref();
const projectYears = ref();

watch(selectedScenarioId, async (newScenarioId) => {
  console.log('new scenId: ', newScenarioId);
  await router.push({
    name: 'mapView1',
    params: { projectId: project.projectDto.id, scenarioId: newScenarioId },
  });
  currentScenarioDto.value = project.scenarioDtos.find(
    (s) => s.id === newScenarioId,
  );
});

async function getProjectAndScenarios() {
  project = await Project.fetchDeep(route.params.projectId);
  projectBaseYear.value = project.projectDto.base_year;
  projectYears.value = project.projectDto.years;
  if (project.scenarioDtos.length === 0) {
    throw Error('project contains no scenarios');
  }
  currentScenarioDto.value = project.scenarioDtos.find(
    (s) => s.id.toString() === route.params.scenarioId,
  );

  if (currentScenarioDto.value == null) {
    throw Error(
      'Scenario with ID "' + route.params.scenarioId + '" does not exist',
    );
  }
  selectedScenarioId.value = currentScenarioDto.value.id;
  mapStore.scenarioSelected = currentScenarioDto.value;
  mapStore.setYear(projectBaseYear.value);
}

let setupError = false;
try {
  await getProjectAndScenarios();
} catch (err) {
  console.log('Error getting project and scenario: ', err.message);
  //router.push does not work correctly in <script setup> block,
  //therefore set setupError so that onMounted hook does it
  setupError = true;
}
</script>

<style lang="scss" scoped>
.bottom-controls {
  bottom: -100px;
  transition: bottom 1s ease;
}

.bottom-controls-active {
  bottom: 0;
}

.top-controls {
  top: -100px;
  transition: top 1s ease;
}

.top-controls-active {
  top: 20px;
}
</style>
