<template>
  <VSnackbar
    v-model="toast.show"
    :timeout="toast.timeout"
    :color="toast.color"
    :value="true"
    location-strategy="connected"
    class="bottom-[48px]"
  >
    <div v-if="toast.title">
      <h4 class="text-white mb-2">{{ toast.title }}</h4>
    </div>
    <div
      class="body-2 flex flex-col gap-1.5"
      :class="toast.textColor ? 'text-' + toast.textColor : 'text-white'"
    >
      <div
        v-for="(paragraph, index) in toastMessage"
        :key="index"
        class="text-center"
      >
        {{ paragraph }}
      </div>
    </div>
    <template v-if="toast.timeout === -1" #actions>
      <VBtn color="white" text @click="toast.show = false">
        <VIcon icon="mdi-close" data-test="close-toast" />
      </VBtn>
    </template>
  </VSnackbar>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';

const store = useStore();
const toast = computed(() => store.state.layout.toast);
const toastMessage = computed(() =>
  toast.value.message.split('\n\n').map((paragraph) => paragraph.trim()),
);
</script>
