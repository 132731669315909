import { defineStore } from 'pinia';
import { useRouter } from 'vue-router';
import { computed } from 'vue';

export const usePageTransitionStore = defineStore('transitionStore', {
  state: () => ({
    transitionName: 'slide-right',
    previousIndex: null,
  }),

  actions: {
    setTransitionName(to, from) {
      const router = useRouter();

      const findRouteByNameOrPath = (route) => {
        return router.getRoutes().find((r) => {
          return r.name === route.name || r.path === route.path;
        });
      };

      const toRoute = findRouteByNameOrPath(to);
      const fromRoute = findRouteByNameOrPath(from);

      // Determine direction: are we going forward or backward?
      const toRouteIndex = router.getRoutes().indexOf(toRoute);
      const fromRouteIndex = router.getRoutes().indexOf(fromRoute);
      const isForward = toRouteIndex < fromRouteIndex;

      // Always prioritize toRoute's transitionName
      if (toRoute && toRoute.meta?.transitionName) {
        this.transitionName = toRoute.meta.transitionName;
      }
      // If going backward, prioritize fromRoute's transitionName
      else if (!isForward && fromRoute && fromRoute.meta?.transitionName) {
        this.transitionName = fromRoute.meta.transitionName;
      }
      // Default to directional transition if no specific transitionName is set
      else {
        this.transitionName = isForward ? 'slide-right' : 'slide-left';
      }
    },
  },

  getters: {
    getTransitionName: (state) => {
      return computed(() => state.transitionName);
    },
  },
});
