<template>
  <div
    style="width: 366px"
    class="dataPanelExpandablesWrapper flex justify-center self-center"
  >
    <div class="max-w-sm h-full flex-col" style="width: 366px">
      <VExpansionPanels v-model="activeExpandableIndex" variant="accordion">
        <VExpansionPanel
          v-for="(expandable, index) in panelData.chips[activeChipIndex].items"
          :key="index"
          data-test="info-panel-expansion-panel"
          class="rounded-b-[8px]"
        >
          <VExpansionPanelTitle
            class="body-2 pl-[11px] pr-[6px] rounded-none"
            :data-test="expandable.title"
          >
            {{ expandable.title }}
          </VExpansionPanelTitle>
          <VExpansionPanelText class="bg-infra-highlight-25">
            <!-- text content start-->
            <template
              v-if="expandable.items.filter((e) => !e.panelItemType).length > 0"
            >
              <div
                v-for="item in expandable.items"
                :key="item.title"
                class="flex items-center text-black font-medium text-xs hover:bg-white relative box-shadow-custom-hover pointer-events-none px-3"
              >
                <InfoPanelItem :item="item" />
              </div>
            </template>
            <!-- charts start-->
            <template
              v-if="expandable.items.filter((e) => e.panelItemType).length > 0"
            >
              <VCarousel class="carousel-custom" height="fit-content">
                <VCarouselItem
                  v-for="item in expandable.items.filter(
                    (e) => e.panelItemType,
                  )"
                  :key="item.title"
                >
                  <BasicPieChart
                    class="h-72 w-full"
                    :title="item.title"
                    :chart-data="chartData(item)"
                    :title-inside="item.titleInside"
                    :show-inner-title="false"
                    :show-label="false"
                    :chart-unit="item.unit"
                    :title-custom-size="13"
                  />
                </VCarouselItem>
              </VCarousel>
            </template>
            <!-- charts end-->
            <!-- text content end-->
          </VExpansionPanelText>
        </VExpansionPanel>
      </VExpansionPanels>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useStore } from 'vuex';
import InfoPanelItem from '@/apps/usecase-1/map/info-panel/InfoPanelItem.vue';
import BasicPieChart from '@/components/charts/basic-pie-chart/BasicPieChart.vue';
import WaterfallChart from '@/components/charts/WaterfallChart/WaterfallChart.vue';

const store = useStore();

const activeExpandableIndex = ref(0);
const emit = defineEmits(['close-info-panel']);
const props = defineProps({
  panelData: {
    type: Object,
    required: true,
    default: () => ({}),
  },
  activeChipIndex: {
    type: Number,
  },
  feature: {
    type: Object,
  },
});

watch(
  () => props.activeChipIndex,
  () => {
    activeExpandableIndex.value = 0;
  },
);

watch(
  () => props.panelData,
  (newPanelData, oldPanelData) => {
    if (newPanelData.name !== oldPanelData.name) {
      activeExpandableIndex.value = 0;
    }
  },
);

function chartData(item) {
  const data = [];
  if (!item.value) return data;
  item.value.forEach((e) => {
    const series = e[item.seriesFieldName];
    const value = e[item.valueFieldName];
    data.push({
      color: item.style[series].color,
      name: item.style[series].label,
      value: item.multiply ? value * item.multiply : value,
    });
  });
  return data;
}
</script>

<style lang="scss">
.dataPanelExpandablesWrapper {
  border-radius: 0 0 8px 8px;

  .v-input__details {
    display: none;
  }

  .box-shadow-custom {
    box-shadow: 0 1px 6px #00000029;
    padding-bottom: 1px;

    &:last-child {
      margin-bottom: -1px;
    }
  }

  .box-shadow-custom-hover {
    &:hover {
      box-shadow: 0 1px 6px #00000029;
      z-index: 10 !important;

      .singleItemcustom {
        margin-bottom: 1px;
        border-bottom: none !important;
      }
    }

    &:last-child {
      &:hover {
        margin-bottom: -1px;
      }

      .singleItemcustom {
        border-bottom: hidden !important;
      }
    }
  }

  .v-field__append-inner {
    padding-top: 0;
  }

  .v-expansion-panel-text__wrapper {
    @apply p-0;
  }

  .v-expansion-panel-title {
    @apply px-4 py-2;
    min-height: 50px !important;
  }

  .v-expansion-panel-title__overlay {
    opacity: 0;
  }

  .v-expansion-panel-title {
    min-height: 44px !important;
  }

  .v-expansion-panel-title--active {
    background-color: white;
  }

  .v-expansion-panel-title__icon {
    .mdi-chevron-up {
      background-image: url('@/assets/svg/minus-blue.svg');
      background-repeat: no-repeat;
      //background-size: 16px;
      background-position: center center;
    }

    .mdi-chevron-up:before {
      display: none;
    }

    .mdi-chevron-down {
      background-image: url('@/assets/svg/plus-blue.svg');
      background-repeat: no-repeat;
      //background-size: 10px;
      background-position: center center;
    }

    .mdi-chevron-down:before {
      display: none;
    }
  }

  .carousel-custom {
    .v-window__container {
      height: unset !important;
      overflow: hidden;

      .v-responsive {
        display: flex !important;
      }
    }

    .v-window__controls {
      .v-window__left,
      .v-window__right {
        margin-top: auto;
        z-index: 10;
        box-shadow: none;
        width: 40px;
        height: 55px;

        .v-btn__content {
          background: white;
          border-radius: 6px;
          height: 24px;
          width: 24px;

          &:hover {
            @apply bg-infra-highlight-100;
          }
        }
      }
    }

    .v-btn__content {
      .mdi-circle {
        border-radius: 50%;
        background-color: #0692bc;
        opacity: 30%;
        font-size: 12px;

        &:before {
          display: none;
        }
      }

      .mdi-chevron-left:before,
      .mdi-chevron-right:before {
        display: none;
      }

      .mdi-chevron-left {
        background-image: url('@/assets/svg/arrow-left-blue.svg');
        background-repeat: no-repeat;
        background-size: 16px;
        background-position: center center;
      }

      .mdi-chevron-right {
        background-image: url('@/assets/svg/arrow-right-blue.svg');
        background-repeat: no-repeat;
        background-size: 16px;
        background-position: center center;
      }
    }

    .v-ripple__container {
      display: none;
    }

    .v-btn--active > .v-btn__overlay,
    .v-btn[aria-haspopup='menu'][aria-expanded='true'] > .v-btn__overlay {
      opacity: 0;
    }

    .v-carousel__controls {
      background: transparent;
      height: 55px;
      left: 50% !important;
      transform: translateX(-50%) !important;
      gap: 2px;

      .v-btn {
        width: 16px;
        height: 16px;

        &.v-btn--active {
          .mdi-circle {
            opacity: 100%;
          }
        }
      }
    }
  }
}
</style>
