import {
  SECTOR,
  CONSTRUCTION_YEAR,
  AZUR_BLUE_30,
  hexToRgba,
  BUILDINGS,
} from '../../../../configs/color-constants';

export const SECTOR_LEGEND = (name) => ({
  name,
  unit: '',
  items: [
    {
      color: SECTOR.HOUSEHOLD,
      name: 'Private Haushalte',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: SECTOR.PUBLIC,
      name: 'Öffentlich',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: SECTOR.GHD,
      name: 'Gewerbe, Handel und Dienstleistungen',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: SECTOR.INDUSTRY,
      name: 'Industrie',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: SECTOR.UNKNOWN,
      name: 'Sonstige',
      icon: 'mdi-checkbox-blank-circle',
    },
  ],
});

export const CONSTRUCTION_YEAR_CLASS_LEGEND = (name) => ({
  name,
  unit: '',
  items: [
    {
      color: CONSTRUCTION_YEAR.UNTIL_1948,
      name: 'vor 1949',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: CONSTRUCTION_YEAR.BETWEEN_1949_1968,
      name: '1949 bis 1968',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: CONSTRUCTION_YEAR.BETWEEN_1969_2001,
      name: '1969 bis 2001',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: CONSTRUCTION_YEAR.AFTER_2001,
      name: 'nach 2001',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: CONSTRUCTION_YEAR.UNKNOWN,
      name: 'unbekannt',
      icon: 'mdi-checkbox-blank-circle',
    },
  ],
});

export const fillExtrusionClickEnabledStyle = (fillStyle) => ({
  fillExtrusionColor: [
    'case',
    ['boolean', ['feature-state', 'clicked'], false],
    AZUR_BLUE_30,
    ...fillStyle,
  ],
  fillExtrusionHeight: ['get', 'height_m'],
  fillExtrusionOpacity: 0.8,
});

export const polygonClickEnabledStyle = (fillStyle) => ({
  fillColor: [
    'case',
    ['boolean', ['feature-state', 'clicked'], false],
    AZUR_BLUE_30,
    ...fillStyle,
  ],
  fillOpacity: 1,
  fillOutlineColor: '#000000',
});

export const pointClickEnabledStyle = (circleStyle) => ({
  circleColor: [
    'case',
    ['boolean', ['feature-state', 'clicked'], false],
    AZUR_BLUE_30,
    ...circleStyle,
  ],
  circleStrokeWidth: 1,
  circleStrokeColor: 'black',
  circleRadius: 8,
});

export const powerStyleType = (fieldName, min, max) => [
  ['!', ['has', fieldName]],
  hexToRgba(min.color, 1),
  [
    'interpolate',
    ['linear'],
    ['get', fieldName],
    min.value,
    hexToRgba(min.color, 1),
    max.value,
    hexToRgba(max.color, 1),
  ],
];

export const powerLegendType = (legendName, unit, min, max) => ({
  type: 'gradient',
  name: legendName,
  unit,
  items: {
    min: {
      value: min.value,
      color: min.color,
    },
    max: {
      value: max.value,
      color: max.color,
    },
  },
});
