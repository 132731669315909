import {
  CONSTRUCTION_YEAR_CLASS_LEGEND,
  polygonClickEnabledStyle,
  SECTOR_LEGEND,
  powerStyleType,
  powerLegendType,
} from './utils';
import {
  CONSTRUCTION_YEAR,
  CONSTRUCTION_YEAR_BY_ENUM,
  GENERATION_POTENTIAL,
  GEOGRAPHICAL_LEVEL,
  SECTOR,
  SECTOR_BY_ENUM,
  HEATING_TYPE_BY_ENUM,
} from '../../../../configs/color-constants';

export const LAYER_KEY__BLOCK = 'aggregation_buildingblock';
export const LAYER_KEY__DISTRICT = 'aggregation_district';
export const LAYER_KEY__MUNICIPALITY = 'aggregation_municipality';
export const LAYER_KEY__COUNTY = 'aggregation_county';
export const LAYER_KEY__GOVERNMENT_DISTRICT = 'aggregation_government_district';
export const LAYER_KEY__FEDERAL_STATE = 'aggregation_federal_state';

const linearFillStyle = (key, type) => [
  ['==', ['get', key], null],
  'rgba(255,255,255,0)',
  [
    'interpolate',
    ['linear'],
    ['get', key],
    type.min.value * 1000, // convert back to kw
    type.min.color,
    type.max.value * 1000, // convert back to kw
    type.max.color,
  ],
];

const AGGREGATION_STYLE = (name, layerStyle) => {
  return {
    style: {
      default: polygonClickEnabledStyle(['rgba(255,255,255,0)']),
      sector: polygonClickEnabledStyle([
        [
          'match',
          ['get', 'sector'],
          0,
          SECTOR.GHD,
          1,
          SECTOR.INDUSTRY,
          2,
          SECTOR.HOUSEHOLD,
          3,
          SECTOR.PUBLIC,
          SECTOR.UNKNOWN,
        ],
      ]),
      constructionYearClass: polygonClickEnabledStyle([
        [
          'match',
          ['get', 'construction_year_class'],
          0,
          CONSTRUCTION_YEAR.UNTIL_1948,
          1,
          CONSTRUCTION_YEAR.BETWEEN_1949_1968,
          2,
          CONSTRUCTION_YEAR.BETWEEN_1969_2001,
          3,
          CONSTRUCTION_YEAR.AFTER_2001,
          CONSTRUCTION_YEAR.UNKNOWN,
        ],
      ]),
      generationPvFreeKw: polygonClickEnabledStyle(
        powerStyleType(
          'pv_free_generation_kw',
          { value: 0, color: GENERATION_POTENTIAL.PV_FREE_MIN_GRADIENT },
          { value: 100000, color: GENERATION_POTENTIAL.PV_FREE_MAX_GRADIENT },
        ),
      ),
      generationWindKw: polygonClickEnabledStyle(
        powerStyleType(
          'wind_generation_kw',
          { value: 0, color: GENERATION_POTENTIAL.WIND_MIN_GRADIENT },
          { value: 100000, color: GENERATION_POTENTIAL.WIND_MAX_GRADIENT },
        ),
      ),
      generationBiomassKw: polygonClickEnabledStyle(
        powerStyleType(
          'biomass_generation_kw',
          { value: 0, color: GENERATION_POTENTIAL.BIOMASS_MIN_GRADIENT },
          { value: 100000, color: GENERATION_POTENTIAL.BIOMASS_MAX_GRADIENT },
        ),
      ),
      generationHydropowerKw: polygonClickEnabledStyle(
        powerStyleType(
          'hydropower_generation_kw',
          { value: 0, color: GENERATION_POTENTIAL.WATER_MIN_GRADIENT },
          { value: 100000, color: GENERATION_POTENTIAL.WATER_MAX_GRADIENT },
        ),
      ),
      generationPvRoofKw: polygonClickEnabledStyle(
        powerStyleType(
          'pv_roof_generation_kw',
          { value: 0, color: GENERATION_POTENTIAL.PV_FREE_MIN_GRADIENT },
          { value: 100000, color: GENERATION_POTENTIAL.PV_FREE_MAX_GRADIENT },
        ),
      ),
      potentialPvFreeKw: polygonClickEnabledStyle(
        powerStyleType(
          'pv_free_potential_kw',
          { value: 0, color: GENERATION_POTENTIAL.PV_FREE_MIN_GRADIENT },
          { value: 100000, color: GENERATION_POTENTIAL.PV_FREE_MAX_GRADIENT },
        ),
      ),
      potentialWindKw: polygonClickEnabledStyle(
        powerStyleType(
          'wind_potential_kw',
          { value: 0, color: GENERATION_POTENTIAL.WIND_MIN_GRADIENT },
          { value: 100000, color: GENERATION_POTENTIAL.WIND_MAX_GRADIENT },
        ),
      ),
      potentialPvRoofKw: polygonClickEnabledStyle(
        powerStyleType(
          'pv_roof_potential_kw',
          { value: 0, color: GENERATION_POTENTIAL.PV_FREE_MIN_GRADIENT },
          { value: 100000, color: GENERATION_POTENTIAL.PV_FREE_MAX_GRADIENT },
        ),
      ),
      heat_demand_total_kwh_a(property) {
        const key = `heat_demand_total_kwh_a_${property}`;
        return polygonClickEnabledStyle(
          linearFillStyle(key, layerStyle.heat_demand_total_kwh_a),
        );
      },
      heat_demand_total_kw(property) {
        const key = `heat_demand_total_kw_${property}`;
        return polygonClickEnabledStyle(
          linearFillStyle(key, layerStyle.heat_demand_total_kw),
        );
      },
      electricity_demand_total_kwh_a(property) {
        const key = `electricity_demand_total_kwh_a_${property}`;
        return polygonClickEnabledStyle(
          linearFillStyle(key, layerStyle.electricity_demand_total_kwh_a),
        );
      },
      electricity_demand_total_kw(property) {
        const key = `electricity_demand_total_kw_${property}`;
        return polygonClickEnabledStyle(
          linearFillStyle(key, layerStyle.electricity_demand_total_kw),
        );
      },
    },
    legend: {
      sector: SECTOR_LEGEND(`${name} nach Sektoren`),
      constructionYearClass: CONSTRUCTION_YEAR_CLASS_LEGEND(
        `${name} nach Baualtersklasse`,
      ),
      generationPvFreeKw: powerLegendType(
        `${name} nach PV-Freifläche Anlagenleistung`,
        'MW',
        { value: 0, color: GENERATION_POTENTIAL.PV_FREE_MIN_GRADIENT },
        { value: 100000, color: GENERATION_POTENTIAL.PV_FREE_MAX_GRADIENT },
      ),
      generationWindKw: powerLegendType(
        `${name} nach Wind Anlagenleistung`,
        'MW',
        { value: 0, color: GENERATION_POTENTIAL.WIND_MIN_GRADIENT },
        { value: 10000, color: GENERATION_POTENTIAL.WIND_MAX_GRADIENT },
      ),
      generationBiomassKw: powerLegendType(
        `${name} nach Biomasse Anlagenleistung`,
        'MW',
        { value: 0, color: GENERATION_POTENTIAL.BIOMASS_MIN_GRADIENT },
        { value: 10000, color: GENERATION_POTENTIAL.BIOMASS_MAX_GRADIENT },
      ),
      generationHydropowerKw: powerLegendType(
        `${name} nach Wasserkraft Anlagenleistung`,
        'MW',
        { value: 0, color: GENERATION_POTENTIAL.WATER_MIN_GRADIENT },
        { value: 10000, color: GENERATION_POTENTIAL.WATER_MAX_GRADIENT },
      ),
      generationPvRoofKw: powerLegendType(
        `${name} nach PV-Dach Anlagenleistung`,
        'MW',
        { value: 0, color: GENERATION_POTENTIAL.PV_FREE_MIN_GRADIENT },
        { value: 10000, color: GENERATION_POTENTIAL.PV_FREE_MAX_GRADIENT },
      ),
      potentialPvFreeKw: powerLegendType(
        `${name} nach PV-Freifläche Potenzial`,
        'MW',
        { value: 0, color: GENERATION_POTENTIAL.PV_FREE_MIN_GRADIENT },
        { value: 100000, color: GENERATION_POTENTIAL.PV_FREE_MAX_GRADIENT },
      ),
      potentialWindKw: powerLegendType(
        `${name} nach Wind Potenzial`,
        'MW',
        { value: 0, color: GENERATION_POTENTIAL.WIND_MIN_GRADIENT },
        { value: 10000, color: GENERATION_POTENTIAL.WIND_MAX_GRADIENT },
      ),
      potentialPvRoofKw: powerLegendType(
        `${name} nach PV-Dach Potenzial`,
        'MW',
        { value: 0, color: GENERATION_POTENTIAL.PV_FREE_MIN_GRADIENT },
        { value: 10000, color: GENERATION_POTENTIAL.PV_FREE_MAX_GRADIENT },
      ),
      heat_demand_total_kwh_a: {
        type: 'gradient',
        name: `${name} nach Wärmebedarf`,
        unit: 'MWh(th)/a',
        items: layerStyle.heat_demand_total_kwh_a,
      },
      heat_demand_total_kw: {
        type: 'gradient',
        name: `${name} nach Wärmelast`,
        unit: 'MW(th)',
        items: layerStyle.heat_demand_total_kw,
      },
      electricity_demand_total_kwh_a: {
        type: 'gradient',
        name: `${name} nach Strombedarf`,
        unit: 'MWh(el)/a',
        items: layerStyle.electricity_demand_total_kwh_a,
      },
      electricity_demand_total_kw: {
        type: 'gradient',
        name: `${name} nach Stromlast`,
        unit: 'MW(el)',
        items: layerStyle.electricity_demand_total_kw,
      },
    },
  };
};

export function getLayerConfig(layerKey) {
  return {
    type: 'polygon',
    id: layerKey,
    sourceLayer: layerKey,
    layerId: layerKey,
    visibility: 'none',
  };
}

function createStyleConfig(minValue, minColor, maxValue, maxColor) {
  return {
    min: { value: minValue, color: minColor },
    max: { value: maxValue, color: maxColor },
  };
}

export function getStyle(
  heatDemandMax,
  heatLoadMax,
  powerDemandMax,
  powerLoadMax,
) {
  return {
    heat_demand_total_kwh_a: createStyleConfig(
      0,
      GEOGRAPHICAL_LEVEL.HEAT_DEMAND_MIN_GRADIENT,
      heatDemandMax,
      GEOGRAPHICAL_LEVEL.HEAT_DEMAND_MAX_GRADIENT,
    ),
    heat_demand_total_kw: createStyleConfig(
      0,
      GEOGRAPHICAL_LEVEL.HEAT_DEMAND_MIN_GRADIENT,
      heatLoadMax,
      GEOGRAPHICAL_LEVEL.HEAT_DEMAND_MAX_GRADIENT,
    ),
    electricity_demand_total_kwh_a: createStyleConfig(
      0,
      GEOGRAPHICAL_LEVEL.ELECTRICITY_DEMAND_MIN_GRADIENT,
      powerDemandMax,
      GEOGRAPHICAL_LEVEL.ELECTRICITY_DEMAND_MAX_GRADIENT,
    ),
    electricity_demand_total_kw: createStyleConfig(
      0,
      GEOGRAPHICAL_LEVEL.ELECTRICITY_DEMAND_MIN_GRADIENT,
      powerLoadMax,
      GEOGRAPHICAL_LEVEL.ELECTRICITY_DEMAND_MAX_GRADIENT,
    ),
  };
}

function createItem(key, unit = '', decimal = undefined, multiply = undefined) {
  return { key, unit, decimal, multiply };
}

export function getSidebarSimple(name, subtitleKeys) {
  return {
    name,
    subtitles: subtitleKeys,
    chips: [
      {
        title: 'Gebäude',
        active: true,
        items: [
          {
            title: 'Gebäudeinformationen',
            active: true,
            items: [
              createItem('num_buildings'),
              createItem('usable_area_m2', 'm²', 0),
            ],
          },
          {
            title: 'Gebäudestruktur',
            active: false,
            items: [
              {
                panelItemType: 'pieChart',
                title: 'Gebäude nach Sektoren',
                titleInside: 'Gebäude',
                key: 'sectors',
                valueFieldName: 'num_buildings',
                seriesFieldName: 'sector',
                style: SECTOR_BY_ENUM,
                unit: '',
              },
              {
                panelItemType: 'pieChart',
                title: 'Gebäude nach Baualtersklassen',
                titleInside: 'Gebäude',
                key: 'construction_year_classes',
                valueFieldName: 'num_buildings',
                seriesFieldName: 'construction_year_class',
                style: CONSTRUCTION_YEAR_BY_ENUM,
                unit: '',
              },
            ],
          },
        ],
      },
      {
        title: 'Nachfrage',
        active: false,
        items: [
          {
            title: 'Strom',
            active: true,
            items: [
              createItem('electricity_demand_total_kwh_a'),
              createItem('electricity_demand_total_kw'),
            ],
          },
          {
            title: 'Strom - Charts',
            active: false,
            items: [
              {
                panelItemType: 'pieChart',
                title: 'Strombedarf nach Sektoren',
                titleInside: 'Strombedarf',
                key: 'sectors_per_year',
                valueFieldName: 'electricity_demand_total_kwh_a',
                seriesFieldName: 'sector',
                style: SECTOR_BY_ENUM,
                unit: 'MWh(el)',
                multiply: 0.001,
              },
            ],
          },
          {
            title: 'Wärme',
            active: false,
            items: [
              createItem('heat_demand_kwh_a'),
              createItem('heat_demand_kw'),
              createItem('num_refurbished_buildings'),
              createItem('heat_demand_savings_kwh_m2a'),
            ],
          },
          {
            title: 'Wärme - Charts',
            active: false,
            items: [
              {
                panelItemType: 'pieChart',
                title: 'Strombedarf nach Sektoren',
                titleInside: 'Strombedarf',
                key: 'sectors_per_year',
                valueFieldName: 'heat_demand_kwh_a',
                seriesFieldName: 'sector',
                style: SECTOR_BY_ENUM,
                unit: 'MWh(th)',
                multiply: 0.001,
              },
              {
                panelItemType: 'pieChart',
                title: 'Strombedarf nach Heiztechnologie',
                titleInside: 'Strombedarf',
                key: 'heating_types_per_year',
                valueFieldName: 'heat_demand_kwh_a',
                seriesFieldName: 'heating_type',
                style: HEATING_TYPE_BY_ENUM,
                unit: 'MWh(th)',
                multiply: 0.001,
              },
            ],
          },
        ],
      },
      {
        title: 'Erzeugung',
        active: false,
        items: [
          {
            title: 'PV-Dach (Potenzial)',
            active: true,
            items: [
              {
                key: 'pv_roof_power_kw',
                unit: 'MW(el)',
                decimal: 0,
                multiply: 0.001,
              },
              {
                key: 'pv_roof_production_kwh_a',
                unit: 'MWh(el)/a',
                decimal: 0,
                multiply: 0.001,
              },
            ],
          },
        ],
      },
    ],
  };
}

export function getSidebarExtended(name, subtitleKeys) {
  const subtitles = [];
  for (const subtitleKey of subtitleKeys) {
    subtitles.push({ key: subtitleKey });
  }
  return {
    name,
    subtitles,
    chips: [
      {
        title: 'Gebäude',
        active: true,
        items: [
          {
            title: 'Gebäudeinformationen',
            active: true,
            items: [
              createItem('buildings'),
              createItem('usable_area_m2', 'm²', 0),
            ],
          },
          {
            title: 'Gebäudestruktur',
            active: false,
            items: [
              {
                panelItemType: 'pieChart',
                title: 'Gebäude nach Sektoren',
                titleInside: 'Gebäude',
                key: 'sector_count',
                unit: '',
              },
              {
                panelItemType: 'pieChart',
                title: 'Gebäude nach Baualtersklassen',
                titleInside: 'Gebäude',
                key: 'construction_year_count',
                unit: '',
              },
            ],
          },
        ],
      },
      {
        title: 'Nachfrage',
        active: false,
        items: [
          {
            title: 'Wärme',
            active: true,
            items: [
              createItem('heat_demand_kwh'),
              createItem('heat_load_kw'),
              createItem('count_refurbished_buildings'),
              createItem('refurbishment_potential'),
            ],
          },
          {
            title: 'Wärme - Charts',
            active: false,
            items: [
              {
                panelItemType: 'pieChart',
                title: 'Wärmebedarf nach Sektoren',
                titleInside: 'Wärmebedarf',
                key: 'heat_demand_by_sector',
                unit: 'MWh',
                multiply: 0.001,
              },
              {
                panelItemType: 'pieChart',
                title: 'Wärmebedarf nach Heiztechnologie',
                titleInside: 'Wärmebedarf',
                key: 'heat_demand_by_technology',
                unit: 'MWh',
                multiply: 0.001,
              },
            ],
          },
          {
            title: 'Elektromobilität',
            active: false,
            items: [
              {
                key: 'count_ebv_base_year',
                unit: 'kWh/m²',
                decimal: 0,
              },
              {
                key: 'count_ebv',
                unit: 'm²',
                decimal: 0,
              },
              {
                key: 'power_demand_ebv',
                unit: 'MWh(el)/a',
                decimal: 0,
              },
              {
                key: 'power_load_ebv',
                unit: 'MW(el)',
                decimal: 0,
              },
            ],
          },
          // TODO: zusammenfassen in ein expandable? Andere auch
          {
            title: 'Strom',
            active: true,
            items: [
              createItem('power_demand_kwh'),
              createItem('power_load_kw'),
            ],
          },
          {
            title: 'Strom - Charts',
            active: false,
            items: [
              {
                panelItemType: 'pieChart',
                title: 'Strombedarf nach Sektoren (inkl. E-Mobilität)',
                titleInside: 'Strombedarf',
                key: 'power_demand_by_sector',
                unit: 'MWh',
                multiply: 0.001,
              },
              {
                panelItemType: 'pieChart',
                title: 'Strombedarf nach Bedarfstreibern',
                titleInside: 'Strombedarf',
                key: 'power_demand_by_technology',
                unit: 'MWh',
                multiply: 0.001,
              },
            ],
          },
        ],
      },
      {
        title: 'Erzeugung',
        active: false,
        items: [
          {
            title: 'Potenzial',
            active: true,
            items: [
              {
                key: 'renewable_potential_load',
                unit: 'MW(el)',
                decimal: 0,
              },
              {
                key: 'renewable_potential_generation',
                unit: 'MWh(el)/a',
                decimal: 0,
              },
            ],
          },
          {
            title: 'Potenzial - Charts',
            active: false,
            items: [
              {
                panelItemType: 'pieChart',
                title: 'Leistungspotenzial nach EE-Technologien',
                titleInside: 'Leistung',
                key: 'power_load_by_potential',
                unit: 'MW(el)',
                multiply: 0.001,
              },
            ],
          },
        ],
      },
    ],
  };
}

export default {
  [LAYER_KEY__BLOCK]: {
    layoutState: 'default',
    visible: false,
    layerConfig: getLayerConfig(LAYER_KEY__BLOCK, {}),
    sidebar: getSidebarSimple('Block', [
      'scenario_name',
      'id',
      'district_name',
      'municipality_name',
    ]),
    ...AGGREGATION_STYLE('Block', getStyle(80000, 40, 40000, 10)),
  },
  [LAYER_KEY__DISTRICT]: {
    layoutState: 'default',
    layerConfig: getLayerConfig(LAYER_KEY__DISTRICT),
    sidebar: getSidebarSimple('Stadtteil', [
      'scenario_name',
      'id',
      'district_name',
      'municipality_name',
    ]),
    ...AGGREGATION_STYLE('Stadtteil', getStyle(400000, 200, 200000, 50)),
  },
  [LAYER_KEY__MUNICIPALITY]: {
    layoutState: 'default',
    visible: false,
    layerConfig: getLayerConfig(LAYER_KEY__MUNICIPALITY),
    sidebar: getSidebarSimple('Gemeinde', [
      'scenario_name',
      'municipality_name',
    ]),
    ...AGGREGATION_STYLE('Gemeinde', getStyle(800000, 400, 400000, 100)),
  },
  [LAYER_KEY__FEDERAL_STATE]: {
    layoutState: 'default',
    visible: false,
    layerConfig: getLayerConfig(LAYER_KEY__FEDERAL_STATE),
    sidebar: getSidebarSimple('Bundesland', ['scenario_name']),
    ...AGGREGATION_STYLE(
      'Bundesland',
      getStyle(9000000, 50000000000, 6000, 20),
    ),
  },
  [LAYER_KEY__GOVERNMENT_DISTRICT]: {
    layoutState: 'default',
    visible: false,
    layerConfig: getLayerConfig(LAYER_KEY__GOVERNMENT_DISTRICT),
    sidebar: getSidebarSimple('Regierungsbezirk', ['scenario_name']),
    ...AGGREGATION_STYLE(
      'Regierungsbezirk',
      getStyle(9000000, 50000000000, 6000, 20),
    ),
  },
  [LAYER_KEY__COUNTY]: {
    layoutState: 'default',
    visible: false,
    layerConfig: getLayerConfig(LAYER_KEY__COUNTY),
    sidebar: getSidebarSimple('Landkreis', ['scenario_name']),
    ...AGGREGATION_STYLE('Landkreis', getStyle(800000, 4000000000, 6000, 20)),
  },
};
