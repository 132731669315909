<template>
  <div v-if="!currentRouteName.includes('map')" class="w-full p-5 z-[100]">
    <div
      class="flex justify-between relative items-center standard-elevation-2 bg-default w-full p-5 rounded-[16px]"
    >
      <transition name="fade" mode="out-in">
        <LandingPageHeaderLogo v-if="showHeaderLogo" />
        <BreadcrumbNavigation v-else />
      </transition>

      <UseCaseSelector :current-route-name />

      <LandingPageHeaderUserSettings />
    </div>
  </div>
</template>

<script setup>
import LandingPageHeaderLogo from '@/apps/landing-page/components/LandingPageHeaderLogo.vue';
import BreadcrumbNavigation from '@/components/navigation/BreadcrumbNavigation.vue';
import UseCaseSelector from '@/apps/landing-page/components/UseCaseSelector.vue';
import LandingPageHeaderUserSettings from '@/apps/landing-page/components/LandingPageHeaderUserSettings.vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const currentRouteName = computed(() => router.currentRoute.value.name);

const showHeaderLogo = computed(() => {
  const routesToShowLogo = [
    'landingURL',
    'UseCase1',
    'UseCase2',
    'UseCase3',
    'UseCase4',
  ];
  return routesToShowLogo.includes(currentRouteName.value);
});
</script>
