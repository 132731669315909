export class ScenarioDto {
  static BASE_URL = '/api/usecase-1/scenario/';

  id = null;
  title = null;
  project = null;
  assumptions_scenario = null;
  assumptions_scenario_title = null;
  created_by = null;
  created_at = null;
  updated_at = null;
  progress = 2;
  demographic_development_yearly = 0;
  economic_development_yearly = 0;

  get economic_development_yearly_validated() {
    return this.economic_development_yearly;
  }
  set economic_development_yearly_validated(value) {
    this.economic_development_yearly = value || 0;
  }
  get demographic_development_yearly_validated() {
    return this.demographic_development_yearly;
  }
  set demographic_development_yearly_validated(value) {
    this.demographic_development_yearly = value || 0;
  }
}
