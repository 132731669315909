<template>
  <TopHeaderBar />
  <div
    class="flex gap-5 bg-default rounded-[16px] p-5 standard-elevation-2 w-full h-full project-create-container"
  >
    <FormValidator v-model="isFormValid">
      <div class="flex flex-col min-w-[420px] gap-7">
        <InputEl
          v-model="project.title"
          :label="projectMetaData.title.label"
          :rules="{
            ...projectFormRules.title,
            unique: [projectStore.projects.map((item) => item.title)],
          }"
          placeholder="Projekt neu"
        />

        <div class="flex flex-col gap-5">
          <h4>Gebietsauswahl</h4>
          <DropDownMultipleGrouped
            v-model="municipalitySelectItems"
            class="w-full"
            :items="municipalitySelectItems"
            placeholder="Gemeinden auswählen"
            :max-preview-items="1"
            data-key="name"
            value-key="value"
            label="Gebiet"
            item-type-all="Gemeinden"
          />
        </div>
        <div class="flex flex-col gap-5">
          <h4>Jahresauswahl</h4>
          <div class="flex gap-5">
            <!-- TODO: Hier das Jahr vom Stand der Basisdaten -->
            <DatePicker
              v-model="project.base_year"
              year-picker
              :year-range="[2024, 2050]"
              disabled
              label="Basisjahr"
            />
            <DatePicker
              v-model="project.target_year"
              year-picker
              :year-range="[2024, 2050]"
              label="Zieljahr"
            />
          </div>
        </div>
        <ProjectCreateFileUpload
          @file-change="project.thumbnail = $event"
          @is-valid="isCoatOfArmsValid = $event"
        />
      </div>
    </FormValidator>
    <div class="w-full geo-chart-custom-height">
      <GeoMunicipalityChart
        v-if="municipalities.length > 0"
        :selected="selected"
        :geo-items="municipalities"
        @geo-unselected="setUnSelected($event)"
        @geo-selected="setSelected($event)"
      />
      <div
        v-else
        class="flex justify-center items-center geo-chart-custom-height"
      >
        <VProgressCircular
          class="text-title-color1"
          size="30"
          width="3"
          indeterminate
        />
      </div>
    </div>
    <ButtonEl
      :disabled="!isFormValid || selected.length < 1 || !isCoatOfArmsValid"
      icon="add"
      text="Speichern"
      class="w-fit"
      @click="save()"
    />
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import GeoMunicipalityChart from '@/components/charts/GeoMunicipalityChart.vue';
import InputEl from '@/components/input/InputEl.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import DropDownMultipleGrouped from '@/components/dropDownMultipleGrouped/DropDownMultipleGrouped.vue';
import { ProjectDto } from '@/apps/usecase-1/projects/project-dto';
import FormValidator from '@/components/FormValidator.vue';
import {
  convertMetaDataToFormRules,
  restGetOrFetchMetaData,
  restPost,
} from '@/utils/rest-utils';
import TopHeaderBar from '@/components/header-component/TopHeaderBar.vue';
import { useProjectStore } from '@/apps/usecase-1/projects/project-store';
import ProjectCreateFileUpload from '@/apps/usecase-1/projects/project-create/ProjectCreateFileUpload.vue';
import DatePicker from '@/components/datePicker/DatePicker.vue';
import cookie from 'vue-cookies';
import { axios } from '@/utils/axiosHelper';
import { useSyncGeoMapSelection } from '@/composables/syncGeoMapSelection';

const projectStore = useProjectStore();
const router = useRouter();

const {
  setSelected,
  setUnSelected,
  selected,
  municipalitySelectItems,
  municipalities,
} = await useSyncGeoMapSelection();

const project = ref(new ProjectDto());
const isFormValid = ref(false);
const isCoatOfArmsValid = ref(false);

const projectMetaData = await restGetOrFetchMetaData(ProjectDto);
const projectFormRules = convertMetaDataToFormRules(projectMetaData);

async function save() {
  try {
    const res = await restPost(project.value, [
      'title',
      'base_year',
      'target_year',
      'municipalities',
    ]);
    if (project.value.thumbnail) {
      const formData = new FormData();
      formData.append('thumbnail', project.value.thumbnail);
      await axios({
        method: 'POST',
        url: `/api/usecase-1/project_thumbnail_create/${res.data.id}/`,
        data: formData,
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      });
    }
    router.push({ name: 'UseCase1ProjectList' });
  } catch (e) {
    console.error(e);
  }
}

watch(selected, (newVal) => {
  project.value.municipalities = newVal;
});
</script>

<style lang="scss" scoped>
.project-create-container {
  min-height: calc(100vh - 288px);
}

.geo-chart-custom-height {
  min-height: calc(100vh - 350px);
}
</style>
