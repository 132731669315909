<template>
  <div ref="searchContainer" class="relative">
    <InputEl
      v-model="searchText"
      :label
      :placeholder
      :search-suggestions
      :suffix-icon="true"
      suffix="search"
      suffix-color="text-neutral"
      :suffix-left="true"
      class="w-full"
      @update:model-value="highlightMatch($event)"
      @blur="setModelToFirstItem"
      @clicked:search-suggestion="emitSearchSuggestion"
    />
    <IconWrapper
      v-if="searchText"
      icon="close"
      fill="text-active-area"
      type="outlined"
      :size="22"
      class="absolute right-[10px] bottom-[-2px] translate-y-[-50%] cursor-pointer bg-core-color1 rounded-full"
      @click="resetAndFocus"
    />
  </div>
</template>

<script setup>
import InputEl from '../../components/input/InputEl.vue';
import IconWrapper from '../../components/IconWrapper/IconWrapper.vue';
import { computed, nextTick, ref, watch } from 'vue';
import FlexSearch from 'flexsearch';

const { Index } = FlexSearch;

const props = defineProps({
  placeholder: {
    type: String,
  },
  label: {
    type: String,
  },
  items: {
    type: Array,
    required: true,
  },
  itemSelected: {
    type: String,
  },
  searchKeys: {
    type: Array,
    required: true,
  },
  itemsValueKey: {
    type: String,
    default: 'id',
  },
});

const emit = defineEmits(['update:search', 'oneItemClicked']);

const searchText = ref('');
const itemsInner = ref([]);
const searchContainer = ref(null);
const index = ref(null);

watch(
  () => props.items,
  () => {
    async function initializeSearch() {
      itemsInner.value = props.items;

      // Recreate the search index with the new items
      index.value = new Index({ tokenize: 'forward' });

      itemsInner.value.forEach((item, id) => {
        const attributes = props.searchKeys
          .map((key) => String(item[key] || '').replace(/\s+/g, '')) // Ensure the value is a string and remove spaces
          .join(' ');
        index.value.add(id, attributes);
      });

      // Ensure the input model matches the selected item
      setModelByItemSelected();
    }

    initializeSearch();
  },
  { deep: true, immediate: true },
);

const searchResults = computed(() => {
  if (!searchText.value) {
    return itemsInner.value;
  }

  const searchTerm = searchText.value.toLowerCase().replace(/\s+/g, ''); // Remove spaces from search term
  const results = index.value.search(searchTerm);
  return results.map((id) => itemsInner.value[id]);
});

const searchSuggestions = computed(() => {
  return searchResults.value.map((item) =>
    props.searchKeys.map((key) => item[key]).join(' / '),
  );
});

function highlightMatch(word) {
  const divs = searchContainer.value.querySelectorAll('.search-item');
  divs.forEach((div) => {
    div.innerHTML = div.textContent;

    if (word) {
      const regex = new RegExp(`(${word})`, 'gi');

      div.innerHTML = div.innerHTML.replace(
        regex,
        `<span class="bg-active-area">$1</span>`,
      );
    }
  });
}

function resetAndFocus() {
  searchText.value = '';
  const input = searchContainer.value.querySelector('input');
  input.focus();
}

watch(searchText, () => {
  emit('update:search', searchResults.value);
});

// -------  Below logic for ensuring one item in input (optional) ---------

function setModelByItemSelected() {
  const { items, itemsValueKey, itemSelected, searchKeys } = props;

  const found = items.find((item) => item[itemsValueKey] === itemSelected);

  // Check if the found item is valid and has data for the search keys
  if (found) {
    searchText.value = searchKeys
      .map((key) => found[key])
      .filter((val) => val) // Ensure only truthy values are included
      .join(' / ');
  } else {
    searchText.value = ''; // Set to an empty string if no item is found
  }
}

async function emitSearchSuggestion() {
  await nextTick();
  emit('oneItemClicked', searchResults.value);
}

async function setModelToFirstItem() {
  if (props.itemSelected) {
    await nextTick();
    searchText.value = searchSuggestions.value[0];
    await nextTick();
    emit('oneItemClicked', searchResults.value);
  }
}

watch(
  () => props.itemSelected,
  () => {
    setModelByItemSelected();
  },
  { immediate: true },
);
</script>
