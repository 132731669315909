import {
  LAYER_KEY__GENERATION_PV_FREE,
  LAYER_KEY__GENERATION_BIOMASS,
  LAYER_KEY__GENERATION_WIND,
  LAYER_KEY__GENERATION_HYDROPOWER,
  LAYER_KEY__POTENTIAL_PV_FREE,
  LAYER_KEY__POTENTIAL_WIND,
} from '../layer-config/generation-potential';
import {
  LAYER_KEY__MUNICIPALITY,
  LAYER_KEY__COUNTY,
  LAYER_KEY__FEDERAL_STATE,
  LAYER_KEY__GOVERNMENT_DISTRICT,
} from '../layer-config/geographical-level';
import { LAYER_KEY__ESTATE } from '../layer-config/estates';

const municipalityAndHigher = [
  LAYER_KEY__MUNICIPALITY,
  LAYER_KEY__COUNTY,
  LAYER_KEY__FEDERAL_STATE,
  LAYER_KEY__GOVERNMENT_DISTRICT,
];

export const layerPanelConfig = {
  sections: [
    {
      title: 'Basisdaten',
      groups: [
        {
          title: 'Gebäude',
          items: [
            {
              title: 'Keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeBuildingToggle: true,
                includeAggregationToggle: {
                  partial: false,
                },
              },
              layerState: 'default',
            },
            {
              title: 'Sektor',
              itemClass: 'toggle',
              aggregation: {
                includeBuildingToggle: true,
                includeAggregationToggle: {
                  partial: false,
                },
              },
              layerState: 'sector',
            },
            {
              title: 'Baualtersklasse',
              itemClass: 'toggle',
              aggregation: {
                includeBuildingToggle: true,
                includeAggregationToggle: {
                  partial: false,
                },
              },
              layerState: 'constructionYearClass',
            },
          ],
        },
        {
          title: 'Flurstücke',
          items: [
            {
              title: 'Keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__ESTATE,
                },
              },
              layerState: 'default',
            },
          ],
        },
      ],
      show: true,
    },
    {
      title: 'Nachfrage',
      groups: [
        {
          title: 'Strom',
          items: [
            {
              title: 'Strombedarf',
              itemClass: 'toggle',
              aggregation: {
                includeBuildingToggle: true,
                includeAggregationToggle: {
                  partial: false,
                },
              },
              layerState: 'electricity_demand_total_kwh_a',
            },
            {
              title: 'Stromlast',
              itemClass: 'toggle',
              aggregation: {
                includeBuildingToggle: true,
                includeAggregationToggle: {
                  partial: false,
                },
              },
              layerState: 'electricity_demand_total_kw',
            },
          ],
        },
        {
          title: 'Wärme',
          items: [
            {
              title: 'Wärmebedarf',
              itemClass: 'toggle',
              aggregation: {
                includeBuildingToggle: true,
                includeAggregationToggle: {
                  partial: false,
                },
              },
              layerState: 'heat_demand_total_kwh_a',
            },
            {
              title: 'Wärmelast',
              itemClass: 'toggle',
              aggregation: {
                includeBuildingToggle: true,
                includeAggregationToggle: {
                  partial: false,
                },
              },
              layerState: 'heat_demand_total_kw',
            },
          ],
        },
      ],
      show: true,
    },
    {
      title: 'Erzeugung',
      groups: [
        {
          title: 'Bestandsanlagen',
          items: [
            {
              title: 'Wind',
              items: [
                {
                  title: 'Keine Einfärbung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GENERATION_WIND,
                    },
                  },
                  layerState: 'default',
                },
                {
                  title: 'Betriebsstatus',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GENERATION_WIND,
                    },
                  },
                  layerState: 'status',
                },
                {
                  title: 'Leistung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GENERATION_WIND,
                    },
                    includeAggregationToggle: {
                      partial: true,
                      onLayers: municipalityAndHigher,
                    },
                  },
                  layerState: 'generationWindKw',
                },
              ],
            },
            {
              title: 'Wasserkraft',
              items: [
                {
                  title: 'Keine Einfärbung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GENERATION_HYDROPOWER,
                    },
                  },
                  layerState: 'default',
                },
                {
                  title: 'Betriebsstatus',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GENERATION_HYDROPOWER,
                    },
                  },
                  layerState: 'status',
                },
                {
                  title: 'Leistung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GENERATION_HYDROPOWER,
                    },
                    includeAggregationToggle: {
                      partial: true,
                      onLayers: municipalityAndHigher,
                    },
                  },
                  layerState: 'generationHydropowerKw',
                },
              ],
            },
            {
              title: 'PV-Freifläche',
              items: [
                {
                  title: 'Keine Einfärbung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GENERATION_PV_FREE,
                    },
                  },
                  layerState: 'default',
                },
                {
                  title: 'Betriebsstatus',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GENERATION_PV_FREE,
                    },
                  },
                  layerState: 'status',
                },
                {
                  title: 'Leistung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GENERATION_PV_FREE,
                    },
                    includeAggregationToggle: {
                      partial: true,
                      onLayers: municipalityAndHigher,
                    },
                  },
                  layerState: 'generationPvFreeKw',
                },
              ],
            },
            {
              title: 'PV-Dach',
              items: [
                {
                  title: 'Leistung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeAggregationToggle: {
                      partial: true,
                      onLayers: municipalityAndHigher,
                    },
                  },
                  layerState: 'generationPvRoofKw',
                },
              ],
            },
            {
              title: 'Biomasse',
              items: [
                {
                  title: 'Keine Einfärbung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GENERATION_BIOMASS,
                    },
                  },
                  layerState: 'default',
                },
                {
                  title: 'Betriebsstatus',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GENERATION_BIOMASS,
                    },
                  },
                  layerState: 'status',
                },
                {
                  title: 'Leistung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GENERATION_BIOMASS,
                    },
                    includeAggregationToggle: {
                      partial: true,
                      onLayers: municipalityAndHigher,
                    },
                  },
                  layerState: 'generationBiomassKw',
                },
              ],
            },
          ],
        },
        {
          title: 'Potenziale',
          items: [
            {
              title: 'Wind',
              items: [
                {
                  title: 'Keine Einfärbung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__POTENTIAL_WIND,
                    },
                  },
                  layerState: 'default',
                },
                {
                  title: 'Flächeneignung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__POTENTIAL_WIND,
                    },
                  },
                  layerState: 'status',
                },
                {
                  title: 'Nennleistung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__POTENTIAL_WIND,
                    },
                    includeAggregationToggle: {
                      partial: true,
                      onLayers: municipalityAndHigher,
                    },
                  },
                  layerState: 'potentialWindKw',
                },
              ],
            },
            {
              title: 'PV-Freifläche',
              items: [
                {
                  title: 'Keine Einfärbung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__POTENTIAL_PV_FREE,
                    },
                  },
                  layerState: 'default',
                },
                {
                  title: 'Flächeneignung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__POTENTIAL_PV_FREE,
                    },
                  },
                  layerState: 'status',
                },
                {
                  title: 'Nennleistung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__POTENTIAL_PV_FREE,
                    },
                    includeAggregationToggle: {
                      partial: true,
                      onLayers: municipalityAndHigher,
                    },
                  },
                  layerState: 'potentialPvFreeKw',
                },
              ],
            },
            {
              title: 'PV-Dach',
              items: [
                {
                  title: 'Leistung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeAggregationToggle: {
                      partial: false,
                    },
                    includeBuildingToggle: true,
                  },
                  layerState: 'potentialPvRoofKw',
                },
              ],
            },
          ],
        },
      ],
      show: true,
    },
    {
      title: 'Infrastruktur',
      groups: [
        {
          title: 'Wärme ',
          items: [
            {
              title: 'Wärmeliniendichte',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: '',
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Strom ',
          items: [
            {
              title: 'Hochspannungsnetz',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: '',
                },
              },
              layerState: 'default',
            },
            {
              title: 'Suchraum Umspannwerk',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: '',
                },
              },
              layerState: 'default',
            },
          ],
        },
      ],
      show: true,
    },
  ],
};
