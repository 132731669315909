<template>
  <div class="relative min-h-full">
    <TheLabel :label="label" :for-input="id" class="text-color1 caption-1" />
    <textarea
      :id="id"
      v-model="model"
      :placeholder="placeholder"
      :class="[
        'placeholder:text-neutral placeholder:text-base body-2 text-neutral w-full p-2.5 outline-none bg-active-area min-h-12',
        customClasses,
      ]"
    />
    <span
      v-if="errorText !== null"
      class="absolute bottom-[-9px] right-0 text-xxs text-spot-error"
    >
      {{ errorText }}
    </span>
  </div>
</template>

<script setup>
import TheLabel from '../label/TheLabel.vue';
import { useValidation } from '@/composables/formElementValidation';
import { inject, onMounted, onUnmounted, ref, watch } from 'vue';

const props = defineProps({
  placeholder: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  id: {
    type: String,
    default: null,
  },
  customClasses: {
    type: String,
  },
  rules: {
    type: Object,
  },
});

const model = defineModel({ type: String, default: '' });

// ------- For validation rules --------

const { isValid, errorText, checkValidationRules, mapValidationRules } =
  useValidation();

const isMounted = ref(false);
const registerValidator = inject('registerValidator', null);
const unregisterValidator = inject('unregisterValidator', null);
const uniqueId = Math.random().toString(36).substring(2, 11);

onMounted(() => {
  isMounted.value = true;
});

onUnmounted(() => {
  if (unregisterValidator) {
    unregisterValidator(uniqueId);
  }
});

function validate(setErrorText) {
  checkValidationRules(
    mapValidationRules(props.rules),
    props.modelValue,
    setErrorText,
  );
  return isValid.value;
}

watch(
  () => props.modelValue,
  () => {
    if (registerValidator) {
      registerValidator(uniqueId, validate(isMounted.value));
    } else {
      validate(isMounted.value);
    }
  },
  { deep: true, immediate: true },
);
</script>

<style scoped lang="scss">
@import '@/assets/styles';

textarea {
  &:hover {
    border-bottom-width: 1px;
    margin-bottom: -1px;
    @apply border-b-line-active-color1;
  }

  &:focus {
    border-bottom-width: 2px;
    margin-bottom: -2px;
    @apply border-b-line-active-color1;
  }
}
</style>
