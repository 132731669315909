import { defineStore } from 'pinia';
import axios from 'axios';

export const useBaseStore = defineStore('baseStore', {
  state: () => ({
    municipalityGeoData: [],
    showScenarioSelector: true,
  }),
  actions: {
    async fetchSimplifiedMuniData() {
      if (this.municipalityGeoData.length) {
        return this.municipalityGeoData;
      }
      try {
        const data = await axios({
          method: 'GET',
          url: '/api/basemodule/simplified-municipality-data/',
        });
        this.municipalityGeoData = data.data.results;
        return this.municipalityGeoData;
      } catch (error) {
        console.error(error);
      }
    },
    setShowScenarioSelector(payload) {
      this.showScenarioSelector = payload;
    },
  },
  getters: {
    getShowScenarioSelector: (state) => {
      return state.showScenarioSelector;
    },
  },
});
