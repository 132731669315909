import { defineStore } from 'pinia';
import { axios } from '@/utils/axiosHelper';
import cookie from 'vue-cookies';

export const useUsers = defineStore('users', {
  state: () => ({
    profile: null,
    hasCheckedClientHash: false, // Add a flag to track if the check has been performed
  }),
  actions: {
    async patchProfile(accountForm) {
      const profileData = await axios({
        method: 'POST',
        url: '/api/profile/',
        data: accountForm,
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      });
      this.profile = profileData.data.user;
    },
    async getProfile() {
      try {
        const profileData = await axios({
          method: 'GET',
          url: '/api/profile/',
        });
        this.profile = profileData.data.user;
      } catch (error) {
        this.profile = null;
        return Promise.reject(error);
      }
    },
    async patchPassword(passwordOld, passwordNew) {
      await axios({
        url: '/api/profile/password/',
        data: { passwordOld, passwordNew },
        method: 'PATCH',
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      });
    },
    async getClientHash() {
      // Return early if the client hash has already been checked
      if (this.hasCheckedClientHash) {
        return;
      }

      try {
        const resp = await axios({ url: '/build-info.json', method: 'GET' });
        if (resp.data['build-id'] !== import.meta.env.VITE_BUILD_ID) {
          // Client update: forcing browser refresh
          window.location.reload();
        }
      } finally {
        // Mark the check as done
        this.hasCheckedClientHash = true;
      }
    },
  },
});
