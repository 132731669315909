<template>
  <div class="flex flex-col gap-2.5">
    <div class="grid grid-flow-col auto-cols-fr gap-2.5">
      <div
        v-for="i in headerKpis"
        :key="i"
        class="flex flex-col gap-[5px] justify-center items-center py-2 px-1 standard-elevation-0-dark"
      >
        <h6 class="text-[#322A26] text-center">{{ i.label }}</h6>
        <h6 class="text-title-neutral text-center">
          {{
            i.value.toLocaleString('de-DE', {
              maximumFractionDigits: i.decimals,
            }) +
            ' ' +
            i.unit
          }}
        </h6>
      </div>
    </div>
    <div class="relative rounded-[8px] standard-elevation-0-dark">
      <div class="w-full h-[60px] bg-default"></div>
      <!-- Todo: Add real dropdown logic-->
      <DropDown
        v-model="waterfallChartUnit"
        size="m"
        class="w-[126px] absolute left-2.5 top-5 z-[100000]"
        :items-data="[
          {
            label: 'Leistung (MW)',
            value: 'MW',
          },
          {
            label: 'Arbeit (MWh)',
            value: 'MWh',
          },
        ]"
        items-data-key="label"
        label="Einheit"
      />
      <p
        class="caption-1 text-title-color1 absolute left-5 bottom-[76px] z-[100000] w-20"
      >
        Nach Gleichzeitigkeit
      </p>
      <h4 class="chart-section-title left-[16%]">Nachfrage</h4>
      <div class="vertical-chart-separator left-[56.8%]"></div>
      <h4 class="chart-section-title left-[59%]">Erzeugung</h4>
      <div class="vertical-chart-separator right-[13.65%]"></div>
      <h4 class="chart-section-title right-[11.5%]">Delta</h4>
      <div class="horizontal-chart-separator"></div>
      <WaterfallChart
        :data="dataOneChart"
        :labels="labelsOneChart"
        :legend="legend"
        grid-left="47px"
        :y-axis-label-custom-fixed-width="80"
        grid-bottom="9%"
        :unit="waterfallChartUnit"
        class="min-h-[540px] shadow-none"
        :show-y-axis-line="true"
        :show-tooltip="false"
      />
    </div>
  </div>
  <ExpansionPanel
    :show-circle-number="false"
    :panels="['Parametrierung Nachfrage', 'Parametrierung EE-Erzeugung']"
    :multiple="true"
    :eager="true"
    :first-panel-open="false"
  >
    <template #panel1>
      <div class="flex flex-col gap-5">
        <div class="grid grid-cols-2 gap-5 pl-5">
          <span class="subtitle-2">Skalierungsfaktoren in %</span>
          <div class="flex justify-between">
            <span class="-ml-8">-100%</span>
            <span class="-ml-1">0</span>
            <span class="mr-1">+100%</span>
          </div>
        </div>
        <div
          v-for="(slider, index) in sliders"
          :key="index"
          class="grid grid-cols-2 standard-elevation-0-dark p-5 items-center justify-between"
        >
          <span class="subtitle-2">{{ slider.label }}</span>
          <SliderParameterization
            v-model="slider.model"
            :color="slider.color"
            :step="0.2"
          />
        </div>

        <div class="flex justify-between mt-10 mb-2">
          <AssumptionsScalingFactorsDemandLoad
            :scenario-id="props.scenarioId"
            @apply-values="applyScalingValues($event)"
          />
          <AssumptionsCreate
            title-save-dialog="Parametrierung zur Nachfrage speichern"
            :data="{
              electrification_mobility:
                resultStore.scalingElectrificationMobility,
              electrification_heat_single:
                resultStore.scalingElectrificationHeatSingle,
              electrification_heat_network:
                resultStore.scalingElectrificationHeatNetwork,
              power_efficiency: resultStore.scalingPowerEfficiency,
            }"
            :url="'/api/usecase-1/assumptions-scaling-factors-demand/'"
            :scenario-id="props.scenarioId"
            :format-data-for-emit="formatDemand"
            @apply-values="applyScalingValues($event)"
          />
        </div>
      </div>
    </template>
    <template #panel2>
      <div class="flex gap-8">
        <div
          class="flex flex-col items-center justify-end px-2.5 py-5 max-w-[175px]"
        >
          <div class="flex flex-col gap-2.5 text-title-neutral">
            <SwitchItem
              v-model="toggleFieldsYearly"
              label="Jährlicher Zubau in MW/a"
              @update:model-value="toggleFieldsAbsolute = !toggleFieldsYearly"
            />
            <SwitchItem
              v-model="toggleFieldsAbsolute"
              :label="`Absoluter Zubau bis ${resultStore.activeProject.target_year} in MW`"
              @update:model-value="toggleFieldsYearly = !toggleFieldsAbsolute"
            />
          </div>
        </div>

        <div class="grid grid-flow-col auto-cols-fr h-[500px] gap-8">
          <div
            v-for="(sliderItem, index) in Object.values(verticalSliders)"
            :key="index"
            class="flex flex-col items-center justify-between gap-5 px-2.5 py-5"
          >
            <div class="flex gap-2.5 h-full w-full justify-between">
              <SliderParameterization
                :model-value="sliderItem.potentialUtilization"
                :color="sliderItem.color"
                :vertical="true"
                :enabled-min="0"
                :enabled-max="1"
                @update:model-value="
                  sliderItem.modelAbsolute = $event * sliderItem.potential
                "
              />
              <div class="flex flex-col justify-between items-start w-fit">
                <div class="flex flex-col">
                  <span class="subtitle-2 whitespace-break-spaces">{{
                    sliderItem.sliderTopLabel
                  }}</span>
                  <h4 class="text-title-neutral">
                    <span
                      :class="
                        sliderItem.potentialUtilization <= 1
                          ? 'text-green-500'
                          : 'text-red-500'
                      "
                      >{{
                        (
                          sliderItem.sliderTopValue *
                          sliderItem.potentialUtilization
                        ).toLocaleString('de-DE', {
                          maximumFractionDigits: 0,
                        })
                      }}
                    </span>
                    <br />
                    von
                    <br />
                    {{
                      sliderItem.sliderTopValue.toLocaleString('de-DE', {
                        maximumFractionDigits: 0,
                      })
                    }}
                  </h4>
                </div>
                <div class="flex flex-col">
                  <span class="subtitle-3 whitespace-break-spaces">{{
                    'Potenzial-\nausschöpfung'
                  }}</span>
                  <h4 class="text-title-neutral">
                    <span
                      :class="
                        sliderItem.potentialUtilization <= 1
                          ? 'text-green-500'
                          : 'text-red-500'
                      "
                      >{{
                        (sliderItem.potentialUtilization * 100).toLocaleString(
                          'de-DE',
                          { maximumFractionDigits: 1 },
                        ) + '%'
                      }}</span
                    >
                  </h4>
                </div>
              </div>
            </div>
            <div class="flex flex-col gap-2.5">
              <h4 class="text-[#322A26]">{{ sliderItem.paramsLabel }}</h4>
              <InputEl
                :model-value="sliderItem.modelAbsolute / countYears"
                :disabled="!toggleFieldsYearly"
                input-type="float"
                suffix="MW/a"
                @update:model-value="
                  sliderItem.modelAbsolute = $event * countYears
                "
              />
              <InputEl
                v-model="sliderItem.modelAbsolute"
                :disabled="!toggleFieldsAbsolute"
                input-type="float"
                suffix="MW"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-between mt-10 mb-2">
        <AssumptionsTotalIncreaseGenerationLoad
          :scenario-id="props.scenarioId"
          :potentials="{
            potentialPvRoof: resultStore.potentialPvRoofMw,
            potentialPvFree: resultStore.potentialPvFreeMw,
            potentialWind: resultStore.potentialWindMw,
          }"
          :scenario-count-years="countYears"
          @apply-values="applyTotalGenerationValues($event)"
        />
        <AssumptionsCreate
          title-save-dialog="Parametrierung zur Erzeugung speichern"
          :data="{
            pv_roof_mw: resultStore.totalIncreasePvRoofMw,
            pv_free_mw: resultStore.totalIncreasePvFreeMw,
            wind_mw: resultStore.totalIncreaseWindMw,
          }"
          :potentials="{
            potentialPvRoof: resultStore.potentialPvRoofMinusGenerationMw,
            potentialPvFree: resultStore.potentialPvFreeMinusGenerationMw,
            potentialWind: resultStore.potentialWindMinusGenerationMw,
          }"
          :scenario-count-years="countYears"
          :url="'/api/usecase-1/assumptions-total-increase-generation/'"
          :scenario-id="props.scenarioId"
          :format-data-for-emit="formatGeneration"
          @apply-values="applyTotalGenerationValues($event)"
        />
      </div>
    </template>
  </ExpansionPanel>
  <SourcesItem item="power-balance" />
</template>

<script setup>
import WaterfallChart from '@/components/charts/WaterfallChart/WaterfallChart.vue';
import { seriesChart } from './power-balance-waterfall-data';
import { computed, ref, watch } from 'vue';
import InputEl from '@/components/input/InputEl.vue';
import AssumptionsScalingFactorsDemandLoad from '@/apps/usecase-1/assumptions-project-result/assumptions-scaling-factors-demand/AssumptionsScalingFactorsDemandLoad.vue';
import AssumptionsCreate from '@/apps/usecase-1/assumptions-project-result/AssumptionsCreate.vue';
import AssumptionsTotalIncreaseGenerationLoad from '@/apps/usecase-1/assumptions-project-result/assumptions-total-increase-generation/AssumptionsTotalIncreaseGenerationLoad.vue';
import SliderParameterization from '@/apps/usecase-1/projects/project-result-detail/components/SliderParameterization.vue';
import SwitchItem from '@/components/SwitchItem/SwitchItem.vue';
import SourcesItem from '@/components/sourcesItem/SourcesItem.vue';
import ExpansionPanel from '@/components/ExpansionPanel/ExpansionPanel.vue';
import { useProjectResult } from './project-result-store';
import DropDown from '../../../../components/DropDown/DropDown.vue';
import {
  POWER_DEMAND,
  RENEWABLE_GENERATION,
} from '../../../../configs/color-constants';

const resultStore = useProjectResult();

const props = defineProps({
  projectId: {
    type: String,
    required: true,
  },
  scenarioId: {
    type: String,
    required: true,
  },
});

const toggleFieldsYearly = ref(false);
const toggleFieldsAbsolute = ref(true);
const waterfallChartUnit = ref('MWh');

await resultStore.fetchDemandData(props.projectId, props.scenarioId);
await resultStore.fetchPotentialGenerationData(
  props.projectId,
  props.scenarioId,
);
await resultStore.applyDefaultGenerationScaling(props.scenarioId);
// set scenario in store after data is fetched
resultStore.setActiveScenarioById(props.scenarioId);

// power generation
const baseYear = computed(() => resultStore.activeProject.base_year);
const targetYear = computed(() => resultStore.activeProject.target_year);
const countYears = computed(() => targetYear.value - baseYear.value);

function applyScalingValues(scalingValues) {
  resultStore.setAppliedDemandScalingId(scalingValues.id);
  resultStore.applyScalingValues(scalingValues);
}

function applyTotalGenerationValues(scalingValues) {
  resultStore.setAppliedGenerationScalingId(scalingValues.id);
  resultStore.setTotalIncreasePvRoofMw(scalingValues.totalIncreasePvRoof, true);
  resultStore.setTotalIncreasePvFreeMw(scalingValues.totalIncreasePvFree, true);
  resultStore.setTotalIncreaseWindMw(scalingValues.totalIncreaseWind, true);
}

// Chart
const labelsOneChart = [
  'Status Quo',
  'Elektri-\nfizierung\nMobilität',
  'Elektri-\nfizierung\nWärme\nEinzeilvers.',
  'Elektri-\nfizierung\nWärmenetz',
  'Effizienz-\nsteigerung\nStrom',
  `Nachfrage ${resultStore.activeProject.target_year}`,
  '',
  'Bestand',
  'PV-Aufdach,\nPV-Freifläche,\nWind',
  `Gesamt-\nerzeugung\n${resultStore.activeProject.target_year}`,
  '',
  'Gesamt',
];

const legend = {
  data: ['PV-Aufdach', 'PV-Freifläche', 'Wind', 'Wasser', 'Biomasse'],
  bottom: 2,
  right: '13%',
};

const dataOneChart = computed(() => {
  if (waterfallChartUnit.value === 'MWh') {
    return seriesChart(
      resultStore.generationTotalIncreaseMwh,
      resultStore.scaledDemandTotalMwh,
      resultStore.demandStatusQuoMwh,
      resultStore.scaledDemandMobilityMwh,
      resultStore.scaledDemandHeatSingleMwh,
      resultStore.scaledDemandHeatNetworkMwh,
      resultStore.scaledDemandEfficiencyMwh,
      resultStore.generationPvRoofMwh,
      resultStore.generationPvFreeMwh,
      resultStore.generationWindMwh,
      resultStore.generationWaterMwh,
      resultStore.generationBiomassMwh,
      resultStore.generationTotalMwh,
      resultStore.totalIncreasePvRoofMwh,
      resultStore.totalIncreasePvFreeMwh,
      resultStore.totalIncreaseWindMwh,
    );
  }
  return seriesChart(
    resultStore.generationTotalIncreaseMw,
    resultStore.scaledDemandTotalMw,
    resultStore.demandStatusQuoMw,
    resultStore.scaledDemandMobilityMw,
    resultStore.scaledDemandHeatSingleMw,
    resultStore.scaledDemandHeatNetworkMw,
    resultStore.scaledDemandEfficiencyMw,
    resultStore.generationPvRoofMw,
    resultStore.generationPvFreeMw,
    resultStore.generationWindMw,
    resultStore.generationWaterMw,
    resultStore.generationBiomassMw,
    resultStore.generationTotalMw,
    resultStore.totalIncreasePvRoofMw,
    resultStore.totalIncreasePvFreeMw,
    resultStore.totalIncreaseWindMw,
  );
});

// Tab 1: horizontal slider config
const sliders = ref([
  {
    label: 'Elektrifizierung Mobilität',
    model: computed({
      get() {
        return resultStore.scalingElectrificationMobility;
      },
      set(value) {
        resultStore.setScalingElectrificationMobility(value);
      },
    }),
    color: POWER_DEMAND.ELECTRIFICATION_MOBILITY,
  },
  {
    label: 'Elektrifizierung Wärme Einzelversorgung',
    model: computed({
      get() {
        return resultStore.scalingElectrificationHeatSingle;
      },
      set(value) {
        resultStore.setScalingElectrificationHeatSingle(value);
      },
    }),
    color: POWER_DEMAND.ELECTRIFICATION_HEAT_SINGLE,
  },
  {
    label: 'Elektrifizierung Wärmenetze',
    model: computed({
      get() {
        return resultStore.scalingElectrificationHeatNetwork;
      },
      set(value) {
        resultStore.setScalingElectrificationHeatNetwork(value);
      },
    }),
    color: POWER_DEMAND.ELECTRIFICATION_HEAT_NETWORK,
  },
  {
    label: 'Effizienzsteigerung Strom',
    model: computed({
      get() {
        return resultStore.scalingPowerEfficiency;
      },
      set(value) {
        resultStore.setScalingPowerEfficiency(value);
      },
    }),
    color: POWER_DEMAND.EFFICIENCY,
  },
]);

// Tab 2: vertical slider config
const verticalSliders = ref({
  pvRoof: {
    // 8 kw = EFH
    sliderTopLabel: `Anzahl\nEFH-Äquivalent`,
    sliderTopValue: Math.round(
      resultStore.potentialPvRoofMinusGenerationMw / 0.008,
    ),
    potentialUtilization: computed(() => {
      if (resultStore.potentialPvRoofMinusGenerationMw === 0) return 0;
      return (
        resultStore.totalIncreasePvRoofMw /
        resultStore.potentialPvRoofMinusGenerationMw
      );
    }),
    potential: resultStore.potentialPvRoofMinusGenerationMw,
    paramsLabel: 'PV-Aufdach',
    modelSlider: null,
    modelAbsolute: computed({
      get() {
        return resultStore.totalIncreasePvRoofMw;
      },
      set(value) {
        resultStore.setTotalIncreasePvRoofMw(value);
      },
    }),
    color: RENEWABLE_GENERATION.PV_ROOF,
  },

  pvFree: {
    // 1,2 ha sind 1 MW
    sliderTopLabel: 'Fläche [ha]\n\n',
    sliderTopValue: resultStore.potentialPvFreeMinusGenerationMw * 1.2,
    potentialUtilization: computed(() => {
      if (resultStore.potentialPvFreeMinusGenerationMw === 0) return 0;
      return (
        resultStore.totalIncreasePvFreeMw /
        resultStore.potentialPvFreeMinusGenerationMw
      );
    }),
    potential: resultStore.potentialPvFreeMinusGenerationMw,
    paramsLabel: 'PV-Freifläche',
    modelSlider: null,
    modelAbsolute: computed({
      get() {
        return resultStore.totalIncreasePvFreeMw;
      },
      set(value) {
        resultStore.setTotalIncreasePvFreeMw(value);
      },
    }),
    color: RENEWABLE_GENERATION.PV_FREE,
  },
  wind: {
    sliderTopLabel: 'Anzahl\nAnlagen',
    // 6 MW eine Anlage
    sliderTopValue: Math.round(resultStore.potentialWindMinusGenerationMw / 6),
    potentialUtilization: computed(() => {
      if (resultStore.potentialWindMinusGenerationMw === 0) return 0;
      return (
        resultStore.totalIncreaseWindMw /
        resultStore.potentialWindMinusGenerationMw
      );
    }),
    potential: resultStore.potentialWindMinusGenerationMw,
    paramsLabel: 'Windenergie',
    modelSlider: null,
    modelAbsolute: computed({
      get() {
        return resultStore.totalIncreaseWindMw;
      },
      set(value) {
        resultStore.setTotalIncreaseWindMw(value);
      },
    }),
    color: RENEWABLE_GENERATION.WIND,
  },
});

const headerKpis = computed(() => {
  return [
    {
      label: 'Stromnachfrage',
      value: resultStore.scaledDemandTotalMwh,
      unit: 'MWh(el)/a',
      decimals: 0,
    },
    {
      label: 'EE-Erzeugung',
      value: resultStore.generationTotalIncreaseMwh,
      unit: 'MWh(el)/a',
      decimals: 0,
    },
    {
      label: 'Investitionsbedarf',
      value: resultStore.investRenewableMioEur,
      unit: 'Mio. €',
      decimals: 2,
    },
    {
      label: 'Bilanzieller Autarkiegrad',
      value:
        resultStore.generationTotalIncreaseMwh >
        resultStore.scaledDemandTotalMwh
          ? 100
          : (resultStore.generationTotalIncreaseMwh /
              resultStore.scaledDemandTotalMwh) *
            100,
      unit: '%',
      decimals: 0,
    },
    {
      label: 'EEG-Gemeindeabgabe',
      value: resultStore.municipalityLevyMioEur,
      unit: 'Mio. €/a',
      decimals: 2,
    },
    {
      label: 'Wasserstoffpotenzial',
      value: resultStore.potentialHydrogenMwh,
      unit: 'MWh(th)/a',
      decimals: 0,
    },
    {
      label: 'Notwendige Elektrolyseurkapazität',
      value: resultStore.potentialHydrogenMw,
      unit: 'MW(el)',
      decimals: 2,
    },
  ];
});
//------------ scaling helpers
function formatGeneration(values) {
  return {
    totalIncreasePvRoof: values.pv_roof_mw,
    totalIncreasePvFree: values.pv_free_mw,
    totalIncreaseWind: values.wind_mw,
  };
}
function formatDemand(values) {
  return {
    scalingElectrificationMobility: values.electrification_mobility,
    scalingElectrificationHeatSingle: values.electrification_heat_single,
    scalingElectrificationHeatNetwork: values.electrification_heat_network,
    scalingPowerEfficiency: values.power_efficiency,
  };
}
</script>
<style scoped lang="scss">
.separator-default-styles {
  @apply bg-default absolute z-[2];
}

.vertical-chart-separator {
  @apply separator-default-styles w-2.5 h-[564px] top-0;

  box-shadow:
    inset 8px 0 8px -8px rgba(126, 117, 112, 0.2),
    inset -8px 0 8px -8px rgba(126, 117, 112, 0.2);
}

.horizontal-chart-separator {
  @apply separator-default-styles h-2 right-0 left-0 bottom-7;

  box-shadow:
    inset 0 8px 8px -8px rgba(126, 117, 112, 0.2),
    inset 0 -8px 8px -8px rgba(126, 117, 112, 0.2);
}

.chart-section-title {
  @apply text-neutral w-2 absolute top-5 z-[10000];
}
</style>
