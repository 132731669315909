export class PotentialAnalysisDto {
  // Todo: Adjust naming to match backend naming
  static BASE_URL = '';

  id = null;
  title = '';
  description = '';
  municipalities = null;
  data_basis = 'administrative';
  min_area = null;
  max_estates_on_area = null;
  medium_voltage = false;
  high_voltage = false;
  distance = null;
  fully_disadvantaged_area = false;
  partial_disadvantaged_area = false;
  farmland = false;
  greenland = false;
  slope_gradient_from = null;
  slope_gradient_to = null;
  surface_gradient_from = null;
  surface_gradient_to = null;
}
