<template>
  <ExpansionPanel
    :panels="[
      'Bestandsanalyse',
      'Nachfrageentwicklung Strom',
      'Nachfrageentwicklung Wärme',
    ]"
  >
    <template #panel1>
      <div class="flex flex-col gap-2.5">
        <div class="projects-grid-wrapper max-h-[300px]">
          <BasicPieChart
            :chart-data="electricityDemandBySectorGWh"
            :title="`Stromverbrauch nach Sektoren`"
            chart-unit="GWh(el)/a"
            title-inside="GWh(el)/a"
            :show-chart-unit-inside-title="false"
            class="projects-grid-item"
            :custom-radius="['50%', '80%']"
            custom-title-classes="mb-3"
            :series-position="['33%', '50%']"
            legend-right="10%"
            :show-label="false"
          />
          <BasicPieChart
            :chart-data="generation"
            :title="`EE-Erzeugung`"
            :custom-radius="['50%', '80%']"
            custom-title-classes="mb-3"
            :series-position="['33%', '50%']"
            chart-unit="GWh(el)/a"
            title-inside="GWh(el)/a"
            :show-chart-unit-inside-title="false"
            class="projects-grid-item"
            legend-right="15%"
            :show-label="false"
          />
          <WaterfallChart
            class="min-h-[300px]"
            :data="dataPowerBalance"
            :labels="labelsPowerBalance"
            title="Mengenbilanz"
            grid-top="70px"
            grid-left="35px"
            grid-right="25px"
            title-top="16px"
            unit="GWh(el)/a"
            y-axis-name="GWh(el)/a"
            :show-y-axis-line="true"
          />
        </div>
        <div class="projects-grid-wrapper max-h-[320px]">
          <div class="projects-grid-item">
            <div
              class="flex flex-col items-center rounded-[4px] justify-center gap-4 p-5 bg-dark text-white w-full h-full"
            >
              <div class="flex items-center gap-3">
                <VProgressCircular
                  v-model="shareRenewables"
                  size="54"
                  width="8"
                  bg-color="#1867C0"
                  color="white"
                />
                <h1>{{ shareRenewables + '%' }}</h1>
              </div>
              <span class="body-2 text-center mt-1">{{
                `des Stromverbrauchs wird heute lokal durch Erneuerbare Energien
            erzeugt (bilanzieller Autarkiegrad).`
              }}</span>
            </div>
          </div>
          <BasicPieChart
            :chart-data="heatDemandBySectorGWh"
            :title="`Wärmeverbrauch nach Sektoren`"
            :chart-unit="'GWh(th)/a'"
            title-inside="GWh(th)/a"
            :show-chart-unit-inside-title="false"
            custom-title-classes="mb-3"
            :custom-radius="['47%', '75%']"
            :series-position="['34%', '50%']"
            legend-right="7.5%"
            class="h-[320px] projects-grid-item"
            :show-label="false"
          />

          <BasicPieChart
            :chart-data="heatDemandByTech"
            :title="`Wärmeverbrauch nach Energieträgern`"
            :chart-unit="'GWh(th)/a'"
            title-inside="GWh(th)/a"
            :show-chart-unit-inside-title="false"
            custom-title-classes="mb-3"
            :custom-radius="['47%', '75%']"
            :series-position="['32%', '50%']"
            legend-right="5%"
            class="h-[320px] projects-grid-item"
            :show-label="false"
          />
        </div>
      </div>
    </template>
    <template #panel2>
      <div class="flex flex-col gap-10">
        <div class="second-panel-card">
          <BarChartBasic
            :stacks="assumedFutureDemandByDrivers"
            :title="'Stromverbrauch nach Verbrauchstreibern'"
            :x-axis-data="years"
            :y-axis-name="'MWh(el)/a'"
            chart-unit="MWh(el)/a"
            class="min-h-[500px]"
          />
          <ArrowNumberCard v-model="arrowElectricityDemand" unit="%" />
        </div>
        <div class="second-panel-card">
          <BarChartBasic
            :stacks="assumedElectricityDemandBySector"
            :title="'Stromverbrauch nach Sektoren'"
            :x-axis-data="years"
            :y-axis-name="'MWh(el)/a'"
            chart-unit="MWh(el)/a"
            class="min-h-[500px]"
          />
          <ArrowNumberCard v-model="arrowElectricityDemand" unit="%" />
        </div>
      </div>
    </template>
    <template #panel3>
      <div class="flex flex-col gap-10">
        <div class="second-panel-card">
          <BarChartBasic
            :stacks="assumedHeatDemandBySector"
            :title="'Wärmeverbrauch nach Sektoren'"
            :x-axis-data="years"
            :y-axis-name="'MWh(th)/a'"
            chart-unit="MWh(th)/a"
            class="min-h-[500px]"
          />
          <ArrowNumberCard v-model="arrowHeatDemand" unit="%" />
        </div>
      </div>
    </template>
  </ExpansionPanel>

  <SourcesItem item="demand-generation" />
</template>

<script setup>
// import axios from "@/utils/axiosHelper";
import BasicPieChart from '@/components/charts/basic-pie-chart/BasicPieChart.vue';
import WaterfallChart from '@/components/charts/WaterfallChart/WaterfallChart.vue';
import BarChartBasic from '@/components/charts/BarChartBasic.vue';
import {
  BALANCE,
  HEATING_TYPE_BY_ENUM,
  POWER_DEMAND,
  RENEWABLE_GENERATION,
  SECTOR_BY_ENUM,
} from '@/configs/color-constants';
import { computed, ref } from 'vue';
import ArrowNumberCard from '@/apps/usecase-1/projects/project-result-detail/components/ArrowNumberCard.vue';
import { useProjectResult } from './project-result-store';
import SourcesItem from '@/components/sourcesItem/SourcesItem.vue';
import ExpansionPanel from '@/components/ExpansionPanel/ExpansionPanel.vue';
import {
  groupByDenormalizeToStackedBars,
  groupByFieldAndYear,
  groupByToStackedBars,
} from '@/utils/chart-utils';

const resultStore = useProjectResult();

const props = defineProps({
  projectId: {
    type: String,
    required: true,
  },
  scenarioId: {
    type: String,
    required: true,
  },
});

const electricityFields = ref([
  'electricity_demand_base_kwh_a',
  'electricity_demand_heat_network_kwh_a',
  'electricity_demand_heat_pump_kwh_a',
  'electricity_demand_bev_kwh_a',
]);
const labelsPowerBalance = [
  'Stromverbrauch',
  'EE-Erzeugung',
  'Konventionell\nbenötigte\nErzeugung',
];

// fetch data
await resultStore.fetchDemandData(props.projectId, props.scenarioId);
await resultStore.fetchPotentialGenerationData(
  props.projectId,
  props.scenarioId,
);
await resultStore.fetchHeatDemandByTechnologyData(
  props.projectId,
  props.scenarioId,
);
// set scenario in store after data is fetched
resultStore.setActiveScenarioById(props.scenarioId);

const demandData = computed(() => resultStore.demandData[props.scenarioId]);

// 1. status quo data
const baseYear = computed(() => {
  return Math.min(...demandData.value.map((e) => e.year));
});
// heat demand by sector pie chart
const heatDemandBySectorGWh = computed(() => {
  return processBaseYearSectorData('heat_demand_kwh_a', baseYear.value);
});
// electricity demand by sector pie chart
const electricityDemandBySectorGWh = computed(() => {
  return processBaseYearSectorData(
    'electricity_demand_base_kwh_a',
    baseYear.value,
  );
});
// total electricity demand in base year
const electricityTotalBaseYearGWh = computed(() => {
  return (
    resultStore.demandFieldByYears(
      props.scenarioId,
      'electricity_demand_base_kwh_a',
    )[baseYear.value] / 1000000
  );
});
const generation = computed(() => {
  const generationByTechnologyKWh = resultStore.getGenerationByTechnologyKwh;
  return [
    {
      name: 'Wind',
      color: RENEWABLE_GENERATION.WIND,
      value: generationByTechnologyKWh.wind / 1000000,
    },
    {
      name: 'PV Aufdach',
      color: RENEWABLE_GENERATION.PV_ROOF,
      value: generationByTechnologyKWh.pvRoof / 1000000,
    },
    {
      name: 'PV Freifläche',
      color: RENEWABLE_GENERATION.PV_FREE,
      value: generationByTechnologyKWh.pvFree / 1000000,
    },
    {
      name: 'Wasserkraft',
      color: RENEWABLE_GENERATION.HYDRO,
      value: generationByTechnologyKWh.hydroPower / 1000000,
    },
    {
      name: 'Biomasse',
      color: RENEWABLE_GENERATION.BIOMASS,
      value: generationByTechnologyKWh.biomass / 1000000,
    },
  ];
});
const dataPowerBalance = computed(() => {
  const generationTotalGWh = resultStore.getGenerationTotalKwh / 1000000;
  return {
    visibleStacks: [
      {
        value: electricityTotalBaseYearGWh.value,
        itemStyle: { color: BALANCE.POWER_DEMAND },
      },
      {
        value: generationTotalGWh,
        itemStyle: { color: BALANCE.RENEWABLE_GENERATION },
      },
      {
        value: Math.max(
          electricityTotalBaseYearGWh.value - generationTotalGWh,
          0,
        ),
        itemStyle: { color: BALANCE.CONVENTIONAL_GENERATION },
      },
    ],
    hiddenStacks: [
      0,
      Math.max(electricityTotalBaseYearGWh.value - generationTotalGWh, 0),
      0,
    ],
  };
});
const shareRenewables = computed(() => {
  const generationTotalGWh = resultStore.getGenerationTotalKwh / 1000000;
  let share = (generationTotalGWh / electricityTotalBaseYearGWh.value) * 100;
  if (share > 100) return 100;
  return share.toLocaleString('de-DE', { maximumFractionDigits: 2 });
});
const years = computed(() => {
  return Array.from(new Set(demandData.value.map((e) => e.year))).sort();
});
const assumedHeatDemandBySector = computed(() => {
  return processYearlySectorData(['heat_demand_kwh_a']);
});
const assumedElectricityDemandBySector = computed(() => {
  return processYearlySectorData(electricityFields.value, true);
});
const arrowElectricityDemand = computed(() => {
  return getTotalIncreasePercent(assumedElectricityDemandBySector.value);
});
const arrowHeatDemand = computed(() => {
  return getTotalIncreasePercent(assumedHeatDemandBySector.value);
});
// 2.2 process demand driver categories
const assumedFutureDemandByDrivers = computed(() => {
  return processYearlyDemandDrivers(demandData.value);
});
// 2.3 process heat demand by tech
const heatDemandByTech = computed(() => {
  const chartData = [];
  for (const tech of resultStore.getHeatDemandByTechnology) {
    chartData.push({
      name: HEATING_TYPE_BY_ENUM[tech.heating_type].label,
      color: HEATING_TYPE_BY_ENUM[tech.heating_type].color,
      value: tech.heat_demand_kwh_a / 1000000,
    });
  }
  return chartData;
});

function processBaseYearSectorData(valueFieldName, year) {
  const data = groupByFieldAndYear(
    demandData.value,
    'sector',
    valueFieldName,
    year,
  );
  return Object.keys(data).map((e) => ({
    name: SECTOR_BY_ENUM[e].label,
    color: SECTOR_BY_ENUM[e].color,
    value: data[e] / 1000000,
  }));
}

function processYearlySectorData(valueFieldNames, withMobility = false) {
  const data = groupByToStackedBars(
    demandData.value,
    'sector',
    valueFieldNames,
    withMobility,
  );
  return Object.keys(data).map((e) => ({
    name: SECTOR_BY_ENUM[e].label,
    itemStyle: { color: SECTOR_BY_ENUM[e].color },
    data: Object.values(data[e]).map((e) => e / 1000),
  }));
}

function processYearlyDemandDrivers(dashboardData) {
  const data = groupByDenormalizeToStackedBars(
    dashboardData,
    electricityFields.value,
  );
  return [
    {
      name: 'Konventionell',
      itemStyle: { color: POWER_DEMAND.CONVENTIONAL },
      data: Object.values(data.electricity_demand_base_kwh_a).map(
        (e) => e / 1000,
      ),
    },
    {
      name: 'Elektrifizierung E-Mobilität',
      itemStyle: { color: POWER_DEMAND.ELECTRIFICATION_MOBILITY },
      data: Object.values(data.electricity_demand_bev_kwh_a).map(
        (e) => e / 1000,
      ),
    },
    {
      name: 'Elektrifizierung Wärme Einzelversorgung',
      itemStyle: { color: POWER_DEMAND.ELECTRIFICATION_HEAT_SINGLE },
      data: Object.values(data.electricity_demand_heat_network_kwh_a).map(
        (e) => e / 1000,
      ),
    },
    {
      name: 'Elektrifizierung Wärmenetze',
      itemStyle: { color: POWER_DEMAND.ELECTRIFICATION_HEAT_NETWORK },
      data: Object.values(data.electricity_demand_heat_pump_kwh_a).map(
        (e) => e / 1000,
      ),
    },
  ];
}

function getTotalIncreasePercent(data) {
  const values = data.map((e) => e.data);
  const baseYearSum = values.reduce((sum, arr) => sum + arr[0], 0);
  const targetYearSum = values.reduce(
    (sum, arr) => sum + arr[arr.length - 1],
    0,
  );
  return ((targetYearSum - baseYearSum) / baseYearSum) * 100;
}
</script>

<style scoped lang="scss">
.second-panel-card {
  @apply flex justify-between items-center gap-5;
}
</style>
