import building from './buildings';
import generationPotential from './generation-potential';
import geographicalLevel from './geographical-level';
import estates from './estates';

export default {
  ...building,
  ...generationPotential,
  ...geographicalLevel,
  ...estates,
};
