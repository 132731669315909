import { createStore } from 'vuex';
import layout from './modules/layout/index';
import root from './modules/index';
import search from '../apps/usecase-1/map/control-options/search/store';

export default createStore({
  modules: {
    layout,
    root,
    search,
  },
});
