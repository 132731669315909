<template>
  <VChart
    :option="chartOptions"
    :style="{ 'min-height': '0px', 'min-width': '0px' }"
    autoresize
    class="h-full w-full standard-elevation-0-dark"
  />
</template>

<script setup>
import { computed } from 'vue';
import {
  formatChartAxisLabel,
  formatChartTooltip,
} from '../../utils/chart-utils';

const props = defineProps({
  series: {
    type: Array,
    default: () => [],
  },
  labels: {
    type: Array,
    default: () => [],
  },
  title: {
    type: String,
    default: null,
  },
  titleTop: {
    type: String,
    default: '12px',
  },
  gridTop: {
    type: String,
    default: '13%',
  },
  gridLeft: {
    type: String,
    default: '3%',
  },
  gridRight: {
    type: String,
    default: '3%',
  },
  gridBottom: {
    type: String,
    default: '6%',
  },
  legend: {
    type: Object,
    default: () => {},
  },
  showXAxisLabel: {
    type: Boolean,
    default: true,
  },
  labelUnit: {
    type: String,
    default: null,
  },
  showTooltip: {
    type: Boolean,
    default: true,
  },
  allowTooltipCallback: {
    type: Function,
    default: () => true,
  },
  showXAxisLine: {
    type: Boolean,
    default: true,
  },
});

const chartOptions = computed(() => ({
  title: {
    text: props.title,
    left: 'center',
    top: props.titleTop,
    textStyle: {
      fontFamily: 'mark',
      fontWeight: 500,
      fontSize: 18,
      lineHeight: 20,
      color: '#7E7570',
    },
  },
  grid: {
    top: props.gridTop,
    left: props.gridLeft,
    right: props.gridRight,
    bottom: props.gridBottom,
    containLabel: true,
  },
  legend: {
    bottom: 4,
    itemWidth: 12,
    itemHeight: 12,
    left: 'center',
    textStyle: {
      fontFamily: 'enbw',
      fontWeight: 700,
      fontSize: 10,
      lineHeight: 11,
      color: '#7E7570',
    },
    selectedMode: false,
    ...props.legend,
  },
  tooltip: {
    show: props.showTooltip,
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
    },
    textStyle: {
      fontFamily: 'mark',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 20,
      color: '#7E7570',
    },
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    borderWidth: 0,
    borderRadius: 0,
    boxShadow: '0 0.5px 8px 0 rgba(207, 216, 220, 0.50)',
    formatter: function (params) {
      let result = '';
      params.forEach((param) => {
        if (props.allowTooltipCallback(param)) {
          result +=
            param.marker +
            param.seriesName +
            ': ' +
            formatChartTooltip(param.value) +
            ' ' +
            (props.labelUnit ?? '') +
            '<br>';
        }
      });
      return result;
    },
  },

  xAxis: {
    type: 'value',
    axisLabel: {
      show: props.showXAxisLabel,
      fontFamily: 'mark',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 16,
      color: '#322A26',
      formatter: function (value) {
        return formatChartAxisLabel(value);
      },
    },
    splitLine: {
      show: props.showXAxisLine,
    },
  },
  yAxis: {
    type: 'category',
    data: props.labels,
    axisLabel: {
      fontFamily: 'mark',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 16,
      color: '#322A26',
      breakWord: 'break-all',
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: '#C3BCB6',
        width: 3,
      },
    },
    axisTick: {
      show: false,
    },
  },
  series: props.series,
}));
</script>
