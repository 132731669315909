<template>
  <template v-for="item in model" :key="item[valueKey]">
    <div v-if="!('hidden' in item) || !item.hidden" class="flex">
      <label
        :class="{
          'p-2 min-h-[32px] body-3': small,
          'p-3 min-h-[40px] body-2': !small,
        }"
        :style="
          levelAdjustments[level].indent
            ? `padding-left: ${levelAdjustments[level].indent}px !important;`
            : null
        "
        class="flex items-center hover:bg-subtle w-full cursor-pointer"
      >
        <input
          :value="item.checked"
          :checked="item.checked"
          class="appearance-none"
          type="checkbox"
          :name="item[valueKey]"
          @click="selectItem(item)"
        />
        <span class="checkmark mr-3"></span>
        <span :class="levelAdjustments[level].fontClass">{{
          dataKey ? item[dataKey] : item
        }}</span>
      </label>
      <div v-if="'expanded' in item" class="ml-auto align-self-center">
        <v-icon
          v-if="!item.expanded"
          icon="mdi-plus"
          @click="(e) => expandItem(item, true, e)"
        />
        <v-icon
          v-if="item.expanded"
          icon="mdi-minus"
          @click="(e) => expandItem(item, false, e)"
        />
      </div>
    </div>
    <drop-down-multiple-grouped-list
      v-if="hasMoreSubItems(item) && (!('expanded' in item) || item.expanded)"
      v-model="item.items"
      :small="small"
      :level-adjustments="levelAdjustments"
      :level="level + 1"
      :expandable="'expanded' in item"
      :expanded="'expanded' in item && item.expanded"
      :value-key="valueKey"
      :data-key="dataKey"
    />
  </template>
</template>

<script setup>
const model = defineModel({ type: Array[Object], required: true });
const props = defineProps({
  dataKey: {
    type: String,
    default: null,
  },
  valueKey: {
    type: String,
    default: 'value',
  },
  small: {
    type: Boolean,
    default: false,
  },
  levelAdjustments: {
    type: Array[Object],
    required: true,
  },
  level: {
    type: Number,
    required: true,
  },
  expandable: {
    type: Boolean,
    required: true,
  },
  expanded: {
    type: Boolean,
    required: true,
  },
});

const hasMoreSubItems = (item) => {
  return 'items' in item && Array.isArray(item.items) && item.items.length > 0;
};

const selectItem = (item, newVal = null) => {
  newVal = newVal === null ? !item.checked : newVal;
  item.checked = newVal;
  let root = item;
  if (hasMoreSubItems(root)) {
    for (let subItem of root.items) {
      selectItem(subItem, newVal);
    }
  }
};

const expandItem = (item, newVal, event) => {
  item.expanded = newVal;
  event.stopPropagation();
};
</script>

<style lang="scss" scoped>
/* Hide the browser's default checkbox */
/* Create a custom checkbox */
.checkmark {
  min-height: 16px;
  min-width: 16px;
  position: relative;
  @apply border-2 border-title-neutral bg-default rounded-[2px];
}

/* When the checkbox is checked, add a blue background */
.dropdown-multiple input:checked ~ .checkmark {
  @apply bg-button-primary-default-color1 border-none;
}

/* Create the checkmark/indicator (hidden when not checked */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.dropdown-multiple input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.dropdown-multiple .checkmark:after {
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 6px;
  height: 12px;
  @apply border border-default;
  border-width: 0 2px 2px 0;
}
</style>
