import { getMap } from '@/mapbox/main';

export function setQueryRenderedFeatures(layers) {
  return getMap().queryRenderedFeatures({ layers });
}

export function clickOnMapInTest(x, y) {
  const map = getMap();
  map.fire('click', {
    latLng: [x, y],
    point: map.project([x, y]),
    originalEvent: {},
  });
}

export function clickOnMapInTestRight(x, y) {
  const map = getMap();
  map.fire('contextmenu', {
    latLng: [x, y],
    point: map.project([x, y]),
    originalEvent: {},
  });
}
