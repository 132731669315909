<template>
  <div :style="cssStyles" class="circle">
    <span class="number">{{ number }}</span>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  number: {
    type: Number,
    required: true,
  },
  size: {
    type: Number,
    default: 30,
  },
  gapAngle: {
    type: Number,
    default: 45, // Angle of the gap in the circle
  },
  color: {
    type: String,
    default: '#000099', // Default to blue
  },
});

const cssStyles = computed(() => {
  const circleThickness = `${props.size / 8}px`;
  // Adjust thickness relative to size
  const fromAngle = 230 + props.gapAngle / 2;
  // Starting angle from bottom center
  const toAngle = 385 - props.gapAngle;

  return {
    '--circle-size': `${props.size}px`,
    '--circle-color': props.color,
    '--circle-thickness': circleThickness,
    '--from-angle': `${fromAngle}deg`,
    '--to-angle': `${toAngle}deg`,
  };
});
</script>

<style scoped lang="scss">
.circle {
  width: var(--circle-size);
  height: var(--circle-size);
  border-radius: 50%;
  background: conic-gradient(
    from var(--from-angle),
    var(--circle-color) var(--to-angle),
    transparent 0
  );
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.circle::before {
  content: '';
  position: absolute;
  width: calc(100% - var(--circle-thickness));
  height: calc(100% - var(--circle-thickness));
  border-radius: 50%;
  background: white; /* Background of the inner part of the circle */
}

.number {
  position: relative;
  font-size: calc(var(--circle-size) / 2);
  color: var(--circle-color);
}
</style>
