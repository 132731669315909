<template>
  <VChart
    :option="chartOptions"
    :style="{ 'min-height': '0px', 'min-width': '0px' }"
    autoresize
    class="h-full w-full standard-elevation-0-dark"
  />
</template>

<script setup>
import { computed } from 'vue';
import {
  formatChartAxisLabel,
  formatChartTooltip,
} from '../../utils/chart-utils';

const props = defineProps({
  series: {
    type: Array,
    default: () => [],
  },
  labels: {
    type: Array,
    default: () => [],
  },
  titleLeft: {
    type: String,
    default: null,
  },
  titleRight: {
    type: String,
    default: null,
  },
  titleTop: {
    type: String,
    default: '12px',
  },
  gridTop: {
    type: String,
    default: '13%',
  },
  gridLeft: {
    type: String,
    default: '3%',
  },
  gridRight: {
    type: String,
    default: '3%',
  },
  gridBottom: {
    type: String,
    default: '6%',
  },
  legend: {
    type: Object,
    default: () => {},
  },
  showXAxisLabel: {
    type: Boolean,
    default: true,
  },
  labelUnit: {
    type: String,
    default: null,
  },
  showTooltip: {
    type: Boolean,
    default: true,
  },
  allowTooltipCallback: {
    type: Function,
    default: () => true,
  },
  showXAxisLine: {
    type: Boolean,
    default: true,
  },
  maxXAxis: {
    type: Number,
  },
});

const chartOptions = computed(() => ({
  title: [
    {
      text: props.titleLeft,
      left: '14%',
      top: props.titleTop,
      textStyle: {
        fontFamily: 'mark',
        fontWeight: 500,
        fontSize: 18,
        lineHeight: 20,
        color: '#7E7570',
      },
    },
    {
      text: props.titleRight,
      left: '65%',
      top: props.titleTop,
      textStyle: {
        fontFamily: 'mark',
        fontWeight: 500,
        fontSize: 18,
        lineHeight: 20,
        color: '#7E7570',
      },
    },
  ],
  grid: [
    {
      // Left grid for the first chart
      left: '5%',
      right: '40.6%',
      top: '10%',
      bottom: '10%',
      containLabel: true,
    },
    {
      // Right grid for the second chart
      left: '56%',
      right: '5%',
      top: '10%',
      bottom: '10%',
      containLabel: true,
    },
  ],
  legend: {
    show: false,
  },
  tooltip: {
    show: props.showTooltip,
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
    },
    textStyle: {
      fontFamily: 'mark',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 20,
      color: '#7E7570',
    },
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    borderWidth: 0,
    borderRadius: 0,
    boxShadow: '0 0.5px 8px 0 rgba(207, 216, 220, 0.50)',
    formatter: function (params) {
      let result = '';
      params.forEach((param) => {
        if (props.allowTooltipCallback(param)) {
          result +=
            param.marker +
            param.seriesName +
            ': ' +
            formatChartTooltip(param.value) +
            ' ' +
            (props.labelUnit ?? '') +
            '<br>';
        }
      });
      return result;
    },
  },

  xAxis: [
    {
      type: 'value',
      gridIndex: 0,
      inverse: true,
      axisLabel: {
        show: props.showXAxisLabel,
        fontFamily: 'mark',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: 16,
        color: '#322A26',
        formatter: function (value) {
          return formatChartAxisLabel(value);
        },
      },
      splitLine: {
        show: props.showXAxisLine,
      },
    },
    {
      type: 'value',
      gridIndex: 1,
      axisLabel: {
        show: props.showXAxisLabel,
        fontFamily: 'mark',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: 16,
        color: '#322A26',
        formatter: function (value) {
          return formatChartAxisLabel(value);
        },
      },
      splitLine: {
        show: props.showXAxisLine,
      },
    },
  ],
  yAxis: [
    {
      type: 'category',
      gridIndex: 0,
      data: props.labels,
      position: 'right',
      axisLabel: {
        fontFamily: 'mark',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: 16,
        color: '#322A26',
        breakWord: 'break-all',
        show: true,
        align: 'center',
        margin: 55,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    {
      type: 'category',
      gridIndex: 1,
      data: props.labels,
      axisLabel: {
        fontFamily: 'mark',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: 16,
        color: '#322A26',
        breakWord: 'break-all',
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
  ],
  series: props.series,
}));
</script>
