<template>
  <div>
    <h5 class="mb-5 mt-2 text-title-neutral">
      Ausweisung von Potenzialen nur bei folgenden Flächenkategorien:
    </h5>
    <div class="flex gap-5 mb-5">
      <div class="w-full standard-elevation-0 p-5">
        <p class="subtitle-2 text-neutral relative inline">
          Benachteiligte Flächen
          <ToolTip
            tooltip-text="Benachteiligte Gebiete nach Maßgabe der FFÖ-VO bzw. des EEG auf Ebene der Gemarkungen. Sind Gemarkungen vollständig als benachteiligtes Gebiet erfasst, liegen alle Flurstücke der Gemarkung im benachteiligten Gebiet. In den Fällen, in denen nur Teilflächen als benachteiligtes Gebiet eingestuft sind, befinden sich nur Teile (Flurstücke) der Gemarkung im benachteiligten Gebiet."
            class="absolute top-[-5px] right-[-35px]"
          />
        </p>
        <div class="flex mb-4 mt-5">
          <CheckboxEl
            :model-value="potentialAnalysis.fully_disadvantaged_area"
            label="Vollständig benachteiligtes Gebiet"
            @update:model-value="
              updateAnalysis('fully_disadvantaged_area', $event)
            "
          />
        </div>
        <div class="flex">
          <CheckboxEl
            :model-value="potentialAnalysis.partial_disadvantaged_area"
            label="Mit benachteiligten Teilflächen"
            @update:model-value="
              updateAnalysis('partial_disadvantaged_area', $event)
            "
          />
        </div>
      </div>
      <div class="w-full standard-elevation-0 p-5">
        <p class="subtitle-2 text-neutral relative inline">
          Landwirtschaftliche Parzellen
          <ToolTip
            tooltip-text="Kategorisierung von landwirtschaftlichen Parzellen gemäß dem Geografischen Informationssystem Entwicklung in der Landwirtschaft (GISELa)."
            class="absolute top-[-5px] right-[-35px]"
          />
        </p>
        <div class="flex mb-4 mt-5">
          <CheckboxEl
            :model-value="potentialAnalysis.farmland"
            label="Ackerland"
            @update:model-value="updateAnalysis('farmland', $event)"
          />
        </div>
        <div class="flex">
          <CheckboxEl
            :model-value="potentialAnalysis.greenland"
            label="Grünland"
            @update:model-value="updateAnalysis('greenland', $event)"
          />
        </div>
      </div>
    </div>
    <h5 class="mt-2 text-title-neutral relative inline">
      Ausweisung von Potenzialen nur bei folgenden topografischen Gegebenheiten:
      <ToolTip
        tooltip-text="Hangneigung und Ausrichtung sind auf einem Raster von 100 x 100 m über den Gradient berechnet. Aufgrund der Rastergröße kann es zu geografischen Unschärfen kommen. Raster, die den folgenden Kriterien nicht entsprechen, werden in der Ausweisung der Potenziale exkludiert."
        class="absolute top-[-1px] right-[-35px]"
      />
    </h5>
    <div class="w-full standard-elevation-0 p-5 mt-5">
      <div class="grid-container">
        <div>
          <CheckboxEl
            v-model="slopeFieldsActive"
            label="Hangneigung"
            class="mr-16"
          />
        </div>
        <div class="flex items-center">
          <InputEl
            :model-value="potentialAnalysis.slope_gradient_from"
            class="w-[120px]"
            suffix="°"
            input-type="integer"
            :disabled="!slopeFieldsActive"
            @update:model-value="updateAnalysis('slope_gradient_from', $event)"
          />
          <span class="mx-2.5 body-2 mt-1">bis</span>
          <InputEl
            :model-value="potentialAnalysis.slope_gradient_to"
            class="w-[120px]"
            suffix="°"
            input-type="integer"
            :disabled="!slopeFieldsActive"
            @update:model-value="updateAnalysis('slope_gradient_to', $event)"
          />
        </div>
        <div>
          <CheckboxEl
            v-model="surfaceFieldsActive"
            label="Flächenausrichtung"
            class="mr-4"
          />
        </div>
        <div class="flex items-center">
          <InputEl
            :model-value="potentialAnalysis.surface_gradient_from"
            class="w-[120px]"
            suffix="°"
            input-type="integer"
            :disabled="!surfaceFieldsActive"
            @update:model-value="
              updateAnalysis('surface_gradient_from', $event)
            "
          />
          <span class="mx-2.5 body-2 mt-1">bis</span>
          <InputEl
            :model-value="potentialAnalysis.surface_gradient_to"
            class="w-[120px]"
            suffix="°"
            input-type="integer"
            :disabled="!surfaceFieldsActive"
            @update:model-value="updateAnalysis('surface_gradient_to', $event)"
          />
          <ToolTip
            tooltip-text="Die Eingabe der Ausrichtung erfolgt in Grad - eine Südausrichtung von SO bis SW entspräche dem Bereich von 135° bis 225°. Achtung: die Datengrundlage der topografischen Daten liegen in einer Auflösung von 100x100m vor. Diese Auflösung kann zu Unschärfen hinsichtlich der Hangneigung und -Ausrichtung führen."
            class="ml-4"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import InputEl from '@/components/input/InputEl.vue';
import CheckboxEl from '@/components/CheckboxEl/CheckboxEl.vue';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import { ref, watch } from 'vue';

const props = defineProps({
  potentialAnalysis: Object,
});

const emit = defineEmits([
  'update:potentialAnalysis',
  'surfaceFieldsActive',
  'slopeFieldsActive',
]);

const surfaceFieldsActive = ref(false);
const slopeFieldsActive = ref(false);

watch(slopeFieldsActive, (currentValue) => {
  emit('slopeFieldsActive', currentValue);
});

watch(surfaceFieldsActive, (currentValue) => {
  emit('slopeFieldsActive', currentValue);
});

function updateAnalysis(field, value) {
  emit('update:potentialAnalysis', {
    ...props.potentialAnalysis,
    [field]: value,
  });
}
</script>
<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: min-content auto;
  align-items: center;
  row-gap: 20px;
}
</style>
