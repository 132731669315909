import { omit } from 'lodash';
import { fetchData } from './utils';
import axios from '@/utils/axiosHelper';
import { mergeFeatureDataAndLayerConfig } from '@/apps/usecase-1/map/info-panel/panel-data/panel-data-calculator';

export class SimplePanelScenarioDependent {
  metaData = null;

  constructor(
    url,
    layerIds,
    optionAxios,
    {
      otherNestedKeys = [],
      yearlyResultsKey = 'yearly_results',
      customMetaView = false,
    } = {},
  ) {
    this.url = url;
    this.layerIds = layerIds;
    this.otherNestedKeys = otherNestedKeys;
    this.yearlyResultsKey = yearlyResultsKey;
    this.customMetaView = customMetaView;
    this.optionAxios = optionAxios;
  }

  async requestFeatureData(feature, mapStore) {
    const scenarioId = mapStore.scenarioSelected.id;
    const featureId = feature.properties.id;
    const layerId = feature.layer.id;
    if (!mapStore.metaDataLayers[layerId]) {
      const metaData = {};
      const options = await axios(this.optionAxios);

      let metaDataResponse;
      if (this.customMetaView) metaDataResponse = options.data;
      else metaDataResponse = options.data.actions.POST;

      for (const [key, value] of Object.entries(metaDataResponse)) {
        if (value.type === 'nested object') {
          for (const [keyNested, valueNested] of Object.entries(
            value.children,
          )) {
            metaData[keyNested] = valueNested;
          }
        } else if (key === this.yearlyResultsKey) {
          for (const [keyNested, valueNested] of Object.entries(
            value.child.children,
          )) {
            metaData[keyNested] = valueNested;
          }
        } else {
          metaData[key] = value;
        }
      }
      mapStore.setLayerMetaData(layerId, metaData);
    }
    const resp = await fetchData(`${this.url}/${scenarioId}/${featureId}/`);

    if (resp === null) return resp;

    const featureData = resp.data;
    featureData.layerId = layerId;
    return featureData;
  }

  calculatePanelData(featureData, scenarioYear, mapStore) {
    const nestedResults = [...this.otherNestedKeys, this.yearlyResultsKey];
    const mainResult = omit(featureData, nestedResults);
    const yearlyResult = featureData[this.yearlyResultsKey];
    for (const key of this.otherNestedKeys) {
      for (const [fieldKey, value] of Object.entries(featureData[key]))
        mainResult[fieldKey] = value;
    }
    const panelConfig = mapStore.layerConfigs[featureData.layerId].sidebar;
    const metaData = mapStore.metaDataLayers[featureData.layerId];
    return mergeFeatureDataAndLayerConfig(
      mainResult,
      yearlyResult,
      scenarioYear,
      panelConfig,
      null,
      metaData,
    );
  }
}
