<template>
  <VSlider
    v-model="year"
    :ticks="Object.assign({}, years)"
    :max="years.length - 1"
    step="1"
    show-ticks="always"
    tick-size="4"
    :ripple="false"
    :hide-details="true"
    class="bg-white rounded-[8px]"
    data-test="year-slider"
  />
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useMapStore } from '@/apps/usecase-1/map/map-store';

const mapStore = useMapStore();
const props = defineProps({
  years: {
    type: Array,
    default: () => [],
  },
});

const year = ref(0);

watch(year, (val) => {
  mapStore.setYear(props.years[val]);
});
const scenarioYear = computed(() => mapStore.scenarioYear);
watch(scenarioYear, (val) => {
  year.value = props.years.findIndex((e) => e === val);
});
</script>
