<template>
  <!-- TODO: Überall noch Zieljahr des Szenarios -->
  <div class="flex flex-col gap-7">
    <div class="flex flex-col gap-5">
      <div class="flex gap-1">
        <h3 class="text-color1">Netzausbau Umspannwerke Mittelspannung</h3>
        <ToolTip
          :tooltip-text="'Dargestellt ist eine reine\nEinspeisebetrachtung. Die Last ist\nin der Darstellung vernachlässigt.'"
        />
      </div>
      <div class="projects-grid-wrapper">
        <div class="projects-grid-item shadow-none h-[340px]">
          <WaterfallChart
            class="p-5 rounded-[4px]"
            :data="windPvFreePrediction"
            :labels="labelsPower"
            :title="'Leistungszuwachs\n PV-Freifläche und Wind'"
            grid-top="70px"
            title-top="0px"
            unit="MW(el)"
            y-axis-name="MW(el)"
            grid-left="7%"
            :show-y-axis-line="true"
          />
        </div>

        <div class="projects-grid-item h-[340px] p-5">
          <div
            class="flex flex-col gap-5 items-center justify-center h-full w-full relative"
          >
            <h4 class="text-title-neutral text-center absolute top-0.5">
              Anzahl notwendiger<br />neuer Umspannwerke
            </h4>
            <ArrowNumberCard
              :model-value="Math.ceil(requiredSubstations)"
              :sub-text="`gemäß Wert\nLeistungszuwachs aus\nPV-Freifläche und Wind\nbis ${resultStore.activeProject.target_year}`"
            />
          </div>
        </div>
        <div class="projects-grid-item shadow-none h-[340px]">
          <BarChartVertical
            :title="'Flächenverfügbarkeit vs.-\nBedarf für neue Umspannwerke'"
            :series="series"
            :labels="labels"
            :legend="legend"
            grid-left="10%"
            title-top="0px"
            grid-right="10%"
            grid-top="70px"
            class="p-5 rounded-[4px]"
            :show-x-axis-label="false"
            label-unit="ha"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-5">
      <div class="flex gap-1">
        <h3 class="text-color1">Netzausbau Trafo-Stationen Niederspannung</h3>
        <ToolTip
          :tooltip-text="'Die Anzahl der Trafos bemisst sich\naus dem höheren Zuwachs\n(Einspeiseleistung bzw. Last)'"
        />
      </div>
      <div class="projects-grid-wrapper">
        <div class="projects-grid-item h-[340px]">
          <WaterfallChart
            class="p-5 rounded-[4px]"
            :data="powerLoadPrediction"
            :labels="labelsLoad"
            :title="'Stromlastzuwachs\n(nach Gleichzeitigkeit)'"
            grid-top="70px"
            title-top="0px"
            unit="MW(el)"
            :show-y-axis-line="true"
            y-axis-name="MW(el)"
            grid-left="7%"
          />
        </div>
        <div class="projects-grid-item h-[340px]">
          <WaterfallChart
            class="p-5 rounded-[4px]"
            :data="pvRoofPrediction"
            :labels="labelsPower"
            :title="'Leistungszuwachs\nPV-Aufdach'"
            grid-top="70px"
            title-top="0px"
            unit="MW(el)"
            :show-y-axis-line="true"
            y-axis-name="MW(el)"
            grid-left="7%"
          />
        </div>
        <div class="projects-grid-item h-[340px] p-5">
          <div
            class="flex flex-col gap-5 items-center justify-center h-full w-full relative"
          >
            <h4 class="text-title-neutral text-center absolute top-0.5">
              Anzahl notwendiger<br />neuer Trafostationen
            </h4>
            <ArrowNumberCard
              :model-value="Math.ceil(requiredTrafoStations)"
              :sub-text="`gemäß ${trafoDriver}\nbis ${resultStore.activeProject.target_year}`"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <SourcesItem item="grid-planning" />
</template>

<script setup>
import { computed, watch } from 'vue';
import { AREA, POWER_DEFAULT } from '@/configs/color-constants';
import WaterfallChart from '@/components/charts/WaterfallChart/WaterfallChart.vue';
import BarChartVertical from '@/components/charts/BarChartVertical.vue';
import ArrowNumberCard from '@/apps/usecase-1/projects/project-result-detail/components/ArrowNumberCard.vue';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import SourcesItem from '@/components/sourcesItem/SourcesItem.vue';
import { useProjectResult } from './project-result-store';

const props = defineProps({
  projectId: {
    type: String,
    required: true,
  },
  scenarioId: {
    type: String,
    required: true,
  },
});

const resultStore = useProjectResult();

await resultStore.fetchDemandData(props.projectId, props.scenarioId);
await resultStore.fetchPotentialGenerationData(
  props.projectId,
  props.scenarioId,
);

await resultStore.applyDefaultGenerationScaling(props.scenarioId);

// set scenario in store after data is fetched
resultStore.setActiveScenarioById(props.scenarioId);

const requiredSubstations = computed(
  () =>
    (resultStore.appliedTotalIncreasePvFreeMw +
      resultStore.appliedTotalIncreaseWindMw) /
    resultStore.assumptionsGeneral.specific_power_substation_mw,
);

const trafoDriver = computed(() => {
  const {
    scaledDemandTotalMw,
    demandStatusQuoMw,
    appliedTotalIncreasePvRoofMw,
  } = resultStore;
  const growthLoad = scaledDemandTotalMw - demandStatusQuoMw;
  if (growthLoad > appliedTotalIncreasePvRoofMw) return 'Stromlastzuwachs';
  return 'Leistungszuwachs\nPV-Aufdach';
});

const requiredTrafoStations = computed(() => {
  const {
    scaledDemandTotalMw,
    demandStatusQuoMw,
    appliedTotalIncreasePvRoofMw,
    assumptionsGeneral,
  } = resultStore;
  const growthLoad = scaledDemandTotalMw - demandStatusQuoMw;
  const growth = Math.max(growthLoad, appliedTotalIncreasePvRoofMw);
  return growth / assumptionsGeneral.specific_power_trafo_mw;
});
const labelsLoad = [
  'Last heute',
  'Lastzuwachs',
  `Last ${resultStore.activeProject.target_year}`,
];
const labelsPower = [
  'Leistung heute',
  'Leistungszuwachs',
  `Leistung ${resultStore.activeProject.target_year}`,
];
const windPvFreePrediction = computed(() => {
  const {
    generationPvFreeMw,
    generationWindMw,
    appliedTotalIncreasePvFreeMw,
    appliedTotalIncreaseWindMw,
  } = resultStore;
  const generation = generationPvFreeMw + generationWindMw;
  const growth = appliedTotalIncreasePvFreeMw + appliedTotalIncreaseWindMw;
  const total = generation + growth;

  const { POWER_TODAY, POWER_INCREASE, POWER_TARGET } = POWER_DEFAULT;

  return {
    visibleStacks: [
      { value: generation, itemStyle: { color: POWER_TODAY } },
      { value: growth, itemStyle: { color: POWER_INCREASE } },
      { value: total, itemStyle: { color: POWER_TARGET } },
    ],
    hiddenStacks: [0, generation, 0],
  };
});

const powerLoadPrediction = computed(() => {
  const { demandStatusQuoMw, scaledDemandTotalMw } = resultStore;
  const { POWER_TODAY, POWER_INCREASE, POWER_TARGET } = POWER_DEFAULT;

  return {
    visibleStacks: [
      { value: demandStatusQuoMw, itemStyle: { color: POWER_TODAY } },
      {
        value: scaledDemandTotalMw - demandStatusQuoMw,
        itemStyle: { color: POWER_INCREASE },
      },
      { value: scaledDemandTotalMw, itemStyle: { color: POWER_TARGET } },
    ],
    hiddenStacks: [0, demandStatusQuoMw, 0],
  };
});

const pvRoofPrediction = computed(() => {
  const { generationPvRoofMw, appliedTotalIncreasePvRoofMw } = resultStore;
  const { POWER_TODAY, POWER_INCREASE, POWER_TARGET } = POWER_DEFAULT;

  return {
    visibleStacks: [
      { value: generationPvRoofMw, itemStyle: { color: POWER_TODAY } },
      {
        value: appliedTotalIncreasePvRoofMw,
        itemStyle: { color: POWER_INCREASE },
      },
      {
        value: generationPvRoofMw + appliedTotalIncreasePvRoofMw,
        itemStyle: { color: POWER_TARGET },
      },
    ],
    hiddenStacks: [0, generationPvRoofMw, 0],
  };
});

const series = computed(() => [
  {
    name: 'Bestand',
    type: 'bar',
    barWidth: 25,
    data: [
      {
        value:
          Math.ceil(requiredSubstations.value) *
          resultStore.assumptionsGeneral.area_per_substation_ha,
        itemStyle: { color: AREA.REQUIRED },
      },
      // TODO: Add Value
      { value: 70, itemStyle: { color: AREA.AVAILABLE } },
    ],
  },
]);
const legend = { show: false };
const labels = ['Flächenbedarf', 'Flächenverfügbarkeit\n(aus Suchräumen)'];

watch(
  () => props.scenarioId,
  async () => {
    await resultStore.applyDefaultGenerationScaling(props.scenarioId);
  },
);
</script>

<style scoped></style>
