<template>
  <FormTabs :tabs="tabs">
    <template #slot0>
      <ProjectReportItemList :data="downloadData" />
    </template>
    <template #slot1>
      <ProjectReportUploadTemplate />
    </template>
  </FormTabs>
</template>
<script setup>
import FormTabs from '@/components/formTabs/FormTabs.vue';
import { ref } from 'vue';
import ProjectReportItemList from '@/apps/usecase-1/projects/project-result-detail/components/ProjectReportItemList.vue';
import ProjectReportUploadTemplate from '@/apps/usecase-1/projects/project-result-detail/components/ProjectReportUploadTemplate.vue';

const props = defineProps({
  projectId: {
    type: String,
    required: true,
  },
  scenarioId: {
    type: String,
    required: true,
  },
});

const tabs = [
  {
    title: 'Berichte',
    icon: 'insert_drive_file',
    iconType: 'outlined',
  },
  // {
  //   title: 'Upload Template',
  //   icon: 'upload_file',
  //   iconType: 'outlined',
  // },
];

const downloadData = ref([
  {
    id: 1,
    title: 'Potenzialanalyse Energieautarkie',
    description: 'Beschreibungstext....',
    link: 'https://www.google.com',
  },
]);
</script>
