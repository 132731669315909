<template>
  <VExpansionPanels
    v-model="openPanel"
    variant="accordion"
    class="gap-2.5 expansion-panels-custom"
    :multiple="multiple"
    :eager
  >
    <VExpansionPanel
      v-for="(panel, index) in panels"
      :key="panel"
      :collapse-icon="null"
      :expand-icon="null"
      class="rounded-none standard-elevation-0-dark"
    >
      <VExpansionPanelTitle class="p-5 flex justify-between items-center">
        <CircleWithNumber
          v-if="showCircleNumber"
          :number="index + 1"
          class="mr-3"
        />
        <h3 class="text-title-neutral pb-0.5 mr-auto">{{ panel }}</h3>
        <IconWrapper
          :class="{ 'rotate-180': openPanel === index }"
          fill="text-core-color1"
          :size="40"
          class="duration-300"
          icon="expand_more"
        />
      </VExpansionPanelTitle>

      <VExpansionPanelText>
        <slot :name="`panel${index + 1}`" />
      </VExpansionPanelText>
    </VExpansionPanel>
  </VExpansionPanels>
</template>
<script setup>
import { ref, watch } from 'vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import CircleWithNumber from '@/components/ExpansionPanel/components/CircleWithNumber.vue';

const props = defineProps({
  panels: {
    type: Array,
    default: () => [],
  },
  firstPanelOpen: {
    type: Boolean,
    default: true,
  },
  showCircleNumber: {
    type: Boolean,
    default: true,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  allPanelsOpen: {
    type: Boolean,
    default: false,
  },
  eager: {
    type: Boolean,
    default: false,
  },
});
const openPanel = ref();
if (props.multiple && props.allPanelsOpen) {
  openPanel.value = Array.from(
    { length: props.panels.length + 1 },
    (_, index) => index,
  );
} else {
  openPanel.value = props.firstPanelOpen ? 0 : null;
}
</script>

<style lang="scss">
.expansion-panels-custom {
  .v-expansion-panel-title__icon {
    display: none;
  }

  .v-expansion-panel::after {
    display: none;
  }

  .v-expansion-panel__shadow {
    display: none;
  }

  .v-expansion-panel-text__wrapper {
    padding: 20px;
  }
}
</style>
