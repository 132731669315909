<template>
  <div
    class="slider-container"
    :style="cssVars"
    :class="{ vertical: vertical }"
  >
    <input
      v-model.number="internalValue"
      type="range"
      :min="initialEnabledMin"
      :max="initialEnabledMax"
      :step="step"
      class="slider"
      :class="{ vertical: vertical }"
      :style="{ background: sliderGradient }"
    />
    <div class="points-container" :class="{ vertical: vertical }">
      <div
        v-for="n in calculatedSteps"
        :key="n"
        :class="{
          point: true,
          ...isNotInRange(n / calculatedSteps),
        }"
      ></div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
  modelValue: {
    type: [Number, String],
    default: null,
  },
  enabledMax: {
    type: Number,
    default: 1,
  },
  enabledMin: {
    type: Number,
    default: -1,
  },
  color: {
    type: String,
    default: '#00A1D1',
  },
  enablePositiveNegativeBreak: {
    type: Number,
    default: null,
  },
  id: {
    type: [Number, String],
    default: null,
  },
  step: {
    type: Number,
    default: 0.1,
  },
  vertical: {
    type: Boolean,
    default: false,
  },
});

const internalValue = ref(parseFloat(props.modelValue) || 0);

const cssVars = computed(() => ({
  '--main-color': props.color,
}));

// Store initial values of enabledMax and enabledMin for each id
const initialValues = ref({});

const sliderGradient = computed(() => {
  const percentage =
    ((internalValue.value - initialEnabledMin.value) /
      (initialEnabledMax.value - initialEnabledMin.value)) *
    100;
  const direction = props.vertical ? 'to top' : 'to right';
  return `linear-gradient(${direction}, ${props.color} ${percentage}%, #D9D9D9 ${percentage}%)`;
});

const initialEnabledMin = computed(() => {
  return initialValues.value[props.id]?.enabledMin ?? props.enabledMin;
});

const initialEnabledMax = computed(() => {
  return initialValues.value[props.id]?.enabledMax ?? props.enabledMax;
});

function setInitialValues(id, enabledMin, enabledMax) {
  if (!initialValues.value[id]) {
    initialValues.value[id] = {
      enabledMin,
      enabledMax,
    };
  }
}

function isNotInRange(pointValue) {
  if (
    pointValue >= initialEnabledMax.value + props.step ||
    pointValue < initialEnabledMin.value
  ) {
    return { 'point-not-in-range': true };
  }

  if (props.enablePositiveNegativeBreak !== null) {
    return pointValue >= props.enablePositiveNegativeBreak
      ? { 'point-green': true }
      : { 'point-red': true };
  }

  return false;
}

const calculatedSteps = computed(() => {
  return Math.round(
    (initialEnabledMax.value - initialEnabledMin.value) / props.step + 1,
  );
});

watch(
  () => internalValue.value,
  (newValue) => {
    emit('update:modelValue', newValue);
  },
);

watch(
  () => props.id,
  (newId) => {
    if (newId !== null) {
      setInitialValues(newId, initialEnabledMin.value, initialEnabledMax.value);
      emit('update:modelValue', initialEnabledMax.value);
    }
  },
  { immediate: true },
);

watch(
  () => props.modelValue,
  (newValue) => {
    internalValue.value = parseFloat(newValue) || 0;
  },
);
</script>

<style lang="scss" scoped>
.slider-container {
  width: 100%;
  margin: auto;
  position: relative;

  &.vertical {
    height: 315px; /* Adjust height as needed */
    width: 20px; /* Adjust width to fit the thumb size */
    display: flex;
    gap: 6px;
    justify-content: center;
    align-items: center;
  }
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  border-radius: 4px;
  height: 4px;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;

  &.vertical {
    width: 4px;
    height: 100%;
    writing-mode: vertical-lr;
    direction: rtl;
  }
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--main-color);
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--main-color);
  cursor: pointer;
}

.points-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 8px;

  &.vertical {
    flex-direction: column;
    height: 100%;
  }
}

.point {
  width: 4px;
  height: 4px;
  background: var(--main-color);
  border-radius: 50%;
  margin-top: -2px;
}

.point-not-in-range {
  background: #d9d9d9;
}

.point-red {
  background: #bc0606;
}

.point-green {
  background: #00b979;
}
</style>
