<template>
  <div class="map-container h-full">
    <InfoPanel
      v-if="showInfoPanel"
      class="absolute top-[80px] flex items-stretch z-20"
      :class="{
        'left-[400px]':
          getMainMenuShow || (showSearch && getResultsLength !== 0),
        'left-5': !(getMainMenuShow || (showSearch && getResultsLength !== 0)),
      }"
      :feature="clickedFeature"
      :feature-id="clickedFeatureId"
      data-test="info-panel"
    />
    <SearchResults />
    <div id="map"></div>
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import {
  createMap,
  destroyMap,
  setFilter,
  setStyle,
  setVisibility,
  toggleSatellite,
  changeSourceLayer,
} from '@/mapbox/main';
import layerConfig from './layer-config';
import InfoPanel from '@/apps/usecase-1/map/info-panel/InfoPanel.vue';
import SearchResults from '@/apps/usecase-1/map/control-options/search/SearchResults.vue';
import { useMapStore } from './map-store';

const store = useStore();
const mapStore = useMapStore();
const props = defineProps({
  selectedScenarioId: {
    type: Number,
    required: true,
  },
  projectBaseYear: {
    type: Number,
    required: true,
  },
});

const clickedFeature = ref(null);
const clickedFeatureId = ref(null);
const initialMapStyleIsLoaded = ref(false);
const layer = ref(layerConfig);

mapStore.setYear(props.projectBaseYear);

const satelliteIsActive = computed(() => mapStore.satelliteIsActive);
const showInfoPanel = computed(() => mapStore.showInfoPanel);
const year = computed(() => mapStore.year);
const getMainMenuShow = computed(() => store.getters['getMainMenuShow']);
const showSearch = computed(() => store.getters['search/showSearch']);
const getResults = computed(() => store.getters['search/getResults']);
const getResultsLength = computed(() =>
  getResults.value ? getResults.value.length : 0,
);
const getLayersForYearlyFilter = computed(
  () => mapStore.getLayersForYearlyFilter,
);
const getLayerStates = computed(() => mapStore.getLayerStates);
const getAppliedFilters = computed(() => mapStore.getAppliedFilters);

// TODO pass correct year
onMounted(() => {
  createMap(
    mapStore,
    {
      applyFilters,
      featureClicked,
      featureIdClicked,
      commitYearFilter,
      initialMapStyleLoaded,
    },
    layer.value,
    year,
    props.selectedScenarioId,
  );
});

onUnmounted(() => {
  destroyMap();
});

// Methods
function commitYearFilter() {
  mapStore.addFilter({
    layerKeys: getLayersForYearlyFilter.value,
    filter: {
      id: 'yearFilterFromSlider',
      filter: ['>=', year.value, ['get', 'year_of_technology_switch']],
    },
  });
}

function applyFilters(filters) {
  Object.entries(filters).forEach(([key, value]) => {
    setFilter(key, value);
  });
}

function initialMapStyleLoaded(isLoaded) {
  initialMapStyleIsLoaded.value = isLoaded;
}

function featureClicked(feature) {
  clickedFeature.value = feature;
}

function featureIdClicked(id) {
  clickedFeatureId.value = id;
}

watch(satelliteIsActive, (newValue) => {
  toggleSatellite(newValue);
});

watch(
  [getLayerStates, year],
  () => {
    setVisibility(getLayerStates.value);
    setStyle(getLayerStates.value, layer.value, year.value);
  },
  { deep: true },
);

watch(
  () => props.selectedScenarioId,
  (newVal, oldVal) => {
    const scenarioIds = { previous: oldVal, new: newVal };
    mapStore.getLayers.forEach((sourceLayer) => {
      changeSourceLayer(sourceLayer, scenarioIds);
    });
    mapStore.setShowInfoPanel(false);
  },
  { deep: true },
);

watch(
  getAppliedFilters,
  (newValue) => {
    if (initialMapStyleIsLoaded.value) {
      applyFilters(newValue);
    }
  },
  { deep: true },
);
</script>
