<template>
  <div>
    <transition name="fade-vertical">
      <div
        v-if="baseStore.getShowScenarioSelector"
        :class="{ 'rounded-b-none': isExpanded }"
        class="bg-default standard-elevation-1 rounded-[8px] outer-container"
      >
        <div class="relative w-full flex p-2 gap-2">
          <div class="flex standard-elevation-0-dark rounded-[6px]">
            <div
              ref="scenarioWrapper"
              class="scenario-wrapper z-20"
              :class="{
                extended: isExpanded,
              }"
            >
              <div class="scenario-box flex gap-2">
                <div
                  class="flex items-center min-w-[170px] justify-between gap-1.5 standard-elevation-0-dark py-1 px-1.5 rounded-[6px]"
                  :class="disabled ? 'cursor-not-allowed' : 'cursor-pointer'"
                  @click="!disabled && (isExpanded = !isExpanded)"
                >
                  <div class="flex flex-col">
                    <div
                      class="caption-1 whitespace-nowrap flex"
                      :class="disabled ? 'text-disabled' : 'text-color1'"
                    >
                      Szenario-Label
                    </div>
                    <h5
                      class="whitespace-nowrap max-w-60 truncate"
                      :class="disabled ? 'text-disabled' : 'text-title-color1'"
                    >
                      {{
                        scenarios.find((item) => item.id === selectedScenarioId)
                          ?.title
                      }}
                    </h5>
                  </div>
                  <IconWrapper
                    icon="keyboard_arrow_down"
                    :fill="disabled ? 'text-disabled' : 'text-color1'"
                    :class="[
                      {
                        'rotate-180': isExpanded,
                      },
                      'transform duration-300',
                    ]"
                  />
                </div>
                <ButtonEl
                  icon="close"
                  color="color2"
                  :edges="true"
                  class="mr-1"
                  @click="baseStore.setShowScenarioSelector(false)"
                />
              </div>
              <div v-if="isExpanded" class="scenario-list-wrapper" @click.stop>
                <InputSearch
                  placeholder="Szenarionahme"
                  :search-keys="['title']"
                  :items="scenarios"
                  @update:search="filteredScenarios = $event"
                />
                <div
                  class="flex flex-col gap-0.5 overflow-y-auto hide-scrollbar"
                  :style="{
                    'max-height': `${mapStore.getContainerHeight - 150}px`,
                  }"
                >
                  <div
                    v-for="(item, index) in filteredScenarios"
                    :key="index"
                    class="cursor-pointer"
                    :class="
                      scenarioHoverIndex === index ? 'bg-white' : 'bg-subtle/50'
                    "
                    @mouseenter="scenarioHoverIndex = index"
                  >
                    <div
                      :class="{
                        'standard-elevation-0-dark':
                          scenarioHoverIndex === index,
                      }"
                      class="m-1 p-2.5 flex flex-col items-start gap-2.5 rounded-[4px]"
                    >
                      <div
                        class="flex flex-col gap-1 w-full border-b border-line-neutral"
                      >
                        <VRadio
                          :model-value="isSelectedScenario(item)"
                          :density="null"
                          @click="selectScenario(item)"
                          @update:model-value="isSelectedScenario(item)"
                        >
                          <template #label>
                            <h6
                              class="flex"
                              :class="{
                                'text-title-color1': isSelectedScenario(item),
                                'text-title-neutral': !isSelectedScenario(item),
                              }"
                            >
                              {{ item.title }}
                            </h6>
                          </template>
                        </VRadio>
                      </div>
                      <div class="flex flex-col gap-1 w-full text-neutral">
                        <div class="flex justify-between">
                          <div class="body-2">Zieljahr</div>
                          <div class="body-3">{{ item.target_year }}</div>
                        </div>
                      </div>
                      <ButtonEl
                        v-show="scenarioHoverIndex === index"
                        :class="[{ visible: scenarioHoverIndex === index }]"
                        size="s"
                        icon="ads_click"
                        text="Öffnen"
                        @click="
                          router.push({
                            name: 'AssumptionsScenario',
                          })
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import { computed, ref } from 'vue';
import { useListeners } from '@/composables/useListeners';
import InputSearch from '@/components/inputSearch/InputSearch.vue';
import { useRouter } from 'vue-router';
import { useBaseStore } from '@/apps/basemodule/base-store';
import { useMapStore } from '@/apps/usecase-1/map/map-store';

const baseStore = useBaseStore();
const mapStore = useMapStore();

const props = defineProps({
  scenarios: Array,
  selectedScenarioId: Number,
});

const emit = defineEmits(['select-scenario']);

const router = useRouter();

const isExpanded = ref(false);
const scenarioWrapper = ref(null);
const filteredScenarios = ref(props.scenarios);
const scenarioHoverIndex = ref(
  props.scenarios.findIndex((item) => item.id === props.selectedScenarioId),
);

const disabled = computed(() => props.scenarios.length === 1);

function selectScenario(scenario) {
  emit('select-scenario', scenario.id);
  isExpanded.value = false;
}

function isSelectedScenario(item) {
  return item.id === props.selectedScenarioId;
}

useListeners({
  click: handleClickOutside,
  keydown: handleKeydown,
});

function handleClickOutside(event) {
  if (!scenarioWrapper.value?.contains(event.target)) {
    isExpanded.value = false;
  }
}
function handleKeydown(event) {
  if (
    event.key === 'Escape' &&
    !scenarioWrapper.value?.contains(event.target)
  ) {
    isExpanded.value = false;
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles';

.outer-container {
  @apply absolute left-1/2 -top-2 transform -translate-x-1/2;
  @media (max-width: 1020px) {
    @apply top-12;
  }
}

.scenario-wrapper {
  display: flex;
  flex-direction: column;
  @apply rounded-[15px];

  .scenario-list-wrapper {
    @apply p-2 mt-2 pt-0 rounded-b-[8px] flex flex-col gap-1 w-full left-0
    top-[59px] z-10;

    @apply absolute bg-white gap-[6px];
    @extend .standard-elevation-1;

    &::before {
      @apply absolute left-0 top-[-3px] w-full h-[3px] bg-white;
      content: '';
    }

    .scenario-item {
      @apply bg-subtle rounded-[3px] flex items-center justify-start p-2 min-w-max h-8
      cursor-pointer text-neutral;
      @extend .body-2;

      &.disabled {
        pointer-events: none;
        background-color: white;
        opacity: 0.7;
      }
    }
  }
}
</style>
