<template>
  <div class="flex justify-center items-center">
    <div
      v-if="item.progress === 2 && item.progress_percentage !== 1"
      class="bg-disabled-warning rounded-full text-status-info flex flex-nowrap gap-0.5 items-center p-1"
    >
      <IconWrapper :icon="IconTaskProgressSharp" :size="16" />
      <div class="caption-1 whitespace-nowrap leading-[12px] pt-0.5">
        {{ progressPercent }}
      </div>
    </div>
    <ToolTip v-else :tooltip-text="tooltipText">
      <div class="rounded-full" :class="progressIconWrapperBackground">
        <IconWrapper
          class="cursor-pointer p-1"
          :icon="progressIcon"
          :size="16"
          :fill="progressIconFill"
        />
      </div>
    </ToolTip>
  </div>
</template>
<script setup>
import IconTaskProgressSharp from '@/assets/icons/custom/misc/IconTaskProgressSharp.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import { computed } from 'vue';

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const progressPercent = computed(() => {
  return (
    (props.item?.progress_percentage * 100).toLocaleString('de-DE', {
      maximumFractionDigits: 0,
    }) + '%'
  );
});

const progressIconWrapperBackground = computed(() => {
  switch (props.item?.progress) {
    case 0:
      return 'bg-fill-success';
    case 1:
      return 'bg-fill-error';
    case 2:
      return 'bg-disabled-warning';
    default:
      return '';
  }
});

const progressIcon = computed(() => {
  switch (props.item?.progress) {
    case 0:
      return 'task_alt';
    case 1:
      return 'error';
    case 2:
      return 'layers';
    default:
      return '';
  }
});

const progressIconFill = computed(() => {
  switch (props.item?.progress) {
    case 0:
      return 'text-spot-success';
    case 1:
      return 'text-spot-error';
    case 2:
      return 'text-status-info';
    default:
      return '';
  }
});

const tooltipText = computed(() => {
  switch (props.item?.progress) {
    case 0:
      return 'Berechnung erfolgreich abgeschlossen';
    case 1:
      return `Berechnung konnte nicht\nerfolgreich abgeschlossen werden`;
    case 2:
      return 'Tilesets werden erstellt';
    default:
      return '';
  }
});
</script>
