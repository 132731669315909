<template>
  <div
    v-if="panelData"
    class="bg-white overflow-visible standard-elevation-1 animate-none rounded-[8px]"
    :class="{ 'expanded-sidebar': drawer }"
  >
    <div v-show="show">
      <div
        class="relative overflow-y-auto flex flex-col items-start hide-scrollbar rounded-[8px]"
        style="width: 366px"
        :style="'max-height:' + containerHeight + 'px'"
      >
        <InfoPanelHeader
          :panel-name="panelData.name"
          :updated-info="panelData.updatedInfo"
          @close-click="show = false"
        />
        <InfoPanelSubtitles
          :subtitles="panelData.subtitles"
          :feature="feature"
        />
        <InfoPanelContent
          :feature="feature"
          :panel-data="panelData"
          @close-info-panel="show = false"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import InfoPanelContent from '@/apps/usecase-1/map/info-panel/InfoPanelContent.vue';
import InfoPanelHeader from '@/apps/usecase-1/map/info-panel/InfoPanelHeader.vue';
import InfoPanelSubtitles from '@/apps/usecase-1/map/info-panel/InfoPanelSubtitles.vue';
import { useMapStore } from '@/apps/usecase-1/map/map-store';
import { SimplePanelScenarioDependent } from './panel-data/simple-panel-scenario-dependent';
import { LAYER_KEY__BUILDINGS } from '../layer-config/buildings';
import {
  LAYER_KEY__BLOCK,
  LAYER_KEY__COUNTY,
  LAYER_KEY__DISTRICT,
  LAYER_KEY__FEDERAL_STATE,
  LAYER_KEY__GOVERNMENT_DISTRICT,
  LAYER_KEY__MUNICIPALITY,
} from '../layer-config/geographical-level';
import { LAYER_KEY__ESTATE } from '../layer-config/estates';

const mapStore = useMapStore();
const props = defineProps({ feature: Object, featureId: [Number, String] });

const show = ref(false);
const featureData = ref(null);
const scenarioYear = computed(() => {
  return mapStore.year;
});
const featureRefresh = computed(() => {
  return mapStore.refreshFeature;
});

const drawer = computed(() => store.state.layout.drawer);
const containerHeight = computed(() => mapStore.containerHeight);

const store = useStore();
const panelData = ref(null);
const panel = ref(null);

const panelObjects = [
  new SimplePanelScenarioDependent(
    '/api/usecase-1/building',
    [LAYER_KEY__BUILDINGS],
    {
      method: 'OPTIONS',
      url: '/api/usecase-1/building-main-result/',
    },
    {
      otherNestedKeys: ['building'],
    },
  ),
  new SimplePanelScenarioDependent(
    '/api/usecase-1/result-aggregation/building_block',
    [LAYER_KEY__BLOCK],
    {
      method: 'GET',
      url: '/api/usecase-1/result-aggregation-meta-data/',
    },
    {
      yearlyResultsKey: 'years',
      customMetaView: true,
    },
  ),
  new SimplePanelScenarioDependent(
    '/api/usecase-1/result-aggregation/district',
    [LAYER_KEY__DISTRICT],
    {
      method: 'GET',
      url: '/api/usecase-1/result-aggregation-meta-data/',
    },
    {
      yearlyResultsKey: 'years',
      customMetaView: true,
    },
  ),
  new SimplePanelScenarioDependent(
    '/api/usecase-1/result-aggregation/municipality',
    [LAYER_KEY__MUNICIPALITY],
    {
      method: 'GET',
      url: '/api/usecase-1/result-aggregation-meta-data/',
    },
    {
      yearlyResultsKey: 'years',
      customMetaView: true,
    },
  ),
  new SimplePanelScenarioDependent(
    '/api/usecase-1/result-aggregation/administrative_district',
    [LAYER_KEY__GOVERNMENT_DISTRICT],
    {
      method: 'GET',
      url: '/api/usecase-1/result-aggregation-meta-data/',
    },
    {
      yearlyResultsKey: 'years',
      customMetaView: true,
    },
  ),
  new SimplePanelScenarioDependent(
    '/api/usecase-1/result-aggregation/county',
    [LAYER_KEY__COUNTY],
    {
      method: 'GET',
      url: '/api/usecase-1/result-aggregation-meta-data/',
    },
    {
      yearlyResultsKey: 'years',
      customMetaView: true,
    },
  ),
  new SimplePanelScenarioDependent(
    '/api/usecase-1/result-aggregation/federal_state',
    [LAYER_KEY__FEDERAL_STATE],
    {
      method: 'GET',
      url: '/api/usecase-1/result-aggregation-meta-data/',
    },
    {
      yearlyResultsKey: 'years',
      customMetaView: true,
    },
  ),
  new SimplePanelScenarioDependent(
    '/api/usecase-1/result-aggregation/estate',
    [LAYER_KEY__ESTATE],
    {
      method: 'GET',
      url: '/api/usecase-1/result-aggregation-meta-data/',
    },
    {
      yearlyResultsKey: 'years',
      customMetaView: true,
    },
  ),
];

const panelsByLayerId = {};
for (const panelObject of panelObjects) {
  for (const layerId of panelObject.layerIds) {
    panelsByLayerId[layerId] = panelObject;
  }
}

watch(
  [() => props.feature, featureRefresh],
  async ([newFeature]) => {
    // new feature was clicked or feature refresh was triggered by feature patch
    if (!newFeature) {
      show.value = false;
      return;
    }
    panel.value = panelsByLayerId[newFeature.layer.id];
    if (!panel.value) {
      console.log('no panel found for layer with id ', newFeature.layer.id);
      return;
    }
    featureData.value = await panel.value.requestFeatureData(
      newFeature,
      mapStore,
    );
  },
  { immediate: true },
);

watch(
  [featureData, scenarioYear],
  async (
    [newFeatureData, newScenarioYear],
    [oldFeatureData, oldScenarioYear],
  ) => {
    // feature data or scenario year changed, thus recalculate panel data
    if (newFeatureData === null) return;
    if (newFeatureData === oldFeatureData && !show.value) {
      return;
    }
    panelData.value = panel.value.calculatePanelData(
      newFeatureData,
      newScenarioYear,
      mapStore,
    );
    panelData.value.id = props.featureId;
    show.value = true;
  },
);
</script>

<style lang="scss">
.info-panel-more-options {
  @apply px-2 py-[5px] hover:bg-infra-background w-full;
}
</style>
