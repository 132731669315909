// Function that takes an object and a list of keys, and returns the sum of values for these keys
function sumValuesForKeys(obj, keys) {
  return keys.reduce((sum, key) => sum + (obj[key] || 0), 0);
}

/**
 * get yearly data already grouped by, denormalize it and transform in chart compatible data. Mostly used for stacked bar charts
 * @param  {Object}  groupedData source data
 * @param  {string}  valueFieldName group by this field
 */
export function groupByDenormalizeToStackedBars(groupedData, valueFieldName) {
  const result = {};
  valueFieldName.forEach((e) => (result[e] = {}));
  for (const group of groupedData) {
    const year = group.year;
    for (const field of valueFieldName) {
      result[field][year] = (result[field][year] || 0) + group[field];
    }
  }
  return result;
}

/**
 * get yearly data already grouped by and transform in chart compatible data. Mostly used for stacked bar charts
 * @param  {Object}  groupedData source data
 * @param  {string}  fieldName group by this field
 * @param  {Array} valueFieldNames values fields to group will be summed
 * @param  {Boolean} withMobility apply mobility as sector
 */
export function groupByToStackedBars(
  groupedData,
  fieldName,
  valueFieldNames,
  withMobility,
) {
  const grouped = {};
  for (const group of groupedData) {
    const key = group[fieldName];
    if (!withMobility && key === 5) continue;
    const year = group.year;
    grouped[key] = grouped[key] || {};
    grouped[key][year] =
      (grouped[key][year] || 0) + sumValuesForKeys(group, valueFieldNames);
  }
  return grouped;
}

/**
 * get yearly data already grouped by and transform in chart compatible data for specific year. Mostly used for pie charts
 * @param  {Object}  groupedData source data
 * @param  {string}  fieldName group by this field
 * @param  {string} valueFieldName values fields to group
 * @param  {Number} year group for that year
 */
export function groupByFieldAndYear(
  groupedData,
  fieldName,
  valueFieldName,
  year,
) {
  const grouped = {};
  for (const group of groupedData) {
    if (!(group.year === year)) continue;
    const key = group[fieldName];
    grouped[key] = (grouped[key] || 0) + group[valueFieldName];
  }
  return grouped;
}

/**
 * get yearly data already grouped by and transform in chart compatible data for specific year. Mostly used for pie charts
 * @param  {Object}  groupedData source data
 * @param  {string}  fieldName group by this field
 * @param  {string} valueFieldName values fields to group
 * @param  {Number} year group for that year
 */
export function sectorByYear(groupedData, fieldName, valueFieldName, year) {
  const grouped = {};
  for (const group of groupedData) {
    if (!(group.year === year)) continue;
    const key = group[fieldName];
    grouped[key] = (grouped[key] || 0) + group[valueFieldName];
  }
  return grouped;
}

export function formatChartTooltip(value, unit = null) {
  const options =
    value >= 10000
      ? { minimumFractionDigits: 0, maximumFractionDigits: 0 }
      : { minimumFractionDigits: 1, maximumFractionDigits: 1 };

  const formattedValue = value.toLocaleString('de-DE', options);

  const finalValue = formattedValue.endsWith(',0')
    ? formattedValue.slice(0, -2)
    : formattedValue;

  return `${finalValue}${unit ? ` ${unit}` : ''}`;
}

export function formatChartAxisLabel(value) {
  return value.toLocaleString('de-DE');
}
