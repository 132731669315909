import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';

import VueCookies from 'vue-cookies';
import store from '@/store';
import App from '@/App.vue';
import router from '@/router';
import { abilitiesPlugin } from '@casl/vue';
import {
  clickOnMapInTest,
  clickOnMapInTestRight,
  setQueryRenderedFeatures,
} from '@/utils/cypress-utils';

// PLUGINS
import vuetify from '@/plugins/vuetify';
import i18n from '@/plugins/vue-i18n';
import ECharts from '@/plugins/echarts';
import defineAbility from '@/plugins/ability';

// STYLE
import '@/assets/styles/index.scss';
import { createPinia } from 'pinia';
import 'material-icons/iconfont/material-icons.css';
import 'material-symbols';

const pinia = createPinia();

const app = createApp(App);

app.component('VChart', ECharts);

if (!window.location.hostname.split('.').includes('localhost')) {
  const dsn =
    'https://b949c0ab0bc7b4433dd4c10c060b0b95@o1327264.ingest.us.sentry.io/4507339385143296';
  let tracePropagationTargets = ['localhost'];
  const environment = window.location.hostname.includes('stage')
    ? 'stage'
    : 'production';
  if (environment === 'prod') {
    tracePropagationTargets.push(/^https:\/\/wp\.lbd\.network/);
  } else if (environment === 'stage') {
    tracePropagationTargets.push(/^https:\/\/wp-stage\.lbd\.network/);
  }
  const sampleRate = environment === 'stage' ? 1.0 : 0.1;
  Sentry.init({
    app,
    environment,
    dsn,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: sampleRate, // Capture transactions based on env
    tracePropagationTargets,
    // Session Replay
    replaysSessionSampleRate: sampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

app
  .use(i18n)
  .use(vuetify)
  .use(router)
  .use(store)
  .use(abilitiesPlugin, defineAbility, {
    useGlobalProperties: true,
  })
  .use(VueCookies, { expires: '7d' })
  .use(pinia);

// Assign Vue instance to window object for Cypress testing
if (window.Cypress) {
  window.__vue_app__ = app;
  window.cypress_utils = {
    setQueryRenderedFeatures,
    clickOnMapInTest,
    clickOnMapInTestRight,
  };
}

router.isReady().then(() => app.mount('#app'));
