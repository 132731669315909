import {
  pointClickEnabledStyle,
  polygonClickEnabledStyle,
  powerStyleType,
  powerLegendType,
} from './utils';
import {
  GENERATION_POTENTIAL,
  STATUS,
  SURFACE_SLOPE,
} from '../../../../configs/color-constants';

export const LAYER_KEY__GENERATION_PV_FREE = 'generation_pv_free';
export const LAYER_KEY__GENERATION_BIOMASS = 'generation_biomass';
export const LAYER_KEY__GENERATION_WIND = 'generation_wind';
export const LAYER_KEY__GENERATION_HYDROPOWER = 'generation_hydropower';
export const LAYER_KEY__POTENTIAL_PV_FREE = 'potential_pv_free';
export const LAYER_KEY__POTENTIAL_WIND = 'potential_wind';

const statusStyleType = [
  'match',
  ['get', 'unit_state'],
  0,
  STATUS.REQUESTED,
  1,
  STATUS.IN_OPERATION,
  2,
  STATUS.SHUT_DOWN,
  3,
  STATUS.SHUT_DOWN,
  STATUS.UNKNOWN,
];

const statusLegendType = (legendName) => ({
  name: legendName,
  unit: '',
  items: [
    {
      color: STATUS.IN_OPERATION,
      name: 'In Betrieb',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: STATUS.SHUT_DOWN,
      name: 'Stillgelegt',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: STATUS.REQUESTED,
      name: 'Beantragt',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: STATUS.UNKNOWN,
      name: 'Unbekannst',
      icon: 'mdi-checkbox-blank-circle',
    },
  ],
});

const usabilityStyleType = [
  'match',
  ['get', 'suitability'],
  'geeignet',
  SURFACE_SLOPE.SUITABLE,
  'bedingt',
  SURFACE_SLOPE.PARTLY_SUITABLE,
  SURFACE_SLOPE.UNKNOWN,
];

const usabilityLegendType = (legendName) => ({
  name: legendName,
  unit: '',
  items: [
    {
      color: SURFACE_SLOPE.SUITABLE,
      name: 'Geeignet',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: SURFACE_SLOPE.NOT_SUITABLE,
      name: 'Ungeeignet',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: SURFACE_SLOPE.PARTLY_SUITABLE,
      name: 'Bedingt geeignet',
      icon: 'mdi-checkbox-blank-circle',
    },
  ],
});

const defaultLegendGeneration = (name, color) => ({
  name: 'Bestandsanlagen',
  unit: '',
  items: [
    {
      color: color,
      name: name,
      icon: 'mdi-checkbox-blank-circle',
    },
  ],
});

const defaultLegendPotential = (name, color) => ({
  name: 'Potenziale',
  unit: '',
  items: [
    {
      color: color,
      name: name,
      icon: 'mdi-checkbox-blank-circle',
    },
  ],
});

const defaultSidebarGenerations = (title, additionalFields) => ({
  title,
  subtitles: ['powerplant_key', 'eeg_key', 'plant_operator_key', 'title'],
  chips: [
    {
      title: 'Anlageninformationen',
      active: true,
      items: [
        {
          title: 'Anlageninformationen',
          active: true,
          items: [
            { key: 'unit_state' },
            { key: 'start_up_date' },
            { key: 'planned_start_up_date' },
            { key: 'type_of_feed_in' },
            ...additionalFields,
            {
              key: 'power_kw',
              unit: 'kW(el)',
              decimal: 0,
            },
            // TODO: add
            {
              key: 'tbd',
              unit: 'kW(el)/a',
              decimal: 0,
            },
          ],
        },
      ],
    },
  ],
});

export default {
  // generations:
  [LAYER_KEY__GENERATION_PV_FREE]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'point',
      id: LAYER_KEY__GENERATION_PV_FREE,
      sourceLayer: LAYER_KEY__GENERATION_PV_FREE,
      layerId: LAYER_KEY__GENERATION_PV_FREE,
      visibility: 'none',
      dependsOnScenario: false,
      // applyYearlyFilter: false,
      // applyMunicipalityFilter: true,
    },
    sidebar: defaultSidebarGenerations('PV (Freifläche)', [
      {
        key: 'orientation',
      },
      {
        key: 'slope_degree',
        unit: '°',
        decimal: 0,
      },
      {
        key: 'count_of_modules',
        decimal: 0,
      },
    ]),
    style: {
      default: pointClickEnabledStyle([GENERATION_POTENTIAL.PV_FREE]),
      generationPvFreeKw: pointClickEnabledStyle(
        powerStyleType(
          'power_kw',
          { value: 0, color: GENERATION_POTENTIAL.PV_FREE_MIN_GRADIENT },
          { value: 1000, color: GENERATION_POTENTIAL.PV_FREE_MAX_GRADIENT },
        ),
      ),
      status: pointClickEnabledStyle([statusStyleType]),
    },
    legend: {
      default: defaultLegendGeneration(
        'PV Freifläche',
        GENERATION_POTENTIAL.PV_FREE,
      ),
      generationPvFreeKw: powerLegendType(
        'PV Freifläche nach Anlagenleistung',
        'MW',
        { value: 0, color: GENERATION_POTENTIAL.PV_FREE_MIN_GRADIENT },
        { value: 1, color: GENERATION_POTENTIAL.PV_FREE_MAX_GRADIENT },
      ),
      status: statusLegendType('PV Freifläche nach Betriebsstatus'),
    },
  },
  [LAYER_KEY__GENERATION_WIND]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'point',
      id: LAYER_KEY__GENERATION_WIND,
      sourceLayer: LAYER_KEY__GENERATION_WIND,
      layerId: LAYER_KEY__GENERATION_WIND,
      visibility: 'none',
      dependsOnScenario: false,
      // applyYearlyFilter: false,
      // applyMunicipalityFilter: true,
    },
    sidebar: defaultSidebarGenerations('Wind', [
      { key: 'technology' },
      { key: 'manufacturer' },
      { key: 'plant_type' },
      {
        key: 'hub_height_m',
        unit: 'm',
        decimal: 0,
      },
      {
        key: 'rotor_diameter_m',
        unit: 'm',
        decimal: 0,
      },
    ]),
    style: {
      default: pointClickEnabledStyle([GENERATION_POTENTIAL.WIND]),
      generationWindKw: pointClickEnabledStyle(
        powerStyleType(
          'power_kw',
          { value: 0, color: GENERATION_POTENTIAL.WIND_MIN_GRADIENT },
          { value: 5000, color: GENERATION_POTENTIAL.WIND_MAX_GRADIENT },
        ),
      ),
      status: pointClickEnabledStyle([statusStyleType]),
    },
    legend: {
      default: defaultLegendGeneration('Wind', GENERATION_POTENTIAL.WIND),
      generationWindKw: powerLegendType(
        'Windpotenzial nach Nennleistung',
        'MW',
        { value: 0, color: GENERATION_POTENTIAL.WIND_MIN_GRADIENT },
        { value: 5, color: GENERATION_POTENTIAL.WIND_MAX_GRADIENT },
      ),
      status: statusLegendType('Wind nach Betriebsstatus'),
    },
  },
  [LAYER_KEY__GENERATION_BIOMASS]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'point',
      id: LAYER_KEY__GENERATION_BIOMASS,
      sourceLayer: LAYER_KEY__GENERATION_BIOMASS,
      layerId: LAYER_KEY__GENERATION_BIOMASS,
      visibility: 'none',
      dependsOnScenario: false,
      // applyYearlyFilter: false,
      // applyMunicipalityFilter: true,
    },
    sidebar: defaultSidebarGenerations('Biomasse', [
      { key: 'technology' },
      { key: 'main_fuel' },
      { key: 'type_of_biomass' },
      { key: 'is_chp' },
    ]),
    style: {
      default: pointClickEnabledStyle([GENERATION_POTENTIAL.BIOMASS]),
      generationBiomassKw: pointClickEnabledStyle(
        powerStyleType(
          'power_kw',
          { value: 0, color: GENERATION_POTENTIAL.BIOMASS_MIN_GRADIENT },
          { value: 1000, color: GENERATION_POTENTIAL.BIOMASS_MAX_GRADIENT },
        ),
      ),
      status: pointClickEnabledStyle([statusStyleType]),
    },
    legend: {
      default: defaultLegendGeneration(
        'Biomasse',
        GENERATION_POTENTIAL.BIOMASS,
      ),
      generationBiomassKw: powerLegendType(
        'Biomasse nach Anlagenleistung',
        'MW',
        { value: 0, color: GENERATION_POTENTIAL.BIOMASS_MIN_GRADIENT },
        { value: 1, color: GENERATION_POTENTIAL.BIOMASS_MAX_GRADIENT },
      ),
      status: statusLegendType('Biomasseanlagen nach Betriebsstatus'),
    },
  },
  [LAYER_KEY__GENERATION_HYDROPOWER]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'point',
      id: LAYER_KEY__GENERATION_HYDROPOWER,
      sourceLayer: LAYER_KEY__GENERATION_HYDROPOWER,
      layerId: LAYER_KEY__GENERATION_HYDROPOWER,
      visibility: 'none',
      dependsOnScenario: false,
      // applyYearlyFilter: false,
      // applyMunicipalityFilter: true,
    },
    sidebar: defaultSidebarGenerations('Wasserkraft', [
      { key: 'type_of_hydropower' },
    ]),
    style: {
      default: pointClickEnabledStyle([GENERATION_POTENTIAL.WATER]),
      generationHydropowerKw: pointClickEnabledStyle(
        powerStyleType(
          'power_kw',
          { value: 0, color: GENERATION_POTENTIAL.WATER_MIN_GRADIENT },
          { value: 1000, color: GENERATION_POTENTIAL.WATER_MAX_GRADIENT },
        ),
      ),
      status: pointClickEnabledStyle([statusStyleType]),
    },
    legend: {
      default: defaultLegendGeneration(
        'Wasserkraft',
        GENERATION_POTENTIAL.WATER,
      ),
      generationHydropowerKw: powerLegendType(
        'Wasserkraft nach Anlagenleistung',
        'MW',
        { value: 0, color: GENERATION_POTENTIAL.WATER_MIN_GRADIENT },
        { value: 1, color: GENERATION_POTENTIAL.WATER_MAX_GRADIENT },
      ),
      status: statusLegendType('Wasserkraft nach Betriebsstatus'),
    },
  },
  // potentials:
  [LAYER_KEY__POTENTIAL_PV_FREE]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'polygon',
      id: LAYER_KEY__POTENTIAL_PV_FREE,
      sourceLayer: LAYER_KEY__POTENTIAL_PV_FREE,
      layerId: LAYER_KEY__POTENTIAL_PV_FREE,
      visibility: 'none',
      dependsOnScenario: false,
      // applyYearlyFilter: false,
      // applyMunicipalityFilter: true,
    },
    sidebar: {
      name: 'PV (Freifläche)',
      subtitles: [{ key: 'id' }],
      chips: [
        {
          title: 'Potenziale',
          active: true,
          items: [
            {
              title: 'Auswertung',
              active: true,
              items: [
                {
                  key: 'globalradiation_kwh_per_m2',
                  unit: 'kWh/m²',
                  decimal: 0,
                },
                {
                  key: 'area_m2',
                  unit: 'm²',
                  decimal: 0,
                },
                {
                  key: 'operating_hours_h_per_year',
                  unit: 'h/a',
                  decimal: 0,
                },
                {
                  key: 'pv_power_mw',
                  unit: 'MW',
                  decimal: 1,
                },
                {
                  key: 'pv_production_mwh',
                  unit: 'MWh/a',
                  decimal: 0,
                },
                {
                  key: 'pv_invest_euro',
                  unit: 'Tsd. €',
                  multiply: 0.001,
                  decimal: 0,
                },
              ],
            },
          ],
        },
      ],
    },
    style: {
      default: polygonClickEnabledStyle([GENERATION_POTENTIAL.PV_FREE]),
      potentialPvFreeKw: polygonClickEnabledStyle(
        powerStyleType(
          'power_kw',
          { value: 0, color: GENERATION_POTENTIAL.PV_FREE_MIN_GRADIENT },
          { value: 10000, color: GENERATION_POTENTIAL.PV_FREE_MAX_GRADIENT },
        ),
      ),
      status: polygonClickEnabledStyle([usabilityStyleType]),
    },
    legend: {
      default: defaultLegendPotential(
        'Photovoltaik (Freifläche)',
        GENERATION_POTENTIAL.PV_FREE,
      ),
      potentialPvFreeKw: powerLegendType(
        'Potenzial PV Freifläche nach Nennleistung',
        'MW',
        { value: 0, color: GENERATION_POTENTIAL.PV_FREE_MIN_GRADIENT },
        { value: 10, color: GENERATION_POTENTIAL.PV_FREE_MAX_GRADIENT },
      ),
      status: usabilityLegendType(
        'Potenzial PV Freifläche nach Flächenneignung',
      ),
    },
  },
  [LAYER_KEY__POTENTIAL_WIND]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      app_name: 'potentials',
      router_name: 'PotentialWind',
      key_name: 'id',
      type: 'polygon',
      id: LAYER_KEY__POTENTIAL_WIND,
      sourceLayer: LAYER_KEY__POTENTIAL_WIND,
      layerId: LAYER_KEY__POTENTIAL_WIND,
      visibility: 'none',
      dependsOnScenario: false,
      applyYearlyFilter: false,
      applyMunicipalityFilter: true,
    },
    sidebar: {
      name: 'Wind',
      subtitles: [
        { key: 'id' },
        { key: 'landkreis' },
        { key: 'regionalverband' },
      ],
      chips: [
        {
          title: 'Potenziale',
          active: true,
          items: [
            /* {
              title: 'Referenzanlage',
              active: true,
              items: [
                {
                  key: 'manufacturer',
                  unit: '',
                },
                {
                  key: 'turbine_type',
                  unit: '',
                },
                {
                  key: 'power_turbine',
                  unit: 'MW',
                  decimal: 1,
                },
                {
                  key: 'hubheight',
                  unit: 'm',
                  decimal: 0,
                },
                {
                  key: 'rotor_diameter',
                  unit: 'm',
                  decimal: 0,
                },
              ],
            }, */
            {
              title: 'Fläche',
              active: true,
              items: [
                {
                  key: 'area_ha',
                  unit: 'ha',
                  decimal: 1,
                },
                {
                  key: 'suitability',
                  unit: '',
                },
                /* {
                  key: 'sub_category',
                  unit: '',
                },
                {
                  key: 'v_wind_100m',
                  unit: 'm/s',
                  decimal: 1,
                },
                {
                  key: 'v_wind_hubheight',
                  unit: 'm/s',
                  decimal: 1,
                }, */
                {
                  key: 'mean_altitude',
                  unit: 'm',
                  decimal: 0,
                },
              ],
            },
            {
              title: 'Auswertung',
              active: false,
              items: [
                {
                  key: 'amount_turbines',
                  unit: '',
                  decimal: 0,
                },
                {
                  key: 'power_park',
                  unit: 'MW',
                  decimal: 1,
                },
                {
                  key: 'operating_hours_turbine',
                  unit: 'h/a',
                  decimal: 0,
                },
                /* {
                  key: 'efficiency',
                  unit: '%',
                  decimal: 0,
                  multiply: 100,
                }, */
                {
                  key: 'production_park',
                  unit: 'MWh/a',
                  decimal: 0,
                },
                {
                  key: 'invest_park',
                  unit: 'Tsd. €',
                  decimal: 0,
                  multiply: 0.001,
                },
              ],
            },
          ],
        },
      ],
    },
    style: {
      default: polygonClickEnabledStyle([GENERATION_POTENTIAL.WIND]),
      potentialWindKw: polygonClickEnabledStyle(
        powerStyleType(
          'power_kw',
          { value: 0, color: GENERATION_POTENTIAL.WIND_MIN_GRADIENT },
          { value: 50000, color: GENERATION_POTENTIAL.WIND_MAX_GRADIENT },
        ),
      ),
      status: polygonClickEnabledStyle([usabilityStyleType]),
    },
    legend: {
      default: defaultLegendPotential('Wind', GENERATION_POTENTIAL.WIND),
      potentialWindKw: powerLegendType(
        'Windpotenzial nach Nennleistung',
        'MW',
        { value: 0, color: GENERATION_POTENTIAL.WIND_MIN_GRADIENT },
        { value: 50, color: GENERATION_POTENTIAL.WIND_MAX_GRADIENT },
      ),
      status: usabilityLegendType(
        'Potenzial PV Freifläche nach Flächenneignung',
      ),
    },
  },
};
