<template>
  <div class="p-3 pt-0 flex justify-between items-center gap-1 w-full">
    <div class="flex flex-col gap-[1px]">
      <InfoEntries
        v-for="subtitle in subtitles"
        :key="subtitle.index"
        :subtitle="subtitle"
      />
    </div>
    <MoreOptions v-if="feature.source === 'heatSourceInventory'">
      <div
        class="flex flex-col whitespace-nowrap rounded-[4px] overflow-hidden border-blue-grey-900 border-[1px] absolute top-0 right-0 bg-white hover:bg-infra-highlight-25 standard-elevation-1 z-10"
      >
        <div
          class="body-3 flex flex-col justify-between items-start cursor-pointer"
        >
          <div class="info-panel-more-options">
            <span @click="emit('open-dialog')">löschen</span>
          </div>
        </div>
      </div>
    </MoreOptions>
  </div>
</template>

<script setup>
import InfoEntries from '@/apps/usecase-1/map/info-panel/InfoEntries.vue';
import MoreOptions from '@/components/moreOptions/MoreOptions.vue';

const props = defineProps({ subtitles: Array, feature: Object });
const emit = defineEmits(['open-dialog']);
</script>
