<template>
  <div class="flex flex-col gap-5">
    <div class="body-2">Laden Sie hier ein eigenes Berichtstemplate hoch:</div>
    <div class="grid grid-cols-2 gap-5">
      <InputEl v-model="fileName" label="Title" />
      <InputEl v-model="fileDescription" label="Beschreibung" />
    </div>

    <FileInputMask @files-selected="files = $event" />

    <!-- save-->
    <ButtonEl text="Speichern" @click="saveFile" />
  </div>
</template>

<script setup>
import InputEl from '@/components/input/InputEl.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import FileInputMask from '@/components/FileInputMask/FileInputMask.vue';
import { ref } from 'vue';

const fileName = ref('');
const fileDescription = ref('');
const files = ref([]);

function saveFile() {
  console.log('saving files', {
    fileName: fileName.value,
    fileDescription: fileDescription.value,
    files: files.value,
  });
}
</script>
