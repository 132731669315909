<template>
  <LandingPageHeader v-if="!isLayout" />
  <div class="default-layout" :class="{ 'route-is-map': routeIsMap }">
    <TopHeaderBar
      v-if="error && !isLayout"
      :go-back-to-custom="routeIsMap ? 'landingURL' : null"
    />
    <Component
      :is="error ? 'div' : LoadingSpinner"
      class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
    >
      {{ error && 'Ein Fehler ist aufgetreten.' }}
    </Component>
  </div>
</template>
<script setup>
import LandingPageHeader from '@/apps/landing-page/components/LandingPageHeader.vue';
import LoadingSpinner from '@/components/loading-spinner/LoadingSpinner.vue';
import TopHeaderBar from '@/components/header-component/TopHeaderBar.vue';
import { useRouter } from 'vue-router';
import { computed } from 'vue';

const router = useRouter();
const currentRouteName = computed(() => router.currentRoute.value.name);

const routeIsMap = computed(() => currentRouteName.value.includes('map'));

const props = defineProps({
  error: {
    type: Boolean,
    default: false,
  },
  isLayout: {
    type: Boolean,
    default: false,
  },
});
</script>

<style lang="scss" scoped>
.route-is-map {
  min-height: 100vh;
  padding: 20px;
}
</style>
