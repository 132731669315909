<template>
  <TopHeaderBar>
    <InputSearch
      class="w-[565px]"
      placeholder="Nach Projektname suchen"
      :search-keys="['title', 'count_municipalities', 'target_year']"
      :items="projects"
      @update:search="filteredProjects = $event"
    />

    <ButtonEl
      icon="add"
      text="Neues Projekt"
      @click="router.push({ name: 'UseCase1ProjectCreate' })"
    />
  </TopHeaderBar>

  <VuetifyDataTable
    :item-data="filteredProjects"
    :headers="ProjectListHeaders"
    class="project-list-table"
    :fixed-header="true"
    @expanded="fetchScenariosOnExpand($event)"
  >
    <template #title="{ item }">
      <div class="flex align-center">
        <img
          :src="`${item.thumbnail ? `/api/usecase-1/${item.thumbnail}` : placeholderImage}`"
          class="cursor-default h-[58px] mr-5 py-1"
          alt="Wappen"
        />
        <div class="flex flex-col gap-3 pr-5 pl-0">
          <h5>{{ item.title }}</h5>
          <span class="body-3"
            >{{
              item.count_municipalities +
              (item.count_municipalities > 1 ? ' Gemeinden' : ' Gemeinde')
            }}
            <br />
            {{
              item.count_counties +
              (item.count_counties > 1 ? ' Landkreise' : ' Landkreis')
            }}
          </span>
        </div>
      </div>
    </template>
    <template #created_at="{ item }">
      <div>
        {{ new Date(item.created_at).toLocaleDateString('de-DE') }}
      </div>
    </template>
    <template #progress="{ item }">
      <div class="flex justify-center items-center pr-4">
        <IconWrapper
          v-if="
            item.count_scenarios > 0 &&
            checkSomeProjectProgress(item.id, [0, 2])
          "
          :icon="
            checkSomeProjectProgress(item.id)
              ? 'task_alt'
              : IconTaskProgressSharp
          "
          :fill="
            checkSomeProjectProgress(item.id)
              ? 'text-spot-success'
              : 'text-spot-warning'
          "
        />
        <ToolTip v-else tooltip-text="Bitte mind. 1 valides Szenario erstellen">
          <IconWrapper
            class="cursor-pointer"
            icon="error_outline"
            fill="text-red-600"
          />
        </ToolTip>
      </div>
    </template>
    <template #functions="{ item }">
      <div class="flex justify-start items-center gap-4">
        <IconWrapper
          v-if="item.count_scenarios > 0"
          type="round"
          :size="32"
          fill="text-core-color1"
          hover="hover:text-button-primary-hover-color1"
          class="cursor-pointer"
          icon="refresh"
          @click="refreshProjectScenarios(item.id)"
        />
        <DeleteDialog
          :id="item.id"
          :name-deleted-item="item.title"
          :dto="ProjectDto"
          :disabled="checkSomeProjectProgress(item.id, 2)"
          @instance-removed="projectStore.fetchProjects"
        >
          <IconWrapper
            :size="32"
            fill="text-core-color1"
            hover="hover:text-button-primary-hover-color1"
            icon="delete_forever"
            class="cursor-pointer"
            type="round"
          />
        </DeleteDialog>
        <template v-if="item.count_scenarios > 0">
          <IconWrapper
            :size="32"
            fill="text-core-color1"
            hover="hover:text-button-primary-hover-color1"
            icon="map"
            :disabled="!checkSomeProjectProgress(item.id)"
            class="cursor-pointer"
            type="round"
            @click="navigateToMap(item.id, item.default_scenario)"
          />
          <IconWrapper
            :size="32"
            type="round"
            fill="text-core-color1"
            hover="hover:text-button-primary-hover-color1"
            icon="arrow_forward"
            :disabled="!checkSomeProjectProgress(item.id)"
            class="cursor-pointer"
            @click="navigateToDetail(item.id, item.default_scenario)"
          />
        </template>
      </div>
    </template>

    <template #expanded-row="{ item }">
      <div class="flex flex-col gap-2.5 py-2.5 -mx-2">
        <h5 v-if="item.count_scenarios > 0" class="text-dark pt-8 pb-3 pl-4">
          Übersicht Nachfrageszenarien
        </h5>
        <ScenarioList
          :scenarios="scenarios.filter((e) => e.project === item.id)"
          :default-scenario-id="item.default_scenario"
          :default-scenario-loading
          :project-id="item.id"
          @set-default-scenario="setDefaultScenario($event, item)"
          @instance-removed="projectStore.fetchProjectsAndScenarios"
        />
        <ButtonEl
          icon="add"
          text="Neues Szenario"
          color="color2"
          class="ml-auto mr-2.5"
          @click="createScenarioDialog = true"
        />
      </div>
      <VuetifyDialog
        v-model="createScenarioDialog"
        title="Neues Nachfrageszenario erstellen"
        width="960px"
      >
        <template #content>
          <ScenarioCreate
            :project-id="item.id"
            :scenarios
            @abort="createScenarioDialog = false"
            @instance-created="scenarioCreated($event, item)"
          />
        </template>
      </VuetifyDialog>
    </template>
  </VuetifyDataTable>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import ButtonEl from '@/components/button/ButtonEl.vue';
import VuetifyDataTable from '@/components/vuetifyDataTable/VuetifyDataTable.vue';
import VuetifyDialog from '@/components/vuetifyDialog/VuetifyDialog.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import { computed, onUnmounted, ref, watch } from 'vue';
import ScenarioCreate from '@/apps/usecase-1/scenario/ScenarioCreate.vue';
import ScenarioList from '@/apps/usecase-1/scenario/ScenarioList.vue';
import { ProjectDto } from '@/apps/usecase-1/projects/project-dto';
import { restPatch } from '@/utils/rest-utils';
import DeleteDialog from '@/components/deleteDialog/DeleteDialog.vue';
import TopHeaderBar from '@/components/header-component/TopHeaderBar.vue';
import { useProjectStore } from '@/apps/usecase-1/projects/project-store';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import placeholderImage from '@/assets/svg/coat-of-arms-placeholder.svg';
import axios from '@/utils/axiosHelper';
import cookie from 'vue-cookies';
import { useBaseStore } from '@/apps/basemodule/base-store';
import InputSearch from '@/components/inputSearch/InputSearch.vue';
import IconTaskProgressSharp from '@/assets/icons/custom/misc/IconTaskProgressSharp.vue';
import { headers as ProjectListHeaders } from '@/apps/usecase-1/projects/project-list-headers';

const router = useRouter();
const route = useRoute();

const projectStore = useProjectStore();
const baseStore = useBaseStore();

await baseStore.fetchSimplifiedMuniData();
await projectStore.fetchProjects();
await projectStore.fetchScenarios();

const projects = computed(() => projectStore.projects);
const scenarios = computed(() => projectStore.scenarios);
const filteredProjects = ref(projects.value);

watch(
  projects,
  (newProjects) => {
    filteredProjects.value = newProjects;
  },
  { immediate: true, deep: true },
);

// Interval reference
const interval = ref();

function startFetchingScenarios() {
  if (!interval.value) {
    interval.value = setInterval(() => {
      projectStore.fetchScenarios();
    }, 3000);
  }
}

function stopFetchingScenarios() {
  if (interval.value) {
    clearInterval(interval.value);
    interval.value = null;
  }
}

// Watch for changes in scenarios and control the interval
watch(
  scenarios,
  (newScenarios) => {
    const hasInProgressScenarios = newScenarios.some(
      (scenario) => scenario.progress === 2,
    );

    if (hasInProgressScenarios) {
      startFetchingScenarios();
    } else {
      stopFetchingScenarios();
    }
  },
  { immediate: true },
);

onUnmounted(() => {
  stopFetchingScenarios();
});

const createScenarioDialog = ref(false);

const defaultScenarioLoading = ref(false);

function setDefaultScenario(scenarioId, project) {
  defaultScenarioLoading.value = true;
  restPatch(ProjectDto, project.id, { default_scenario: scenarioId }).then(
    () => {
      projectStore.fetchProjects().then(() => {
        defaultScenarioLoading.value = false;
      });
    },
  );
}

async function scenarioCreated(scenarioId, project) {
  await projectStore.fetchScenarioForProject(project.id);
  await projectStore.fetchProjectsAndScenarios();
  // set default scenario if first scenario
  if (project.default_scenario === null) {
    setDefaultScenario(scenarioId, project);
  }
}

function navigateToDetail(projectId, scenarioId) {
  router.push({
    name: 'UseCase1ProjectResultOverview',
    params: { projectId, scenarioId },
  });
}

function refreshProjectScenarios(projectId) {
  axios({
    method: 'POST',
    url: '/api/usecase-1/refresh-project-scenarios/',
    data: { id: projectId },
    headers: { 'X-CSRFToken': cookie.get('csrftoken') },
  });
}

function fetchScenariosOnExpand(projectIds) {
  // check if project has scenarios
  const hasScenarios = projectIds.some((id) =>
    scenarios.value.some((scenario) => scenario.project === id),
  );
  if (hasScenarios) projectStore.fetchScenarioForProject(projectIds[0]);
}

function checkSomeProjectProgress(projectId, progress = [0]) {
  const progressValues = Array.isArray(progress) ? progress : [progress];

  return scenarios.value.some(
    (scenario) =>
      scenario.project === projectId &&
      progressValues.includes(scenario.progress),
  );
}

function navigateToMap(projectId, scenarioId) {
  router.push({
    name: 'mapView1',
    params: { projectId, scenarioId },
  });
}
</script>

<style lang="scss">
.project-list-table thead th:first-child {
  padding-left: 19px !important;
}
</style>
